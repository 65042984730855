import { Button, Icon, Text, ThemeProvider, Tooltip } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faArrowPointer, faPencil, faEraser, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Layer, Stage, Image, Rect } from 'react-konva';
import BoundedBoxSelection from 'src/components/editor/UiContols/uiGeneratorControls/imageEditingControls/BoundedBoxSelection';
import BrushSelection from 'src/components/editor/UiContols/uiGeneratorControls/imageEditingControls/BrushSelection';
import Stack from 'src/customUIComponents/Stack';
import useImage from 'use-image';
import ControlLabel from './UI/ControlLabel';
import { EditorContextStateP1 } from '../../../../../../AppContext';
import commonStyle from '../../../../common/common.module.scss';

const overrideMouseLeaveTimeout = (theme: any) => {
  return {
    ...theme,
    tooltip: {
      ...theme.tooltip,
      mouseLeaveTimeout: 0,
    },
  };
};

const ImageEditingCanvas = forwardRef((props: any, forwardRef) => {
  const { appendControlValues } = props;
  const editorContext: EditorContextStateP1 = useContext(props.editorContext);

  const control = props.control;
  const [tool, setTool] = useState<string>('brush');
  const [brushTool, setBrushTool] = useState<string>('pen');
  const [lines, setLines] = useState<any>([]);
  const [shadowLines, setShadowLines] = useState<any>([]);

  const stageRef = useRef(null);
  const shadowRef = useRef(null);

  const isDrawing = useRef(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [img, setImg] = useState<any>();
  const [image] = useImage(img);

  console.log('images:', img, image);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 300, height: 200 });
  const [actualDimensions, setActualDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [scale, setScale] = useState<{ scaleX: number; scaleY: number }>({ scaleX: 0, scaleY: 0 });
  const divRef = useRef<any>(null);

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setImageDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (img === undefined) {
      setImageDimensions({
        width: divRef.current.offsetWidth,
        height: 200,
      });
      setDisabled(true);
    } else setDisabled(false);
  }, [img]);

  const [boundedBox, setBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'rgba(255, 250, 250, 0.1)',
    // opacity: 0.08,
    stroke: 'black',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });
  const [shadowBoundedBox, setShadowBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'white',
    // opacity: 0.08,
    stroke: 'white',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });

  const [boxStartPos, setBoxStartPos] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (!editorContext.fileUploadEvent) {
      setImg(undefined);
      clearCanvas();
      return;
    }
    const img = document.createElement('img');
    const imageObjUrl = URL.createObjectURL(editorContext.fileUploadEvent.payload);
    img.id = 'canvasImage';
    img.onload = (event: any) => {
      updateImage(imageObjUrl, event.target.width, event.target.height);
    };
    img.src = imageObjUrl;
  }, [editorContext.fileUploadEvent]);

  // used for unit testing
  useImperativeHandle(forwardRef, () => ({
    updateImage,
    setDisabled,
    getLines: () => lines,
    getShadowLines: () => shadowLines,
    shadowRef: () => shadowRef,
    editorContext: () => editorContext,
    getBoundedBox: () => boundedBox,
    getShadowBoundedBox: () => shadowBoundedBox,
  }));

  const updateImage = (url: string, width: number, height: number) => {
    setImg(url);
    const newHeight = (imageDimensions.width * height) / width;
    setImageDimensions({ width: imageDimensions.width, height: newHeight });
    setActualDimensions({ width, height });
    setScale({ scaleX: width / imageDimensions.width, scaleY: height / newHeight });
  };

  const srcToFile = (src: string, fileName: string, mimeType: string) => {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  };

  const updateMask = async () => {
    if (shadowRef.current) {
      const dataUrl = (shadowRef.current as any).toDataURL();
      const fileObj = await srcToFile(dataUrl, 'image_mask.png', 'image/png');
      const controlName = props.control.controlName;
      const controlData = {
        [controlName]: { value: null, file: fileObj },
      };
      editorContext.setWorkFlowOptions({ ...editorContext.workflowOptions, ...controlData });
    }
  };

  const handleMouseUpBrush = (e: any) => {
    isDrawing.current = false;
    updateMask();
  };
  const handleMouseMoveBrush = (e: any) => {
    // no drawing - skipping

    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    let shadowLastLine = shadowLines[shadowLines.length - 1];
    // add point
    shadowLastLine.points = shadowLastLine.points.concat([point.x, point.y]);
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    shadowLines.splice(shadowLines.length - 1, 1, shadowLastLine);
    setLines(lines.concat());
    setShadowLines(shadowLines.concat());
  };
  const handleMouseDownBrush = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    // let tool;
    if (tool === 'brush') {
      setLines([{ tool, points: [pos.x, pos.y] }]);
      setShadowLines([{ tool, points: [pos.x, pos.y] }]);
    } else {
      setLines([...lines, { tool, points: [pos.x, pos.y] }]);
      setShadowLines([...shadowLines, { tool, points: [pos.x, pos.y] }]);
    }
  };

  const handleMouseUpBox = (e: any) => {
    isDrawing.current = false;
    updateMask();
  };
  const handleMouseMoveBox = (e: any) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();

    console.log(point); // no drawing - skipping

    const x = point.x - boundedBox.x;
    const y = point.y - boundedBox.y;

    setBoundedBox({ ...boundedBox, width: x, height: y });
    setShadowBoundedBox({ ...shadowBoundedBox, width: x, height: y });
  };

  const handleMouseDownBox = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setBoxStartPos(pos);
    setBoundedBox({ ...boundedBox, x: pos.x, y: pos.y, width: 5, height: 5 });
    setShadowBoundedBox({ ...shadowBoundedBox, x: pos.x, y: pos.y, width: 5, height: 5 });
  };

  const toggleTool = () => {
    if (tool === 'brush') setTool('boundexBox');
    else setTool('brush');
  };

  const clearCanvas = () => {
    setLines([]);
    setShadowLines([]);
    setBoundedBox({ ...boundedBox, width: 0, height: 0 });
    setShadowBoundedBox({ ...shadowBoundedBox, width: 0, height: 0 });
  };

  const handleToolChange = (tool: any) => {
    if (tool === 'clear') clearCanvas();
    setTool(tool);
  };

  const getSelectedTool = (toolName: string) => {
    const style: any = {
      borderRadius: '8px',
      boxShadow: 'none',
      border: 'none',
      color: '#6F7B8F',
    };
    if (toolName === tool && !disabled) {
      console.log('toolname', toolName);
      style.color = '#6236FF';
      style.border = '1px';
      style.borderStyle = 'solid';
      style.borderColor = '#6236FF';
    }
    return style;
  };

  const handleMouseDown = (e: any) => {
    console.log(disabled);
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') handleMouseDownBrush(e);
      else if (tool === 'boundedBox') handleMouseDownBox(e);
    }
  };

  const handleMouseUp = (e: any) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') handleMouseUpBrush(e);
      else if (tool === 'boundedBox') handleMouseUpBox(e);
    }
  };

  const handleMouseMove = (e: any) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') handleMouseMoveBrush(e);
      else if (tool === 'boundedBox') handleMouseMoveBox(e);
    }
  };

  const handleMouseEnter = (e: any) => {
    if (e.target.type === 'button') {
      e.target.style.color = '#6236FF';
    }
  };

  const handleMouseLeave = (toolName: string, e: any) => {
    const border = e.target.style.border;
    // firefox and safari bug ... setting border to medium instead of none
    if (toolName === tool) {
      return;
    }
    if (e.target.type === 'button') {
      e.target.style.color = '#6F7B8F';
    }
  };
  //
  // const downloadHandler = () => {
  //   console.log("DOWNLOADING...")
  //   const dataUrl = (shadowRef.current as any).toDataURL();
  //   const link = document.createElement('a');
  //   link.setAttribute('download','test.jpg')
  //   link.href = dataUrl;
  //   link.click();
  //
  // }

  return (
    <>
      <ControlLabel title={control.controlLabel} subTitle={control.description} />

      <div className={commonStyle.hideTooltipCloseIcon} style={{ width: '100%', display: 'flex', flexDirection: 'column', margin: 'auto' }}>
        <div ref={divRef} style={{ width: '100%', height: imageDimensions.height }}>
          <Stage
            width={imageDimensions.width}
            height={imageDimensions.height}
            style={{ background: '#F2F4F6', marginBottom: '5px', borderRadius: '12px' }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            ref={stageRef}
          >
            <Layer>
              <Image image={image} width={imageDimensions.width} height={imageDimensions.height} />
            </Layer>
            {tool === 'brush' && <BrushSelection brush={brushTool} lines={lines} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={boundedBox} />}
            {tool === 'eraser' && <BrushSelection brush={'eraser'} lines={lines} />}
          </Stage>
          <Stage
            ref={shadowRef}
            width={actualDimensions.width}
            height={actualDimensions.height}
            style={{ border: '1px solid grey', marginBottom: '5px', display: 'none' }}
            scaleX={scale.scaleX}
            scaleY={scale.scaleY}
          >
            <Layer>
              <Rect x={0} y={0} width={imageDimensions.width} height={imageDimensions.height} fill="black"></Rect>
            </Layer>
            {tool === 'eraser' && <BrushSelection brush={'eraser'} strokeWidth={30} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'brush' && <BrushSelection brush={brushTool} strokeWidth={30 * scale.scaleX} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={shadowBoundedBox} />}
          </Stage>
        </div>
        <div
          style={{
            maxWidth: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '5px',
            marginBottom: '5px',
          }}
        >
          <ThemeProvider theme={overrideMouseLeaveTimeout}>
            <Stack style={{ flexDirection: 'row', gap: '5px' }}>
              <Tooltip
                trigger={
                  <div data-testid="studio-style-container-toggle-button">
                    {' '}
                    <Button
                      style={getSelectedTool('boundedBox')}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={(event) => handleMouseLeave('boundedBox', event)}
                      onClick={() => {
                        handleToolChange('boundedBox');
                      }}
                      disabled={disabled}
                    >
                      <Icon size={'lg'} type={faArrowPointer as IconDefinition} />
                    </Button>
                  </div>
                }
              >
                <Text>Use a bounding box to select the area</Text>
              </Tooltip>
              <Tooltip
                trigger={
                  <div>
                    <Button
                      style={getSelectedTool('brush')}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={(event) => handleMouseLeave('brush', event)}
                      onClick={() => {
                        handleToolChange('brush');
                      }}
                      disabled={disabled}
                    >
                      <Icon size={'lg'} type={faPencil as IconDefinition} />
                    </Button>
                  </div>
                }
              >
                <Text>Use the drawing tool to select the area</Text>
              </Tooltip>
              <Tooltip
                trigger={
                  <div>
                    <Button
                      style={getSelectedTool('eraser')}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={(event) => handleMouseLeave('eraser', event)}
                      onClick={() => {
                        handleToolChange('eraser');
                      }}
                      disabled={disabled}
                    >
                      <Icon size={'lg'} type={faEraser as IconDefinition} />
                    </Button>
                  </div>
                }
              >
                <Text>Refine or remove a selected area</Text>
                <Text>using the erase tool</Text>
              </Tooltip>
              <Tooltip
                trigger={
                  <div>
                    <Button
                      style={getSelectedTool('clear')}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={(event) => handleMouseLeave('clear', event)}
                      onClick={() => {
                        handleToolChange('clear');
                      }}
                      disabled={disabled}
                    >
                      <Icon size={'lg'} type={faRotateLeft as IconDefinition} />
                    </Button>
                  </div>
                }
                message="Reset the selection area"
              />
              {/*<Button*/}
              {/*    style={getSelectedTool('clear')}*/}
              {/*    onClick={() => {*/}
              {/*      downloadHandler()*/}
              {/*    }}*/}
              {/*    disabled={disabled}*/}
              {/*>*/}
              {/*  <Icon size={'lg'} type={faRotateLeft as IconDefinition} />*/}
              {/*</Button>*/}
            </Stack>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
});

export default ImageEditingCanvas;
