export enum WeblabTreatment {
  C = 'C',
  T1 = 'T1',
  T2 = 'T2',
}

export interface Weblab {
  name: string;
  treatment: WeblabTreatment;
}

export const AICS_WEBLAB_EXAMPLE = 'AICS_1055830';
export const AICS_WEBLAB_FEEDS_DEACTIVATION = 'AICS_1075347';
export const AICS_WEBLAB_EDIT_TAB_FAST_FOLLOW = 'AICS_1077206';
export const AICS_WEBLAB_EDIT_TAB_POSITION_EDIT = 'AICS_1095436';
// Valid groups AI_LAB_1061066: T1, AI_LAB_997080: T1, AICS_1055830: T1
export const CLOSED_BETA_TREATMENT_GROUPS: Weblab[] = [
  { name: 'AI_LAB_1061066', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1027305', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_997080', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1072131', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073705', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073743', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073744', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073745', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073747', treatment: WeblabTreatment.T1 },
  { name: 'AI_LAB_1073746', treatment: WeblabTreatment.T1 },
];

export const CREATIVE_AGENT_ACCESS_CONTROL_LIST: Weblab[] = [{ name: 'RCS_MONS_AUTH_1072881', treatment: WeblabTreatment.T1 }];
