/* istanbul ignore file */
import KatalLogger, { Level } from '@amzn/katal-logger';
import { Metrics, STAGE } from 'src/constants';

const katalLoggerConfig = {
  url: '/monitoring/log',
  logThreshold: Level.INFO,
  maxLogLineSize: 50000,
  logToConsole: true,
  context: {
    [Metrics.Dimensions.Stage]: STAGE,
  },
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
logger.addPerformanceListener();
