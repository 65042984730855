import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Container, Divider, Dialog, IconButton, ImageList, ImageListItem, Typography, Stack } from '@mui/material';
import { useContext } from 'react';
import * as ControlComponents from '..';
import ActionButton from './ActionButton';
import { EditorContext } from '../../../../../../AppContext';
import CancelButton from '../components/CancelButton';
import './ImageList.scss';

const handleClose = () => {};

const singleImage = (src: string, onClick: any, onChange: any) => (
  <ImageList gap={0} cols={1} variant="standard">
    <ImageListItem rows={1}>
      <div className="container2">
        <img
          className="image"
          srcSet={src}
          src={src}
          alt={src}
          style={{ maxHeight: '70vh', width: '60%', alignSelf: 'center', cursor: 'pointer', objectFit: 'contain' }}
          loading="lazy"
        />

        {
          <div className="middle">
            <div className="buttonsWrapper" style={{ paddingTop: '5px' }}>
              <Button
                component="label"
                size={'medium'}
                role={undefined}
                variant="contained"
                tabIndex={-1}
                aria-label="fileuploader"
                data-testid="fileUploadButton"
                sx={{ fontSize: '0.6em' }}
                //style={buttonStyle}
              >
                Replace Image
                <input
                  accept="image/png, image/jpeg"
                  onChange={onChange}
                  onClick={onClick}
                  data-testid="fileUploadInput"
                  style={{
                    clip: 'rect(0 0 0 0)',
                    clipPath: 'inset(50%)',
                    height: 1,
                    overflow: 'hidden',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    whiteSpace: 'nowrap',
                    width: 1,
                  }}
                  type="file"
                />
              </Button>
            </div>
          </div>
        }
      </div>
    </ImageListItem>
  </ImageList>
);
const controlComponents: any = ControlComponents;
const selectControlComponent = (controlType: string) => {
  return controlType in controlComponents ? controlComponents[controlType] : () => {};
};
const getChildren = (children: any = []) => {
  return children
    .sort((a: any, b: any) => a.order - b.order)
    .map((item: any, index: number) => {
      const Component = selectControlComponent(item.controlType);
      return <Component key={'dialog_' + index} control={item} />;
    });
};

const FileUploadDialog = (props: any) => {
  const context = useContext(EditorContext);

  const { control, setOpenDialog, file, uploadFile, cancel, onClick, onChange } = props;
  const controlMetadata = control.controlMetadata;
  const dialogProps = controlMetadata?.controlOptions;
  const image = file && URL.createObjectURL(file);

  const clickHandler = () => {
    // control.visible = true;
    cancel();
    setOpenDialog(false);
  };
  return (
    <>
      {dialogProps?.openDialog && (
        <Dialog onClose={handleClose} open={true} maxWidth={'xl'} sx={{}}>
          <Box maxWidth={'xl'} sx={{ p: 0, mb: 2 }}>
            <Stack direction={'row'}>
              <Container maxWidth={'xl'} sx={{ pt: '10px' }}>
                <Typography variant="h3">{dialogProps?.dialogTitle}</Typography>
              </Container>
              <Box p={'4px'}>
                <IconButton
                  size="small"
                  onClick={clickHandler}
                  sx={{
                    m: 0.2,
                    backgroundColor: 'white',
                    opacity: '0.8',
                    color: 'grey.500',
                    '&.MuiIconButton-root:hover': {
                      color: 'white',
                      bgcolor: 'grey.500',
                    },
                  }}
                  aria-label={`Close`}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>

            <Divider variant="fullWidth" flexItem />

            <Stack direction={'column'} alignItems={'center'} gap={0.5}>
              {singleImage(image, onClick, onChange)}
              <Container maxWidth={'xl'} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Typography
                  width={'65%'}
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: dialogProps?.dialogDescription }}
                  style={{ minInlineSize: 'fit-content' }}
                ></Typography>
              </Container>
              {controlMetadata.children.length && getChildren(controlMetadata.children)}
              <Stack direction={'row'} width={'100%'} justifyContent={'flex-end'} pr={2}>
                <CancelButton callback={clickHandler} />
                <ActionButton
                  buttonText={controlMetadata?.controlOptions?.buttonText}
                  callback={() => {
                    setOpenDialog(false);
                    uploadFile(file);
                    if (controlMetadata?.controlOptions?.onSubmit.length) {
                      controlMetadata?.controlOptions?.onSubmit.map((item: any) => {
                        context.workflowConfig
                          .filter((control: any) => control.controlName === item.controlName)
                          .map((control: any) => {
                            return item.props.map((prop: any) => {
                              control.hasOwnProperty(prop.key) && (control[prop.key] = prop.value);
                            });
                          });
                      });
                      context.setWorkflowConfig([...context.workflowConfig]);
                    }
                  }}
                  validation={context.workflowOptions[controlMetadata?.controlOptions?.validation]}
                />
              </Stack>
            </Stack>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default FileUploadDialog;
