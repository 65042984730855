import { Asset } from '@amzn/genaihub-typescript-client';
import { Skeleton } from '@mui/material';
import { CSSProperties, MouseEventHandler, useEffect, useRef, useState } from 'react';
import styles from './AssetContentCard.module.scss';
import VideoIcon from '../../../images/icons/videoicon.svg';
import ViewIcon from '../../../images/icons/viewicon.svg';

export interface AssetGalleryProps {
  asset?: Asset;
  showOverlay?: boolean;
  controls?: boolean;
  autoPlayTime?: number;
  viewIcon?: boolean;
  fixedSize?: boolean;
  clickHandler?: MouseEventHandler<HTMLDivElement>;
  loadedCallback?: Function;
  className?: string;
  style?: CSSProperties;
  containerTestId?: string;
}

export default function AssetContentCard(props: AssetGalleryProps) {
  const { asset, showOverlay, controls, autoPlayTime, fixedSize, viewIcon, className, style, clickHandler, loadedCallback } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const videoTimeout = useRef<NodeJS.Timeout | null>(null);
  const video = useRef<HTMLVideoElement>(null);
  const previousAsset = useRef(asset);

  const onLoad = (e: any) => {
    setIsLoaded(true);
    loadedCallback?.();
  };

  const onMouseEnter = () => {
    if (!controls && video.current) {
      if (autoPlayTime) {
        video.current.currentTime = 0;
      }
      // play is asyncronous and can throw an error
      video.current.play().catch((e) => console.error(e));

      // set up a time out to pause the video
      if (autoPlayTime) {
        if (videoTimeout.current) {
          clearTimeout(videoTimeout.current);
        }
        videoTimeout.current = setTimeout(() => {
          if (video.current) {
            video.current.pause();
            video.current.currentTime = 0;
          }
        }, autoPlayTime);
      }
    }
  };

  const onMouseLeave = () => {
    if (!controls && video.current) {
      video.current.pause();

      if (autoPlayTime) {
        video.current.currentTime = 0;
        if (videoTimeout.current) {
          clearTimeout(videoTimeout.current);
        }
      }
    }
  };

  useEffect(() => {
    preLoadVideo();

    return () => {
      if (videoTimeout.current) {
        clearTimeout(videoTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    // update the current previous asset with the current one
    if (previousAsset.current?.uri !== asset?.uri) {
      previousAsset.current = asset;
      // if the asset changes and it had loaded reload the video and update state
      if (isLoaded) {
        preLoadVideo();
        setIsLoaded(false);
      }
    }
  }, [isLoaded, asset]);

  function preLoadVideo() {
    if (asset?.type === 'VIDEO') {
      try {
        console.log('AssetContentCard pre-load', asset?.uri);
        video.current?.load();
      } catch (err) {
        console.log('Error pre-loading AssetContentCard video', asset?.uri);
      }
    }
  }

  return (
    <div
      className={`asset-card ${styles['asset-card']} ${showOverlay ? styles['show-overlay'] : ''} ${fixedSize ? styles['fixed-size'] : ''} ${
        isLoaded ? styles.loaded : ''
      } ${className ? className : ''}`}
      onClick={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      data-testid={props.containerTestId ?? `asset-gallery-card-${asset?.uri}`}
    >
      {asset?.type === 'IMAGE' ? (
        <img className={`${styles.content} ${styles.image}`} srcSet={asset.uri} src={asset.uri} alt={asset.uri} onLoad={onLoad} loading="lazy" />
      ) : asset?.type === 'VIDEO' ? (
        <video
          ref={video}
          className={`${styles.content} ${styles.video}`}
          onLoadStart={onLoad}
          preload="auto"
          controls={controls}
          playsInline
          muted
          loop
        >
          <source src={asset.uri} type="video/mp4" />
        </video>
      ) : (
        <Skeleton className={styles.placeholder} variant="rounded" animation={false} />
      )}
      {asset && asset?.type && !isLoaded && <Skeleton className={`${styles.placeholder} ${styles.loading}`} variant="rounded" />}
      {asset && showOverlay && isLoaded && (
        <div className={`overlay ${styles.overlay}`}>
          {asset?.type === 'VIDEO' && (
            <div className={`icon ${styles.icon} ${styles.video}`}>
              <VideoIcon />
            </div>
          )}
          {viewIcon && (
            <div className={`${styles.icon} ${styles.view}`}>
              <ViewIcon />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
