import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ImageModalContextType } from 'src/components/imageModal/ImageModalContext';
import { ContentItem, StudioInputSettings, getProductImageDetails } from 'src/components/pages/studio/StudioContext';
import useAssetImageCache from './useAssetImageCache';

const usePreview = (props: {
  contentItem?: ContentItem;
  handleReuseSettings: (props: { studioInputSettings: StudioInputSettings }) => void;
  handleSwitchToPrevContentItem: () => void;
  handleSwitchToNextContentItem: () => void;
}) => {
  const { contentItem, handleSwitchToPrevContentItem, handleSwitchToNextContentItem } = props;
  const content = useAssetImageCache(contentItem);

  // Preview Data
  const [imageType, setImageType] = useState<ImageModalContextType['imageType']>('');
  const [imageUrl, setImageUrl] = useState<ImageModalContextType['imageUrl']>('');
  const [imageReferenceId, setImageReferenceId] = useState<ImageModalContextType['imageReferenceId']>('');
  const [productImageDetails, setProductImageDetails] = useState<ImageModalContextType['productImageDetails']>();
  const [prompt, setPrompt] = useState<ImageModalContextType['prompt']>('');
  const [studioInputSettings, setStudioInputSettings] = useState<ImageModalContextType['studioInputSettings']>(null);
  const [suggestedHeadline, setSuggestedHeadline] = useState<ImageModalContextType['suggestedHeadline']>('');
  const [theme, setTheme] = useState<ImageModalContextType['theme']>();

  // Edit Data
  const [editMode, setEditMode] = useState<string>('');
  const [savedEditsImageUrl, setSavedEditsImageUrl] = useState<ImageModalContextType['savedEditsImageUrl']>('');
  const [savedEditsImageReferenceId, setSavedEditsImageReferenceId] = useState<ImageModalContextType['savedEditsImageReferenceId']>('');
  const [savedEditsImageAspectRatio, setSavedEditsImageAspectRatio] = useState<ImageModalContextType['savedEditsImageAspectRatio']>('');

  // Active Edits Data
  const [activeEditsImageUrl, setActiveEditsImageUrl] = useState<ImageModalContextType['activeEditsImageUrl']>();
  const [activeEditsOriginalImageUrl, setActiveEditsOriginalImageUrl] = useState<ImageModalContextType['activeEditsOriginalImageUrl']>();
  const [activeEditsImageFeedback, setActiveEditsImageFeedback] = useState<ImageModalContextType['activeEditsImageFeedback']>();
  const [activeEditsImageReferenceId, setActiveEditsImageReferenceId] = useState<ImageModalContextType['activeEditsImageReferenceId']>();
  const [activeEditsAspectRatio, setActiveEditsAspectRatio] = useState<ImageModalContextType['activeEditsAspectRatio']>();
  const [activeEditResults, setActiveEditResults] = useState<ImageModalContextType['activeEditResults']>();
  const [activeEditErrorMessage, setActiveEditErrorMessage] = useState<ImageModalContextType['activeEditErrorMessage']>();
  const [unsavedWork, setUnsavedWork] = useState<boolean>(false);
  const [pendingGeneration, setPendingGeneration] = useState<boolean>(false);
  const [isPublishingAsset, setIsPublishingAsset] = useState<ImageModalContextType['isPublishingAsset']>(false);

  useEffect(() => {
    setUnsavedWork(pendingGeneration || (!!activeEditResults && activeEditResults?.jobs?.[0].status === 'COMPLETED'));
  }, [pendingGeneration, activeEditResults]);

  const clearSavedEdits = () => {
    setSavedEditsImageUrl(undefined);
    setSavedEditsImageReferenceId(undefined);
    setSavedEditsImageAspectRatio(undefined);
  };

  const queryClient = useQueryClient();

  const clearActiveEdit = () => {
    setActiveEditsAspectRatio(undefined);
    setActiveEditsImageFeedback(undefined);
    setActiveEditsImageReferenceId(undefined);
    setActiveEditsImageUrl(undefined);
    setActiveEditsOriginalImageUrl(undefined);
    setActiveEditResults(undefined);
    setActiveEditErrorMessage(undefined);

    queryClient.cancelQueries({
      predicate(query) {
        return query.queryKey.includes((key: unknown) => key === 'useWorkflow');
      },
    });
    queryClient.resetQueries();
    queryClient.invalidateQueries({
      refetchType: 'none',
    });
  };

  useEffect(() => {
    // Preview Data
    setImageType(contentItem?.contentType || '');
    setImageUrl(content || contentItem?.content || '');
    setImageReferenceId(contentItem?.referenceId || '');
    // NOTE: temporarily falling back on contentHeadline for prompt since Feed assets include an incomplete model
    // TODO: only use studioInputSettings.prompt after Feed is fixed to provide studioInputSettings
    setPrompt(contentItem?.studioInputSettings?.prompt || contentItem?.contentHeadline || '');
    setStudioInputSettings(contentItem?.studioInputSettings || null);
    setSuggestedHeadline(contentItem?.contentHeadline || '');
    // Set Product Image Details
    setProductImageDetails(contentItem?.studioInputSettings?.asin ? getProductImageDetails(contentItem?.studioInputSettings.asin) : undefined);
    // Set Theme
    const style = contentItem?.studioInputSettings?.style;
    setTheme(
      style
        ? {
            icon: style.icon,
            label: style.label,
            value: style.value,
          }
        : undefined,
    );
    // Edit Data
    clearSavedEdits();
    // Active Edits Data
    clearActiveEdit();
  }, [contentItem, content]);

  const previewContext: ImageModalContextType = {
    // Preview Data
    imageType,
    imageReferenceId,
    setImageReferenceId,
    imageUrl,
    productImageDetails,
    prompt,
    studioInputSettings,
    suggestedHeadline,
    theme,
    // Edit Data
    editMode,
    setEditMode,
    savedEditsImageUrl,
    setSavedEditsImageUrl,
    savedEditsImageReferenceId,
    setSavedEditsImageReferenceId,
    savedEditsImageAspectRatio,
    setSavedEditsImageAspectRatio,
    // Active Edit Data
    unsavedWork,
    isPublishingAsset,
    setIsPublishingAsset,
    pendingGeneration,
    setPendingGeneration,
    clearActiveEdit,
    activeEditResults,
    setActiveEditResults,
    activeEditErrorMessage,
    setActiveEditErrorMessage,
    activeEditsImageUrl,
    setActiveEditsImageUrl,
    activeEditsOriginalImageUrl,
    setActiveEditsOriginalImageUrl,
    activeEditsImageFeedback,
    setActiveEditsImageFeedback,
    activeEditsImageReferenceId,
    setActiveEditsImageReferenceId,
    activeEditsAspectRatio,
    setActiveEditsAspectRatio,

    //
    handleReuseSettings: () => {
      if (studioInputSettings) {
        props.handleReuseSettings({
          studioInputSettings,
        });
      }
    },
    handleSwitchToNextContentItem,
    handleSwitchToPrevContentItem,
  };

  return {
    previewContext,
  };
};

export type UsePreview = ReturnType<typeof usePreview>;

export default usePreview;
