export enum ImageType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export type RequestedImage = {
  readonly height: number;
  readonly width: number;
  readonly url: string;
  readonly type: ImageType;
  readonly base64Encode: () => string;
};

export function getImageFromUrl({ url, type }: { url: string; type: ImageType }): Promise<RequestedImage> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';

    image.onload = () => {
      resolve({
        height: image.height,
        width: image.width,
        url,
        type,
        base64Encode: () => base64EncodeImage({ image, type }),
      });
    };
    image.onerror = () => reject();
  });
}

export const base64EncodeImage = ({ image, type }: { image: HTMLImageElement; type: ImageType }): string => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('no context found on the canvas');
  }
  ctx.drawImage(image, 0, 0);
  const base64 = canvas.toDataURL(type).split(',')[1];
  return base64;
};

export const blobToBase64 = (blob: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export async function urlToFile(url: string, fileTypeOverride?: string) {
  const urlObj = new URL(url);
  const filename: any = urlObj.pathname.split('/').pop();
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: fileTypeOverride || blob.type });
}

export function urlToFileOld(url: string) {
  return new Promise((resolve, reject) => {
    // Extract file name from URL
    const urlObj = new URL(url);
    const filename: any = urlObj.pathname.split('/').pop();

    // Create an Image element to load the image
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Handle CORS issues

    img.onload = function () {
      // Create a canvas to draw the image
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const context: any = canvas.getContext('2d');
      context.drawImage(img, 0, 0);

      // Use canvas to infer MIME type
      canvas.toBlob(function (blob: any) {
        const mimeType = blob.type;
        const file = new File([blob], filename, { type: mimeType });
        resolve(file);
      });
    };

    img.onerror = function () {
      reject(new Error('Error loading the image'));
    };

    // Set the image source to start loading
    img.src = url;
  });
}
