import { AdvertisingAccountMetadata, UserDetails } from '@amzn/genaihub-typescript-client';
import { Publisher } from '@amzn/katal-metrics';
import { AccountType } from '../../AppContext';
import { useCallback, useMemo } from 'react';
import { Metrics } from 'src/constants';
import {
  userMetricsPublisher,
  trackNewActionMetrics,
  trackMetricsWithPublisher,
  StringMetrics,
  CounterMetrics,
  MetricsProvider,
  ContextDimension,
} from 'src/metrics';

interface MetricsContext {
  pageName: string;
  userDetails?: UserDetails;
  toolname?: string;
  accountType?: AccountType;
  selectedAccount?: AdvertisingAccountMetadata | null;
}

export const useMetrics = ({ pageName, userDetails, toolname, accountType, selectedAccount }: MetricsContext): MetricsProvider => {
  const metrics = useMemo<Publisher>(() => {
    return userMetricsPublisher(pageName, userDetails?.alias, toolname, [
      ...(accountType ? [{ name: Metrics.Dimensions.AccountType, value: accountType }] : []),
      ...(selectedAccount?.alternateIds ? [{ name: Metrics.Dimensions.EntityId, value: selectedAccount.alternateIds[0] }] : []),
    ]);
  }, [pageName, userDetails, toolname, accountType, selectedAccount]);

  const trackMetrics = useCallback(
    (name: string, strings?: StringMetrics, counters?: CounterMetrics, context?: ContextDimension): Publisher =>
      trackNewActionMetrics(metrics, name, strings, counters, context),
    [metrics],
  );
  const getMetrics = useCallback((): Publisher => metrics, [metrics]);

  const metricsProvider = useMemo<MetricsProvider>(
    () => ({
      trackMetricsWithPublisher,
      trackMetrics,
      getMetrics,
    }),
    [metrics],
  );

  return metricsProvider;
};
