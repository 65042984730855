export interface LoadedImage {
  el: HTMLImageElement;
  height: number;
  width: number;
  url: string;
}

export interface GetImageFromUrlProps {
  url: string;
  onFetchDimensions?: (props: { width: number; height: number }) => void;
}

export function getImageFromUrl({ url, onFetchDimensions }: GetImageFromUrlProps): Promise<LoadedImage> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';

    const poll =
      onFetchDimensions &&
      setInterval(() => {
        if (image.naturalWidth || image.naturalHeight) {
          clearInterval(poll);
          onFetchDimensions({
            width: image.naturalWidth,
            height: image.naturalHeight,
          });
        }
      }, 100);

    image.onload = () => {
      if (poll) clearInterval(poll);
      resolve({
        el: image,
        height: image.naturalHeight,
        width: image.naturalWidth,
        url,
      });
    };

    image.onerror = () => {
      if (poll) clearInterval(poll);
      reject();
    };
  });
}
