import { ASINMetadata, WorkflowId } from '@amzn/genaihub-typescript-client';
import _cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { createContext } from 'react';
import { Feedback } from 'src/components/feedback/FeedbackContext';
import { EffectOption } from 'src/components/pages/studio/effectSettings/effectSettings';

export interface AsinImageDetails {
  asin: string;
  title: string;
  url: string;
}

export interface CustomImageDetails {
  url: string;
  name: string;
  size: number;
}

export type ProductImageDetails = AsinImageDetails | CustomImageDetails;

export interface ASINItem {
  asin: string;
  metadata: ASINMetadata;
  selectedImageIndex?: number;
  customProductImage?: string;
  customImage?: CustomImageDetails;
}

export interface StyleItem {
  value: string;
  label: string;
  icon: string;
  prompt?: string;
}

export interface JobStatus {
  status: string;
  message?: string;
  results?: ContentItem[];
}

export enum JobStatusEnum {
  READY = 'ready',
  FAILED = 'failed',
  RUNNING = 'running',
  COMPLETED = 'completed',
  LOADING_ASIN = 'loadingAsin',
}

export interface StudioInputSettings {
  asin?: ASINItem;
  effects?: Map<string, EffectOption>;
  enablePromptRewriting?: boolean;
  format?: string;
  prompt?: string;
  style?: StyleItem;
  advanced?: {
    negativePrompt?: string;
    seed?: string;
    temperature?: number;
  };
}

export interface ContentItem {
  loading: boolean;
  isEmptyPlaceholder?: boolean;
  originalUri?: string;
  content?: string;
  message?: string;
  aspectRatio?: string;
  width?: string;
  contentType?: string;
  contentHeadline?: string;
  studioInputSettings?: StudioInputSettings;
  referenceId?: string;
  workflowId?: WorkflowId;
  feedback?: Feedback;
  isFeedsCallRunning?: boolean;
}

export type StudioContextType = {
  placeholders?: ContentItem[];
  setPlaceholders: (placeholders: ContentItem[]) => void;
  clearPlaceholders: () => void;
  // Disabling this option for now since we don't support multiple generations in parallel
  // appendPlaceholders: (placeholders: ContentItem[]) => void;
  //
  creativeTypes?: string[];
  setCreativeTypes: (creativeTypes?: string[]) => void;
  //
  format?: string;
  setFormat: (format: string) => void;
  //
  style?: StyleItem;
  setStyle: (style?: StyleItem) => void;
  //
  effects?: Map<string, EffectOption>;
  setEffects: (effects?: Map<string, EffectOption>) => void;
  //
  asin?: ASINItem;
  setAsin: (asin?: ASINItem) => void;
  //
  textPrompt?: string;
  setTextPrompt: (textPrompt?: string) => void;
  //
  jobStatus?: JobStatus;
  setJobStatus: (jobStatus?: JobStatus) => void;
  //
  results: ContentItem[];
  clearResults: () => void;
  prependResults: (results: ContentItem[]) => void;
  appendResults: (results: ContentItem[]) => void;
  removeResult: (assetToDelete: string) => void;
  updateResult: (result: ContentItem) => void;
  //
  autoGenerate: boolean;
  setAutoGenerate: (autoGenerate: boolean) => void;
  //
  negativePrompt?: string;
  setNegativePrompt: (negativePrompt?: string) => void;
  //
  seed?: string;
  setSeed: (seed?: string) => void;
  //
  temperature?: number;
  setTemperature: (temperature?: number) => void;
  //
  enableAdvancedMode: boolean;
  setEnableAdvancedMode: React.Dispatch<React.SetStateAction<boolean>>;
  //
  enablePromptRewriting: boolean;
  setEnablePromptRewriting: React.Dispatch<React.SetStateAction<boolean>>;
  //
  handleReuseSettings: (props: { studioInputSettings: StudioInputSettings }) => void;
};
export const StudioContext = createContext({} as StudioContextType);

export const getProductImageDetails = (asinItem: ASINItem): ProductImageDetails => {
  const selectedImageIndex = asinItem.selectedImageIndex || 0;
  const mediaCentralAssets = asinItem.metadata?.mediaCentralAssets;
  let asinImage =
    mediaCentralAssets &&
    selectedImageIndex !== -1 &&
    mediaCentralAssets.length > selectedImageIndex &&
    (mediaCentralAssets[selectedImageIndex].highResUri || mediaCentralAssets[selectedImageIndex].lowResUri);

  if (!asinImage && asinItem?.customProductImage) {
    return {
      asin: asinItem.asin,
      title: asinItem.metadata?.title || '',
      url: asinItem.customProductImage,
    };
  } else if (!asinImage && asinItem?.customImage) {
    return _cloneDeep(asinItem.customImage);
  } else {
    return {
      asin: asinItem.asin,
      title: asinItem.metadata?.title || '',
      url: asinImage as string,
    };
  }
};
