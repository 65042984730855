import { Layer, Line } from 'react-konva';

interface line {
  tool: string;
  toolSize: number;
  points: number[];
}
interface FreeEditSelectionProps {
  lines: line[];
  opacity?: number;
  stroke?: string;
  scale?: number;
}

const FreeEditSelection = ({ lines, opacity, stroke, scale }: FreeEditSelectionProps) => {
  return (
    <Layer>
      {lines.map((line: any, i: any) => (
        <Line
          key={i}
          points={line.points}
          stroke={stroke || 'black'}
          strokeWidth={(scale ? line.toolSize * scale : line.toolSize) || 30}
          tension={0}
          lineCap="round"
          lineJoin="round"
          opacity={line.tool === 'eraser' ? 1 : opacity ? opacity : 0.4}
          globalCompositeOperation={line.tool === 'eraser' ? 'destination-out' : 'source-over'}
          strokeScaleEnabled={false}
        />
      ))}
    </Layer>
  );
};

export default FreeEditSelection;
