import { Icon, HelpTip } from '@amzn/storm-ui';
const categories = ['Popular', 'Holidays', 'Location', 'Abstract'];
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { StudioContext } from 'src/components/pages/studio/StudioContext';
import style from './StyleSetting.module.scss';
import themes from '../../../config/themes/themesDropdownList';
import themesConfig from '../../editor/UiContols/uiGeneratorControls/themes.json';

const icons: any = { ...themes };

const StyleSetting = () => {
  const [list, setList] = useState(themesConfig);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const studioContext = useContext(StudioContext);
  const handleClick = (id: string) => {
    const selectedStyle = list.find((item) => item.controlValue === id);
    if (selectedStyle) {
      studioContext.setStyle({
        icon: icons[selectedStyle.src],
        value: selectedStyle.controlValue,
        label: selectedStyle.controlLabel,
        prompt: selectedStyle.customPrompt,
      });
    }
  };

  const searchHandler = (value: string) => {
    const filteredList = themesConfig.filter(
      (theme) =>
        value === 'all' ||
        theme.controlValue.startsWith(value) ||
        theme.controlLabel.startsWith(value) ||
        theme.keywords.join(' ').toLowerCase().search(value.toLowerCase()) > -1 ||
        theme.category.toLowerCase().search(value.toLowerCase()) > -1,
    );
    setList(filteredList);
  };

  const inputChangeHandler = (value: string) => {
    searchHandler(value);
    setSelectedCategory('all');
  };

  const categoryClickHandler = (category: string) => {
    if (category === selectedCategory) {
      setSelectedCategory('all');
      searchHandler('all');
    } else {
      setSelectedCategory(category);
      searchHandler(category);
    }
  };

  return (
    <div className={`${style.container} ${studioContext.asin ? '' : style.inactive}`}>
      <div className={style.header}>Style{!studioContext.asin && <HelpTip>Please select a product to apply a style</HelpTip>}</div>
      <div className={style.searchBar}>
        <input placeholder="Search by keyword" onChange={(event) => studioContext.asin && inputChangeHandler(event.target.value)} />
        <Icon type={faMagnifyingGlass as any} />
      </div>
      <div className={style.pills}>
        {categories.map((category) => (
          <div
            key={category}
            onClick={() => studioContext.asin && categoryClickHandler(category)}
            className={`${style.pillButton} ${selectedCategory == category ? style.selected : ''}`}
          >
            {category}
          </div>
        ))}
      </div>
      <div className={style.tiles}>
        {list.map((item) => {
          return (
            <div key={item.controlValue} onClick={() => studioContext.asin && handleClick(item.controlValue)} className={style.tileItem}>
              <div
                className={`${style.tileItemIcon} ${studioContext.style?.value == item.controlValue ? style.selected : ''}`}
                style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.05) 51%, rgba(0, 0, 0, 0.5) 82%), url(${icons[item.src]})` }}
              ></div>
              <div className={style.tileItemLabel}>{item.controlLabel}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StyleSetting;
