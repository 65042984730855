import { ControlValue } from '@amzn/genaihub-typescript-client';
import { Button } from '@amzn/storm-ui';
import { useState, useContext, useEffect } from 'react';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import Stack from 'src/customUIComponents/Stack';
import ControlLabel from './UI/ControlLabel';
import { EditorContextStateP1 } from '../../../../../../AppContext';

const RadioGroup = (props: any) => {
  const { control, bulkUpdateCallback } = props;

  const editorContext: EditorContextStateP1 = useContext(props.editorContext);
  const controlValues = editorContext.workflowOptions;

  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    if (editorContext.activateGuide) {
      guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
    }
  }, [editorContext.activateGuide]);

  const guideCallback = async (config: any) => {
    if (config) {
      console.log(controlValues, { [control.controlName]: { value: config.value } });
      batchStateUpdate({ [control.controlName]: { value: config.value } }, bulkUpdateCallback);
      setSelected(control.controlValues?.findIndex((controlValue: ControlValue) => controlValue.guide));
    }
  };

  useEffect(() => {
    if (editorContext.disableControl) {
      setOpacity(0.5);
    } else {
      setOpacity(1);
      if (!editorContext.activateGuide) {
        setSelectedOnEnable();
      }
    }
  }, [editorContext.disableControl]);

  const setSelectedOnEnable = () => {
    let value;
    let selectedControlIndex;
    if (control.defaultValue) {
      selectedControlIndex = control.controlValues?.findIndex((controlValue: ControlValue) => controlValue.value == control.defaultValue);
    } else {
      selectedControlIndex = control.controlValues?.findIndex((controlValue: ControlValue) => controlValue.defaultValue);
    }

    if (selectedControlIndex == -1) {
      return;
    }

    setSelected(selectedControlIndex);
    value = control.controlValues[selectedControlIndex].value;

    if (value) {
      const controlName = control.controlName;
      const controlData: any = {};
      controlData[controlName] = { value };
      editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    }
  };

  const [selected, setSelected] = useState<number>();

  const handleClick = (index: number, value: any) => {
    const controlName = control.controlName;
    const controlData: any = {};
    controlData[controlName] = { value };
    editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    setSelected(index);
  };

  const displayButton = control.controlValues.map((controlValue: any, index: number) => {
    return (
      <Button
        onClick={() => handleClick(index, controlValue.value)}
        key={'button' + index}
        style={{
          padding: '6px 12px',
          border: 1,
          borderColor: !editorContext.disableControl && index === selected ? '#6236FF' : '#f1f3f5',
          borderStyle: 'solid',
          backgroundColor: 'white',
          fontSize: '14px',
        }}
        disabled={editorContext.disableControl}
        data-testid={'button' + index}
      >
        {controlValue.label}
      </Button>
    );
  });

  return (
    <div>
      <ControlLabel title={control.controlLabel} />
      <Stack style={{ justifyContent: 'left', flexDirection: 'row', flexWrap: 'wrap', gap: '2px' }}>{displayButton}</Stack>
    </div>
  );
};

export default RadioGroup;
