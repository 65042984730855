import Hero from 'src/components/layout/_storm/Hero';
import Stack from 'src/customUIComponents/Stack';
import { AICS_WEBLAB_EXAMPLE, WeblabTreatment } from 'src/util/weblab/config';
import { WeblabWrapper } from 'src/util/weblab/WeblabWrapper';
import Inspirations from './Inspirations';
import Playground from './Playground';
import './homeStyles.css';

const Home = (props: any) => {
  return (
    <Stack style={{ gap: '100px' }}>
      <WeblabWrapper weblabName={AICS_WEBLAB_EXAMPLE} weblabTreatment={WeblabTreatment.T1}>
        <div>Weblab is enabled</div>
      </WeblabWrapper>
      <Hero />
      <Playground cards={5} showButton wrap={false} width="100%" cardStyle={{ width: '368px', maxWidth: '368px' }} navigate="arrows" />
      <Inspirations showButton width="100%" maxItems={6} />
    </Stack>
  );
};

export default Home;
