import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../AppContext';
import LogoIcon from '../../icons/logo';

const Logo = () => {
  const { metrics } = useContext(AppContext);
  const linkTo = useNavigate();

  const clickHandler = () => {
    metrics.trackMetrics(Metrics.Methods.LogoClick, { [Metrics.Names.ButtonId]: Metrics.Buttons.Logo }, { [Metrics.Counters.Clicks]: 1 });
    linkTo('/');
    console.log('click');
  };

  return (
    <a
      onClick={clickHandler}
      style={{
        cursor: 'pointer',
        marginRight: '16px',
      }}
    >
      <LogoIcon />
    </a>
  );
};
export default Logo;
