import { Favorite, FavoriteBorder, ThumbUp, ThumbUpAltOutlined, ThumbDown, ThumbDownAltOutlined, FileDownload } from '@mui/icons-material';
import { Container, IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';

interface Props {
  /*image: {
		src: string;
		orientation: string;
	};
    */
}

const ImageResults = (props: any) => {
  const { imageGallery, cols, setOpenViewer, setViewerImage, showPreview, previewImage } = props;
  const [favorite, setFavorite] = useState(false);
  const [thumbsUp, setThumbsUp] = useState(false);
  const [thumbsDown, setThumbsDown] = useState(false);
  const [selectedImage, setSelectedImage] = useState(showPreview && previewImage ? previewImage : imageGallery[0].src || undefined);

  const reduce = showPreview ? 0 : imageGallery[0].orientation === 'l' ? (cols === 2 ? 1 : 0) : cols > 3 ? 2 : 0;
  const desktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  let FavComponent = FavoriteBorder,
    ThumbsUpComponent = ThumbUpAltOutlined,
    ThumbsDownComponent = ThumbDownAltOutlined;

  const clickHandler = (icon: string) => {
    switch (icon) {
      case 'favorite':
        setFavorite(!favorite);
        break;
      case 'thumbsUp':
        setThumbsUp(!thumbsUp);
        break;
      case 'thumbsDown':
        setThumbsDown(!thumbsDown);
        break;
      case 'download':
        const link = document.createElement('a');
        link.download = selectedImage;
        link.href = selectedImage;
        link.click();
        break;
    }
  };

  const imageClickHandler = (event: any) => {
    setSelectedImage(event.target.src);
    if (desktop && !showPreview) {
      setViewerImage(event.target.src);
      setOpenViewer(true);
    }
  };

  const TopBar = (show: boolean = false, top?: boolean) => {
    show = show || useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    return (
      <ImageListItemBar
        position="top"
        sx={{
          background: 'none',
          display: { xs: show ? '-webkit-box' : 'none', md: showPreview && top ? '-webkit-box' : 'none' },
        }}
        actionIcon={
          <>
            <IconButton onClick={() => clickHandler('download')} sx={{ color: 'rgba(255, 255, 255, 0.70)' }} aria-label={`Download`}>
              <FileDownload />
            </IconButton>
            <IconButton
              onClick={() => clickHandler('favorite')}
              sx={{
                m: 1,
                backgroundColor: 'grey.900',
                opacity: '0.8',
                color: 'rgba(255, 255, 255, 1)',
                '&.MuiIconButton-root:hover': {
                  bgcolor: 'grey.900',
                },
              }}
              aria-label={`favorite`}
            >
              <FavComponent />
            </IconButton>
          </>
        }
      />
    );
  };

  const BottomBar = (show: boolean = false, top?: boolean) => {
    show = show || useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    return (
      <ImageListItemBar
        sx={{ display: { xs: show ? '-webkit-box' : 'none', md: showPreview && top ? '-webkit-box' : 'none' } }}
        title={'Rate this Image'}
        actionIcon={
          <>
            <IconButton onClick={() => clickHandler('thumbsUp')} sx={{ color: 'rgba(255, 255, 255, 0.70)' }} aria-label={`Thumbs up`}>
              <ThumbsUpComponent />
            </IconButton>
            <IconButton onClick={() => clickHandler('thumbsDown')} sx={{ color: 'rgba(255, 255, 255, 0.70)' }} aria-label={`Thumbs down}`}>
              <ThumbsDownComponent />
            </IconButton>
          </>
        }
      />
    );
  };

  useEffect(() => {
    FavComponent = favorite ? Favorite : FavoriteBorder;
  }, [favorite]);
  useEffect(() => {
    ThumbsUpComponent = thumbsUp ? ThumbUp : ThumbUpAltOutlined;
  }, [thumbsUp]);
  useEffect(() => {
    ThumbsDownComponent = thumbsDown ? ThumbDown : ThumbDownAltOutlined;
  }, [thumbsDown]);

  return (
    <Container maxWidth={desktop ? (showPreview ? 'md' : 'xl') : 'xs'} sx={{ textAlign: 'center' }}>
      <ImageList cols={1} rowHeight={500} variant="masonry" sx={{ display: { md: showPreview ? 'block' : 'none' } }}>
        <ImageListItem rows={1}>
          <img srcSet={selectedImage} src={selectedImage} alt={selectedImage} loading="lazy" />
          {TopBar(true, true)}
          {BottomBar(true, true)}
        </ImageListItem>
      </ImageList>

      <ImageList
        rowHeight={desktop ? (showPreview ? 150 : 450) : 200}
        variant="masonry"
        sx={{
          display: 'inline-block',
          columnCount: {
            xs: '4 !important',
            sm: '4 !important',
            md: `${cols - reduce} !important`,
            lg: `${cols - reduce} !important`,
            xl: `${cols} !important`,
          },
        }}
        gap={8}
      >
        {imageGallery.map((item: any) => (
          <ImageListItem key={item.src}>
            <img srcSet={item.src} src={item.src} alt={item.src} loading="lazy" style={{ cursor: 'pointer' }} onClick={imageClickHandler} />
            {TopBar()}
            {BottomBar()}
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
};
export default ImageResults;
