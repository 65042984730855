import { AdvertisingAccountMetadata, UserDetails, Cards } from '@amzn/genaihub-typescript-client';
import { MbmProvider } from '@amzn/react-arb-tools';
import { ThemeProvider as StormUIThemeProvider } from '@amzn/storm-ui';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles'; // to be removed once text to image workflow is changed
import { QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import Chat from 'src/components/pages/conversational/ChatPage';
import { queryClient } from 'src/components/QueryClient';
import { SnackbarContextProvider } from 'src/components/snackbar/SnackbarContext';
import { SnackbarManager } from 'src/components/snackbar/SnackbarManager';
import { Metrics, STUDIO } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import { getMbmOptions, saveSelectedAdvertisingAccountToLocalStorage } from 'src/helpers';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { useMetrics } from 'src/hooks/useMetrics';
import WithNavigationMetrics from 'src/metrics/WithNavigationMetrics';
import { AppContext, AppContextState, AlertEvent, AccountType } from '../../AppContext';
import EditorP1 from './editor/EditorP1';
import Header from '../components/pages/studio/Header';
import { defaultTheme } from '../config/themes';
import Editor from './editor/legacy/Editor';
import Content from './layout/_storm/Content';
import Footer from './layout/_storm/Footer';
import errorpage from './pages/errorpage';
import InspirationsPage from './pages/InspirationsGallery';
import Home from './pages/storm/Home';
import Playground from './pages/storm/Playground';
import Studio from './pages/studio/StudioPage';
import Tools from './pages/Tools';
import arbManifest from '../i18n/translations/arbManifest';
import './App.css';
import AccountList from './layout/_storm/AccountList';
import CreativeGallery from './pages/CreativeGallery';
import WithAuthentication from './WithAuthentication';

const mbmOptions = getMbmOptions(arbManifest);

const App = ({ page, editor, unAuthenticated }: { page: string; editor?: boolean; unAuthenticated?: boolean }) => {
  console.log('app: page : ', page);
  console.log('editor', editor);
  const [activePage, setActivePage] = useState<string>(page);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [headerDimensions, setHeaderDimensions] = useState<{ width: number; pageHeaderHeight: number; navHeaderHeight: number }>({
    width: 0,
    pageHeaderHeight: 0,
    navHeaderHeight: 0,
  });
  const [footerDimensions, setFooterDimensions] = useState<{ width?: number; height?: number }>({});
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [activeTheme, setActiveTheme] = useState<Theme>(defaultTheme);
  const [colorScheme, setColorScheme] = useState<string>('primary');
  const [tools, setTools] = useState<Cards>([]);
  const [selectedTool, setSelectedTool] = useState<string>();
  const [alertEvent, setAlertEvent] = useState<AlertEvent | undefined>();
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const [accountType, setAccountType] = useState<AccountType>();
  const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(false);
  const [selectedAdvertisingAccount, setSelectedAdvertisingAccount] = useState<AdvertisingAccountMetadata | null>(null);
  const metrics = useMetrics({
    pageName: `${activePage}${openEditor ? '/editor' : ''}`,
    userDetails: userDetails,
    toolname: openEditor && selectedTool ? selectedTool : undefined,
    accountType: accountType,
    selectedAccount: selectedAdvertisingAccount,
  });
  useErrorHandler(metrics);

  const appContextState: AppContextState = {
    activePage,
    setActivePage,
    openEditor,
    setOpenEditor,
    headerDimensions,
    setHeaderDimensions,
    footerDimensions,
    setFooterDimensions,
    authenticated,
    setAuthenticated,
    activeTheme,
    setActiveTheme,
    colorScheme,
    setColorScheme,
    tools,
    setTools,
    selectedTool,
    setSelectedTool,
    showLandingPage: true,
    setShowLandingPage: () => {},
    alertEvent,
    setAlertEvent,
    userDetails,
    setUserDetails,
    metrics,
    accountType,
    setAccountType,
    showSwitchAccountModal,
    setShowSwitchAccountModal,
    selectedAdvertisingAccount,
    setSelectedAdvertisingAccount,
  };

  // Check for external and show account modal if there is none
  // It would have been set by WithAuthentication
  useEffect(() => {
    if (accountType === 'external') {
      setShowSwitchAccountModal(!selectedAdvertisingAccount);
    }
  }, [accountType]);

  // If the advertiser's account changes; save it to storage
  useEffect(() => {
    if (selectedAdvertisingAccount) {
      saveSelectedAdvertisingAccountToLocalStorage(selectedAdvertisingAccount);
    }
  }, [selectedAdvertisingAccount]);

  // Set page styles for editor
  useEffect(() => {
    setActivePage(page);
    setOpenEditor(!!editor);
    localStorage.setItem('activePage', page);
    localStorage.setItem('openEditor', JSON.stringify(!!editor));
    if (page.toLocaleLowerCase() === 'studio') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [page, editor]);

  const pages: { [key: string]: React.FunctionComponent } = {
    home: Home,
    play: Tools,
    browse: InspirationsPage,
    notfounderror: errorpage,
    chat: Chat,
    playground: Playground,
    studio: Studio,
    gallery: CreativeGallery,
  };

  const getContent = () => {
    console.log('activePage', activePage);

    const Content = pages[activePage];
    return openEditor ? selectedTool === 'TEXT_TO_IMAGE' ? <Editor type="editor" /> : <EditorP1 type="editor" module={selectedTool} /> : <Content />;
  };

  const showFooter = !openEditor && ![STUDIO.toLowerCase(), 'chat'].includes(page);

  return (
    <MbmProvider {...mbmOptions}>
      <AppContext.Provider value={appContextState}>
        <ThemeProvider theme={activeTheme}>
          <StormUIThemeProvider>
            <QueryClientProvider client={queryClient}>
              <SnackbarContextProvider>
                <SnackbarManager></SnackbarManager>
                <CssBaseline />
                <WithAuthentication
                  unAuthenticated={unAuthenticated}
                  unAuthenticatedCallback={() => {
                    console.log('unauthenticated');
                    metrics.trackMetrics(Metrics.Methods.PageMetrics, {}, { [Metrics.Names.UserBounced]: 1 });
                    window.location.replace('https://advertising.amazon.com');
                  }}
                >
                  <WithNavigationMetrics activePage={`${activePage}${openEditor ? '/editor' : ''}`}>
                    <div id="wrapper" className="wrapper">
                      {accountType === 'external' && <AccountList />}
                      <Header
                        style={{
                          paddingLeft: 'var(--div-spacing-default)',
                          paddingRight: 'var(--div-spacing-default)',
                          position: activePage === 'chat' ? 'sticky' : 'relative',
                          width: '100%',
                          maxWidth: '2000px',
                          top: 0,
                        }}
                      />
                      <Stack>
                        <Content>{getContent()}</Content>
                        {showFooter && <Footer />}
                      </Stack>
                    </div>
                  </WithNavigationMetrics>
                </WithAuthentication>
              </SnackbarContextProvider>
            </QueryClientProvider>
          </StormUIThemeProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </MbmProvider>
  );
};

export default App;
