import { TextAreaFormGroup } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import cssStyles from './uiform.module.scss';
import { EditorContextStateP1 } from '../../../../../../AppContext';

const TextPromptBox = (props: any) => {
  const { control, bulkUpdateCallback, workflowId } = props;
  const editorContext: EditorContextStateP1 = useContext(props.editorContext);
  const controlValues = editorContext.workflowOptions;
  const controlName = control.controlName;
  const [disabled, setDisabled] = useState<boolean>(true);

  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    console.log('WORKFLOWID :: ', workflowId);
    console.log(editorContext);
    if (editorContext.disableControl) {
      setDisabled(true);
      setOpacity(0.5);
    } else {
      setOpacity(1);
      setDisabled(false);
    }
  }, [editorContext.disableControl]);

  useEffect(() => {
    updateControlValues(editorContext.promptUpdateEvent);
  }, [editorContext.promptUpdateEvent]);

  useEffect(() => {
    const wfo = editorContext.workflowOptions;
    if (wfo.task_type) {
      if (wfo.task_type.value === 'removal') {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [editorContext.workflowOptions]);

  useEffect(() => {
    guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
  }, [editorContext.activateGuide]);

  const guideCallback = (guide: any) => {
    if (guide) {
      const controlData: any = {};
      controlData[control.controlName] = { value: guide.guideCustomValue };

      batchStateUpdate(controlData, bulkUpdateCallback);
    }
  };

  const handleChange = (e: any) => {
    updateControlValues(e.target.value);
  };

  const updateControlValues = (value: string) => {
    if (value !== undefined) {
      if (value === '') {
        const controlValuesCopy = { ...controlValues };
        delete controlValuesCopy[controlName];
        editorContext.setWorkFlowOptions({ ...controlValuesCopy });
      } else {
        const controlData: any = {};
        controlData[controlName] = { value: value };
        editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
      }
    }
  };
  return (
    <>
      <ControlLabel title={control.controlLabel} />
      <TextAreaFormGroup
        fullWidth
        value={controlValues[controlName]?.value}
        onChange={handleChange}
        id="textarea-basic"
        label=""
        hideLabel
        placeholder={
          workflowId === 'LIFESTYLE_IMAGERY' ? "Describe the changes you'd like to make." : "Describe the changes you'd like to make to your image."
        }
        style={{ background: '#f1f3f5', minHeight: '80px', opacity: opacity }}
        disabled={disabled}
        className={cssStyles.inputFieldStyle}
      />
    </>
  );
};

export default TextPromptBox;
