import { Button, Icon, Text, ThemeProvider, Tooltip } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faPencil, faEraser, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Slider } from '@mui/material';
import type Kovna from 'konva';
import React, { useRef, useState, useEffect, useContext, useImperativeHandle, forwardRef, CSSProperties } from 'react';
import { Layer, Stage, Image, Rect } from 'react-konva';
import BoundedBoxSelection from 'src/components/editor/UiContols/uiGeneratorControls/imageEditingControls/BoundedBoxSelection';
import { ImageModalContext } from 'src/components/imageModal';
import FreeEditSelection from 'src/components/imageModal/components/FreeEditSelection/FreeEditSelection';
import { FA_ICON_SIZE } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import usePrevious from 'src/hooks/usePrevious';
import BoundingBoxOff from 'src/icons/editor/editor-boundingBox-off.svg';
import BoundingBoxOn from 'src/icons/editor/editor-boundingBox-on.svg';
import useImage from 'use-image';
import styles from './ImageEditingCanvas.module.scss';
import ControlLabel from '../../../editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';

type KonvaTouchMouseEvent = Kovna.KonvaEventObject<MouseEvent> | Kovna.KonvaEventObject<TouchEvent>;
const overrideMouseLeaveTimeout = (theme: any) => {
  return {
    ...theme,
    tooltip: {
      ...theme.tooltip,
      mouseLeaveTimeout: 0,
    },
  };
};

const ImageEditingCanvas = forwardRef((props: any, forwardRef) => {
  const devTestImage = 'static/media/src/components/imageModal/dev-test-image.png';
  const control = props.control;
  const defaultToolSize = 30;
  const minToolSize = 5;
  const maxToolSize = 50;
  const [tool, setTool] = useState<string>('brush');
  const [toolSize, setToolSize] = useState<number>(defaultToolSize);
  const [hoverBoundedBoxIcon, setHoverBoundedBoxIcon] = useState<boolean>(false);
  const [lines, setLines] = useState<any>([]);
  const [shadowLines, setShadowLines] = useState<any>([]);
  const showBrushSlider = tool === 'brush';
  const showEraserSlider = tool === 'eraser';
  const [disabled, setDisabled] = useState<boolean>(false);
  const [img, setImg] = useState<string>();
  const [image] = useImage(img || '');
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 400, height: 200 });
  const [actualDimensions, setActualDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [scale, setScale] = useState<{ scaleX: number; scaleY: number }>({ scaleX: 0, scaleY: 0 });
  const [boundedBox, setBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'rgba(255, 250, 250, 0.1)',
    // opacity: 0.08,
    stroke: 'black',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });
  const [shadowBoundedBox, setShadowBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'white',
    // opacity: 0.08,
    stroke: 'white',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });
  const [boxStartPos, setBoxStartPos] = useState({
    x: 0,
    y: 0,
  });

  const shadowRef = useRef(null);
  const isDrawing = useRef(false);
  const divRef = useRef<HTMLDivElement>(null);

  const editToolActiveColor = '#4305F4';
  const editSliderToolBarColor = '#CED3DC';
  const editToolInactiveColor = '#A0AABA';
  const editToolBackgroundColor = '#fbfbfc';
  const sliderIconColors = tool === 'brush' || tool === 'eraser' ? { color: editToolActiveColor } : { color: editToolInactiveColor };
  const sliderColors = {
    '& .MuiSlider-thumb': {
      color: editToolActiveColor,
    },
    '& .MuiSlider-track': {
      color: editToolActiveColor,
    },
    '& .MuiSlider-rail': {
      color: editSliderToolBarColor,
    },
    '& .MuiSlider-active': {
      color: editToolActiveColor,
    },
  };

  const { imageUrl, imageReferenceId, savedEditsImageUrl } = useContext(ImageModalContext);
  const activeImageUrl = savedEditsImageUrl || imageUrl;
  const prevActiveImageUrl = usePrevious(activeImageUrl);

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setImageDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (img === undefined && divRef.current) {
      setImageDimensions({
        width: divRef.current.offsetWidth,
        height: 200,
      });
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [img, divRef]);

  useEffect(() => {
    if (!activeImageUrl) {
      setImg(undefined);
      clearCanvas();
      return;
    }

    if (prevActiveImageUrl === activeImageUrl) {
      return;
    }

    const img = document.createElement('img');
    img.id = 'canvasImage';
    img.onload = (event: any) => {
      updateImage(activeImageUrl, event.target.width, event.target.height);
    };
    img.src = activeImageUrl;
    clearCanvas();
  }, [activeImageUrl]);

  // used for unit testing
  useImperativeHandle(forwardRef, () => ({
    updateImage,
    setDisabled,
    getLines: () => lines,
    getShadowLines: () => shadowLines,
    shadowRef: () => shadowRef,
    getBoundedBox: () => boundedBox,
    getShadowBoundedBox: () => shadowBoundedBox,
  }));

  const updateImage = (url: string, width: number, height: number) => {
    setImg(url);
    const newHeight = (imageDimensions.width * height) / width;
    setImageDimensions({ width: imageDimensions.width, height: newHeight });
    setActualDimensions({ width, height });
    setScale({ scaleX: width / imageDimensions.width, scaleY: height / newHeight });
  };

  const srcToFile = (src: string, fileName: string, mimeType: string) => {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      })
      .catch(function (error) {
        console.error('ERROR (srcToFile)', error.message);
      });
  };

  const convertImageUrlToFile = async ({
    canvasRef,
    urlRef,
    label,
    format,
  }: {
    label: string;
    format: 'jpg' | 'png';
    canvasRef?: any;
    urlRef?: string;
  }) => {
    let dataUrl;
    if (canvasRef) {
      dataUrl = (canvasRef as any).toDataURL();
    }
    if (urlRef) {
      dataUrl = urlRef;
    }
    return await srcToFile(dataUrl, `${label}.${format}`, `image/${format}`);
  };

  const updateMask = async () => {
    if (shadowRef.current) {
      const maskFile = await convertImageUrlToFile({ canvasRef: shadowRef.current, label: 'image_mask', format: 'png' });
      props.setImageMask(maskFile);
    }
  };

  const getBaseImageRef = async (baseArg: string) => {
    const baseImageResult = baseArg || (await convertImageUrlToFile({ urlRef: imageUrl, label: 'image_ref', format: 'png' }));
    if (!baseImageResult) {
      console.error('Error: base image not available');
      return;
    }
    props.setBaseImage(baseImageResult);
  };

  useEffect(() => {
    if (!activeImageUrl || activeImageUrl === devTestImage) {
      return;
    }
    const baseRef = activeImageUrl === '' && !imageUrl.includes('catwalk-generated-assets') ? imageReferenceId : activeImageUrl;
    getBaseImageRef(baseRef);
  }, [activeImageUrl, imageReferenceId, savedEditsImageUrl]);

  const handleMouseUpBrush = () => {
    isDrawing.current = false;
    updateMask();
  };

  const handleMouseMoveBrush = (e: KonvaTouchMouseEvent) => {
    if (!isDrawing.current) {
      // no drawing - skipping
      return;
    }
    const stage = e.target?.getStage();
    if (!stage) return;
    const point = stage.getPointerPosition();
    if (!point) return;
    const lastLine = lines[lines.length - 1];
    const shadowLastLine = shadowLines[shadowLines.length - 1];
    // add point
    shadowLastLine.points = shadowLastLine.points.concat([point.x, point.y]);
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    shadowLines.splice(shadowLines.length - 1, 1, shadowLastLine);

    setLines(lines.concat());
    setShadowLines(shadowLines.concat());
  };

  const handleMouseDownBrush = (e: KonvaTouchMouseEvent) => {
    isDrawing.current = true;
    const pos = e.target?.getStage()?.getPointerPosition();
    if (!pos) return;
    if (tool === 'brush') {
      setLines([{ tool, points: [pos.x, pos.y], toolSize }]);
      setShadowLines([{ tool, points: [pos.x, pos.y] }]);
    } else {
      setLines([...lines, { tool, points: [pos.x, pos.y], toolSize }]);
      setShadowLines([...shadowLines, { tool, points: [pos.x, pos.y] }]);
    }
  };

  const handleMouseUpBox = () => {
    isDrawing.current = false;
    updateMask();
  };

  const handleMouseMoveBox = (e: KonvaTouchMouseEvent) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    if (!stage) return;
    const point = stage.getPointerPosition();
    if (!point) return;
    const x = point.x - boundedBox.x;
    const y = point.y - boundedBox.y;

    setBoundedBox({ ...boundedBox, width: x, height: y });
    setShadowBoundedBox({ ...shadowBoundedBox, width: x, height: y });
  };

  const handleMouseDownBox = (e: KonvaTouchMouseEvent) => {
    isDrawing.current = true;
    const pos = e.target?.getStage()?.getPointerPosition();
    if (!pos) return;
    setBoxStartPos(pos);
    setBoundedBox({ ...boundedBox, x: pos.x, y: pos.y, width: 5, height: 5 });
    setShadowBoundedBox({ ...shadowBoundedBox, x: pos.x, y: pos.y, width: 5, height: 5 });
  };

  const clearCanvas = () => {
    setLines([]);
    setShadowLines([]);
    setBoundedBox({ ...boundedBox, width: 0, height: 0 });
    setShadowBoundedBox({ ...shadowBoundedBox, width: 0, height: 0 });
    props.setImageMask(undefined);
    setToolSize(defaultToolSize);
  };

  const handleToolChange = (tool: string) => {
    if (tool === 'clear') {
      clearCanvas();
      setTool('brush');
      return;
    }
    setTool(tool);
  };

  // precise icon styling needed depending on tool and due to some being FA icons and others SVGs.
  const getSelectedToolStyle = (toolName: string) => {
    const boundedBoxPadding = {
      paddingTop: '6px',
      paddingRight: '6px',
      paddingBottom: '0px',
      paddingLeft: '6px',
    };

    const sliderPadding = {
      paddingTop: '2px',
      paddingRight: '8px',
      paddingBottom: '2px',
      paddingLeft: '8px',
    };

    const faIconPadding = {
      padding: '8px',
    };

    let toolIconPadding;
    if (toolName === 'boundedBox') {
      toolIconPadding = boundedBoxPadding;
    } else if (toolName === 'slider') {
      toolIconPadding = sliderPadding;
    } else {
      toolIconPadding = faIconPadding;
    }

    const style: CSSProperties = {
      width: toolName === 'slider' ? '150px' : 'auto',
      ...toolIconPadding,
      borderRadius: '8px',
      boxShadow: 'none',
      border: `1px solid ${editToolBackgroundColor}`,
      color: editToolInactiveColor,
      backgroundColor: editToolBackgroundColor,
    };

    if (toolName === tool && !disabled) {
      style.color = editToolActiveColor;
      style.border = '1px';
      style.borderStyle = 'solid';
      style.borderColor = editToolActiveColor;
    }
    return style;
  };
  const handleMouseDown = (e: KonvaTouchMouseEvent) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') {
        handleMouseDownBrush(e);
      }

      if (tool === 'boundedBox') {
        handleMouseDownBox(e);
      }
    }
  };

  const handleMouseUp = () => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') {
        handleMouseUpBrush();
      }

      if (tool === 'boundedBox') {
        handleMouseUpBox();
      }
    }
  };

  const handleMouseMove = (e: KonvaTouchMouseEvent) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') {
        handleMouseMoveBrush(e);
      }

      if (tool === 'boundedBox') {
        handleMouseMoveBox(e);
      }
    }
  };

  const handleMouseEnter = (toolName: string, e: React.MouseEvent) => {
    if (toolName === 'boundedBox') {
      setHoverBoundedBoxIcon(true);
    }

    if (e.target && 'type' in e.target && e.target.type === 'button') {
      (e.target as HTMLButtonElement).style.color = editToolActiveColor;
    }
  };

  const handleMouseLeave = (toolName: string, e: React.MouseEvent) => {
    if (toolName === 'boundedBox') {
      setHoverBoundedBoxIcon(false);
    }
    // const border = e.target.style.border;
    // firefox and safari bug ... setting border to medium instead of none
    if (toolName === tool) {
      return;
    }
    if (e.target && 'type' in e.target && e.target.type === 'button') {
      (e.target as HTMLButtonElement).style.color = editToolInactiveColor;
    }
  };

  return (
    <div className={styles.ImageEditingCanvasWrapper}>
      <ControlLabel title={control.controlLabel} subTitle={control.description} titleSize={control.titleSize} />

      <div className={styles.editCanvasArea}>
        <div ref={divRef} style={{ width: '100%', height: imageDimensions.height }}>
          <Stage
            width={imageDimensions.width}
            height={imageDimensions.height}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchMove={handleMouseMove}
            onTouchEnd={handleMouseUp}
          >
            <Layer>
              <Image image={image} width={imageDimensions.width} height={imageDimensions.height} cornerRadius={10} />
            </Layer>
            {tool === 'brush' && <FreeEditSelection lines={lines} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={boundedBox} />}
            {tool === 'eraser' && <FreeEditSelection lines={lines} />}
          </Stage>
          {/* This object below is for the image mask being generated */}
          <Stage
            ref={shadowRef}
            width={actualDimensions.width}
            height={actualDimensions.height}
            style={{ border: '1px solid grey', marginBottom: '5px', display: 'none' }}
            scaleX={scale.scaleX}
            scaleY={scale.scaleY}
          >
            <Layer>
              <Rect x={0} y={0} width={imageDimensions.width} height={imageDimensions.height} fill="black"></Rect>
            </Layer>
            {tool === 'eraser' && <FreeEditSelection scale={scale.scaleX} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'brush' && <FreeEditSelection scale={scale.scaleX} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={shadowBoundedBox} />}
          </Stage>
        </div>
        <div className={styles.toolIconsWrapper}>
          <ThemeProvider theme={overrideMouseLeaveTimeout}>
            <Stack className={styles.toolIconsRow}>
              <Tooltip
                trigger={
                  <Button
                    style={getSelectedToolStyle('boundedBox')}
                    onMouseOver={(event) => handleMouseEnter('boundedBox', event)}
                    onMouseOut={(event) => handleMouseLeave('boundedBox', event)}
                    onClick={() => {
                      handleToolChange('boundedBox');
                    }}
                    disabled={disabled}
                  >
                    {(tool === 'boundedBox' && !disabled) || hoverBoundedBoxIcon ? <BoundingBoxOn /> : <BoundingBoxOff />}
                  </Button>
                }
              >
                <Text>Use a bounding box to select the area</Text>
              </Tooltip>
              {!showBrushSlider && (
                <Tooltip
                  trigger={
                    <div>
                      <Button
                        style={getSelectedToolStyle('brush')}
                        onMouseEnter={(event) => handleMouseEnter('brush', event)}
                        onMouseLeave={(event) => handleMouseLeave('brush', event)}
                        onClick={() => {
                          handleToolChange('brush');
                        }}
                        disabled={disabled}
                        autoFocus={tool === 'brush'}
                      >
                        <Icon size={FA_ICON_SIZE.LARGE} type={faPencil as IconDefinition} />
                      </Button>
                    </div>
                  }
                >
                  <Text>Use the drawing tool to select the area</Text>
                </Tooltip>
              )}
              {showBrushSlider && (
                <div className={styles.sliderTool} style={getSelectedToolStyle('slider')}>
                  <Icon
                    size={FA_ICON_SIZE.SMALL}
                    type={faPencil as IconDefinition}
                    style={sliderIconColors}
                    onClick={() => handleToolChange('brush')}
                  />
                  <Slider
                    key={`slider-${toolSize}`}
                    defaultValue={toolSize || defaultToolSize}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    min={minToolSize}
                    max={maxToolSize}
                    onChangeCommitted={(_, sliderValue) => {
                      setToolSize(sliderValue as number);
                    }}
                    sx={sliderColors}
                  />
                  <Icon
                    size={FA_ICON_SIZE.LARGE}
                    type={faPencil as IconDefinition}
                    style={sliderIconColors}
                    onClick={() => handleToolChange('brush')}
                  />
                </div>
              )}
              {!showEraserSlider && (
                <Tooltip
                  trigger={
                    <div>
                      <Button
                        style={getSelectedToolStyle('eraser')}
                        onMouseEnter={(event) => handleMouseEnter('eraser', event)}
                        onMouseLeave={(event) => handleMouseLeave('eraser', event)}
                        onClick={() => {
                          handleToolChange('eraser');
                        }}
                        disabled={disabled || (lines && lines.length === 0)}
                      >
                        <Icon size={FA_ICON_SIZE.LARGE} type={faEraser as IconDefinition} />
                      </Button>
                    </div>
                  }
                >
                  <Text>Refine or remove a selected area</Text>
                  <Text>using the erase tool</Text>
                </Tooltip>
              )}
              {showEraserSlider && (
                <div className={styles.sliderTool} style={getSelectedToolStyle('slider')}>
                  <Icon
                    size={FA_ICON_SIZE.SMALL}
                    type={faEraser as IconDefinition}
                    style={sliderIconColors}
                    onClick={() => handleToolChange('eraser')}
                  />
                  <Slider
                    key={`slider-${toolSize}`}
                    defaultValue={toolSize || defaultToolSize}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    min={minToolSize}
                    max={maxToolSize}
                    onChangeCommitted={(_, sliderValue) => {
                      setToolSize(sliderValue as number);
                    }}
                    sx={sliderColors}
                  />
                  <Icon
                    size={FA_ICON_SIZE.LARGE}
                    type={faEraser as IconDefinition}
                    style={sliderIconColors}
                    onClick={() => handleToolChange('eraser')}
                  />
                </div>
              )}
              <Tooltip
                trigger={
                  <div>
                    <Button
                      style={getSelectedToolStyle('clear')}
                      onMouseEnter={(event) => handleMouseEnter('clear', event)}
                      onMouseLeave={(event) => handleMouseLeave('clear', event)}
                      onClick={() => {
                        handleToolChange('clear');
                      }}
                      disabled={disabled}
                    >
                      <Icon size={FA_ICON_SIZE.LARGE} type={faRotateLeft as IconDefinition} />
                    </Button>
                  </div>
                }
                message="Reset the selection area"
              />
            </Stack>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
});
ImageEditingCanvas.displayName = 'ImageEditingCanvas';
export default ImageEditingCanvas;
