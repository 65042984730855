import { AdvertisingAccountMetadata, SubmitWorkflowByIdOutput } from '@amzn/genaihub-typescript-client';
import React, { createContext } from 'react';
import { AlertTargets } from 'src/constants';
import { MetricsProvider } from 'src/metrics';

// Global Type
export type AccountType = 'internal' | 'external';

// Global Type
export type AlertEvent = {
  target?: AlertTargets;
  control?: {
    controlName: string;
    position: string;
  };
  type: 'info' | 'warning' | 'error' | 'success' | 'feature-introduction';
  header?: string;
  text?: string;
  link?: string;
  linkText?: string;
  code?: number;
  show?: boolean;
  resourceKey?: string;
  resourceArgs?: Record<string, unknown> | undefined;
};

export type AppContextState = {
  activePage: string;
  setActivePage: (page: string) => void;
  openEditor: boolean;
  setOpenEditor: (open: boolean) => void;
  headerDimensions: { width: number; pageHeaderHeight: number; navHeaderHeight: number };
  setHeaderDimensions: (dimensions: { width: number; pageHeaderHeight: number; navHeaderHeight: number }) => void;
  footerDimensions: object;
  setFooterDimensions: (dimensions: object) => void;
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  activeTheme: any;
  setActiveTheme: (theme: any) => void;
  colorScheme: any;
  setColorScheme: (scheme: any) => void;
  tools: any;
  setTools: (tools: any) => void;
  selectedTool: string | undefined;
  setSelectedTool: (selectedTool: any) => void;
  showLandingPage: any;
  setShowLandingPage: (showLandingPage: boolean) => void;
  alertEvent: AlertEvent | undefined;
  setAlertEvent: React.Dispatch<React.SetStateAction<AlertEvent | undefined>>;
  userDetails: UserDetails | undefined;
  setUserDetails: React.Dispatch<React.SetStateAction<UserDetails | undefined>>;
  metrics: MetricsProvider;
  accountType?: AccountType;
  setAccountType: React.Dispatch<React.SetStateAction<AccountType | undefined>>;
  showSwitchAccountModal: boolean;
  setShowSwitchAccountModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAdvertisingAccount: AdvertisingAccountMetadata | null;
  setSelectedAdvertisingAccount: React.Dispatch<React.SetStateAction<AdvertisingAccountMetadata | null>>;
};
export const AppContext = createContext({
  activePage: 'home',
} as AppContextState);

declare global {
  // Duplicate from above
  type AccountType = 'internal' | 'external';

  type UserDetails = {
    alias?: string;
    isExternal?: boolean;
  };

  type FileUploadEvent = {
    payload: File;
    controlName: string;
  };
  
  // Duplicate from above
  type AlertEvent = {
    target?: AlertTargets;
    control?: {
      controlName: string;
      position: string;
    };
    type: 'info' | 'warning' | 'error' | 'success' | 'feature-introduction';
    header?: string;
    text?: string;
    link?: string;
    linkText?: string;
    code?: number;
    show?: boolean;
    resourceKey?: string;
    resourceArgs?: Record<string, unknown> | undefined;
  };
}

export type EditorContextStateP1 = {
  asinMetaData: any;
  setAsinMetaData: React.Dispatch<React.SetStateAction<any>>;
  workflowOptions: any;
  setWorkFlowOptions: React.Dispatch<React.SetStateAction<any>>;
  fileUploadEvent: FileUploadEvent | undefined;
  setFileUploadEvent: React.Dispatch<React.SetStateAction<FileUploadEvent | undefined>>;
  disableControl: any;
  setDisableControl: (disableControl: any) => void;
  promptUpdateEvent: string;
  setPromptUpdateEvent: React.Dispatch<React.SetStateAction<any>>;
  activateGuide: boolean;
  setActivateGuide: React.Dispatch<React.SetStateAction<any>>;
  rawJobResponse?: SubmitWorkflowByIdOutput;
  setRawJobResponse: (rawJobResponse?: SubmitWorkflowByIdOutput) => void;
};

export type EditorContextState = {
  theme?: string | null | undefined;
  workflowOptions: any;
  workflowConfig: any;
  setWorkflowConfig: (workflowConfig: any) => void;
  setWorkFlowOptions: React.Dispatch<React.SetStateAction<any>>;
  openFileUploadDialog: boolean;
  setOpenFileUploadDialog: (open: boolean) => void;
  childControls: any;
  setChildControls: (controls: any) => void;
  fileUploadEvent: FileUploadEvent | undefined;
  setFileUploadEvent: React.Dispatch<React.SetStateAction<FileUploadEvent | undefined>>;
  componentVisibility: any;
  setComponentVisibility: (componentVisibility: any) => void;
  jobInProgress: boolean;
  setJobInProgress: (jobInProgress: boolean) => void;
};
export const EditorContext = createContext({
  theme: null,
} as EditorContextState);

export const EditorContextP1 = createContext({} as EditorContextStateP1);
