import { useEffect } from 'react';
import Spinner from 'src/components/common/storm/Spinner';

const RedirectToAuthPortal = (): JSX.Element => {
  useEffect(() => {
    window.location.replace(location.href.replace(location.host, `auth.${location.host}`));
  });

  return (
    <div style={{ marginTop: '20px' }}>
      <Spinner />
    </div>
  );
};

export default RedirectToAuthPortal;
