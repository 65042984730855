import { Stack } from '@mui/material';
import UIGenerator from './UIGenerator';

const RightPanel = (props: any) => {
  const { componentList } = props;

  return (
    <>
      <Stack
        minWidth={'310px'}
        maxWidth={'310px'}
        direction={'column'}
        padding={'5px'}
        spacing={2}
        style={{
          overflow: 'scroll',
          height: '100%',
          flexWrap: 'nowrap',
          position: 'absolute',
          right: 0,
        }}
      >
        <UIGenerator config={componentList} />
      </Stack>
    </>
  );
};

export default RightPanel;
