import { AssetUrls, BatchResult as BackendBatchResult, BatchResultJobsItem } from '@amzn/genaihub-typescript-client';
import { useMutation, useQuery, UseQueryResult, Query } from '@tanstack/react-query';
import { useState, useEffect, useContext } from 'react';
import { ImageCategory } from 'src/components/imageModal/components/utils';
import { EditActions, WorkflowId } from 'src/components/imageModal/components/utils/types';
import { urlToFile } from 'src/components/utils/base64Encode';
import { uploadImage } from 'src/components/utils/uploadImage';
import { KeysOfPropertyWithType } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { AppContext } from '../../AppContext';

export type useControlsOptions<T extends EditActions> = {
  workFlowId: T['workFlowId'];
  pollingInterval?: number;
  userAlias?: string;
  pageName?: string;
  timeout?: number;
  studioRequest?: boolean;
  defaultWorkflowOptions?: T['workflowOptions'];
  setPendingGeneration?: (pendingGeneration: boolean) => void;
};

interface Job extends BatchResultJobsItem {
  originalUrls?: AssetUrls;
}

export interface BatchResult extends BackendBatchResult {
  jobs: Job[];
}

export const useWorkflow = <T extends EditActions>(options: useControlsOptions<T>) => {
  const { defaultWorkflowOptions, timeout = 120000, workFlowId, pollingInterval = 2000, studioRequest = true, setPendingGeneration } = options;
  const [submissionTime, setSubmissionTime] = useState<number>();
  type WorkflowOptionsType = T['workflowOptions'];
  const [workflowOptions, setWorkflowOptions] = useState<WorkflowOptionsType>(defaultWorkflowOptions!);
  const appContext = useContext(AppContext);
  const client = useAIBackendHubClient();

  const submitWorkflowQuery = useMutation({
    mutationFn: async (workflowOptions: WorkflowOptionsType) => {
      const response = await client.submitWorkflowById({
        workflowId: workFlowId,
        body: {
          workflowOptions: {
            ...workflowOptions,
          },
        },
      });
      const { batchId } = response.body;
      if (batchId) {
        return batchId;
      } else {
        throw new Error('No batchId found');
      }
    },
    onSuccess: () => {
      setSubmissionTime(Date.now());
    },
  });
  const batchId = submitWorkflowQuery.data;

  type StringKeys = KeysOfPropertyWithType<WorkflowOptionsType, string>;
  type UploadFilesPayload = Partial<{
    [key in StringKeys]: {
      urlOrFile: string | File;
      contentCategory: ImageCategory;
      fileTypeOverride?: string;
    };
  }>;
  const uploadFileQuery = useMutation({
    mutationFn: async (options: UploadFilesPayload) => {
      const keys = Object.keys(options) as StringKeys[];
      const results: Partial<Record<StringKeys, string>> = {};
      const promises = keys.map(async (key) => {
        const { urlOrFile, fileTypeOverride, contentCategory } = options[key]!;

        // this block is ONLY for images with a valid imageReferenceId and not images in the generated results
        if (
          typeof urlOrFile === 'string' &&
          !urlOrFile.startsWith('http') &&
          !urlOrFile.startsWith('blob') &&
          (workFlowId === WorkflowId.IMAGE_THEMING || contentCategory === ImageCategory.REFERENCE_IMAGE)
        ) {
          const response = await client.retrieveAsset({
            id: urlOrFile,
            entityId: appContext?.selectedAdvertisingAccount?.alternateIds?.[0],
          });
          const url = response?.body.asset?.uri || '';
          const fileTypeOverride = 'image/png';
          if (url) {
            results[key] = await uploadImage(await urlToFile(url, fileTypeOverride), client, contentCategory);
            return;
          } else {
            throw new Error('No url found for the asset');
          }
        }

        const file = typeof urlOrFile === 'string' ? await urlToFile(urlOrFile, fileTypeOverride) : urlOrFile;
        results[key] = await uploadImage(file, client, contentCategory);
      });
      await Promise.all(promises);
      return results as Partial<WorkflowOptionsType>;
    },
    onSuccess: (ref) => {
      const payload: WorkflowOptionsType = { ...workflowOptions, ...ref };
      submitWorkflowQuery.mutate(payload);
    },
  });
  const batchJobQuery: UseQueryResult<BatchResult> = useQuery({
    queryKey: ['useWorkflow', 'retrieveResultByWorkflowIdAndBatchId', submitWorkflowQuery.data],
    refetchInterval: (query) => {
      if (!submitWorkflowQuery.data) return false;
      if (!submissionTime) return false;
      try {
        const {
          state: { data },
        } = query;

        const status = data?.jobs?.[0].status;
        return status === 'RUNNING' ? pollingInterval : false;
      } catch {
        return false;
      }
    },
    refetchIntervalInBackground: true,
    queryFn: async ({ signal }) => {
      signal.onabort = () => {
        setSubmissionTime(undefined);
        submitWorkflowQuery.reset();
        uploadFileQuery.reset();
      };
      if (Date.now() - submissionTime! > timeout) {
        setSubmissionTime(undefined);
        throw new Error('Job timed out');
      }
      const { body } = <{ body: BatchResult }>await client!.retrieveResultByWorkflowIdAndBatchId({
        workflowId: workFlowId,
        batchId: batchId!,
        studioRequest,
      });

      // load images at background before complete the query
      if (body.jobs?.[0].status === 'COMPLETED') {
        for (let i = 0; i < body.jobs.length; i++) {
          body.jobs[i].originalUrls = body.jobs[i].urls || [];
          body.jobs[i].urls = await Promise.all((body.jobs[i].urls || []).map(async (url) => URL.createObjectURL(await urlToFile(url, 'image/png'))));
        }

        const urls = body.jobs?.[0]?.urls || [];
        const promises = urls.map((url) => {
          const img = new Image();
          img.src = url;
          return new Promise((resolve) => (img.onload = resolve));
        });
        await Promise.all(promises);
      }

      if (body.jobs?.[0].status !== 'RUNNING') {
        setSubmissionTime(undefined);
      }

      return body;
    },
    enabled: (query: Query<BatchResult>) => {
      const {
        state: { data },
      } = query;
      const status = data?.jobs?.[0].status;
      return !!submitWorkflowQuery.data && submissionTime !== undefined && status !== 'COMPLETED';
    },
  });

  useEffect(() => {
    setPendingGeneration?.(uploadFileQuery.isPending || submitWorkflowQuery.isPending || !!submissionTime);
  }, [uploadFileQuery.isPending, submitWorkflowQuery.isPending, submissionTime]);

  return {
    workflowOptions,
    updateWorkflowOptions: (value: Partial<WorkflowOptionsType>) => setWorkflowOptions({ ...workflowOptions, ...value }),
    submitWorkflow: async (payload?: UploadFilesPayload) => {
      if (!workflowOptions) {
        return;
      }
      if (payload) uploadFileQuery.mutate(payload);
      else submitWorkflowQuery.mutate(workflowOptions);
    },
    uploadFileQuery,
    workflowQuery: submitWorkflowQuery,
    submissionQuery: batchJobQuery,
    isPending: uploadFileQuery.isPending || submitWorkflowQuery.isPending || !!submissionTime,
    isError: uploadFileQuery.isError || submitWorkflowQuery.isError || batchJobQuery.isError,
    error: uploadFileQuery.error || submitWorkflowQuery.error || batchJobQuery.error,
  };
};
