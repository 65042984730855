import { Button, Text, Icon } from '@amzn/storm-ui';
import { check } from '@amzn/storm-ui-icons';
import { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import CardLoader from 'src/components/common/storm/CardLoader';
import { SubmittedFeedback } from 'src/components/feedback/FeedbackContext';
import { ImageWithThumbnails, ImageWithThumbnailsRef, Image, ImageState } from 'src/components/image/ImageWithThumbnails';
import { ImageModalContext } from 'src/components/imageModal';
import { ImageModalErrorCard } from 'src/components/imageModal/components/ImageModalErrorCard';
import { WorkflowSubmission } from 'src/util/WorkflowSubmission';
import { v6 as uuidV6 } from 'uuid';
import styles from './ImageModalImageViewer.module.scss';

export interface DefaultImageModalImageViewerProps {
  sourceImageUrl: string;
  handleAcceptEdits?: () => void;
  reframeAspectRatio?: string;
  reframeDirection?: string;
}

const DefaultImageModalImageViewer: FC<DefaultImageModalImageViewerProps> = ({
  sourceImageUrl,
  handleAcceptEdits,
  reframeAspectRatio,
  reframeDirection,
}) => {
  const imageWithThumbnailsRef = useRef<ImageWithThumbnailsRef | null>(null);
  const [imageWithThumbnailsIsLoading, setImageWithThumbnailsIsLoading] = useState<boolean>(true);
  const imageModalContext = useContext(ImageModalContext);
  const activeEditResults = imageModalContext.activeEditResults;
  const activeEditErrorMessage = imageModalContext.activeEditErrorMessage;
  const job = activeEditResults?.jobs?.[0];
  const jobHasResults = !!job?.urls?.length;
  const isJobFailed = job?.status === WorkflowSubmission.FAILED;
  const isJobCompleted = job?.status === WorkflowSubmission.COMPLETED;
  const isGeneratingResults = imageModalContext.pendingGeneration;
  const isIdle = !activeEditResults && !isGeneratingResults && !isJobFailed && !activeEditErrorMessage;
  const canAccept = isJobCompleted && !!imageModalContext.activeEditsImageUrl;
  const isPublishingAsset = imageModalContext.isPublishingAsset;

  useEffect(() => {
    if (isGeneratingResults) return;
    if (job && job.urls?.length && job.originalUrls?.length) {
      const images: Image[] = job.urls.map((url: string, index: number) => ({
        // Generating an asset ID since job.ids is no longer populated
        referenceId: uuidV6(),
        originalUrl: job.originalUrls![index],
        url,
      }));
      imageWithThumbnailsRef.current?.setImages(images);
    } else {
      imageWithThumbnailsRef.current?.setImages([]);
    }
  }, [job]);

  const onChangeImageWithThumbnailsIsLoading = (isLoading: boolean) => {
    setImageWithThumbnailsIsLoading(isLoading);
  };

  const onChangeSelectedImage = (selectedImage: ImageState | undefined) => {
    if (selectedImage) {
      imageModalContext.setActiveEditsImageFeedback(selectedImage.feedback);
      imageModalContext.setActiveEditsImageReferenceId(selectedImage.image.referenceId);
      imageModalContext.setActiveEditsImageUrl(selectedImage.image.url);
      imageModalContext.setActiveEditsOriginalImageUrl(selectedImage.image.originalUrl);
    } else {
      imageModalContext.setActiveEditsImageFeedback(undefined);
      imageModalContext.setActiveEditsImageReferenceId(undefined);
      imageModalContext.setActiveEditsImageUrl(undefined);
      imageModalContext.setActiveEditsOriginalImageUrl(undefined);
    }
  };

  const onSubmittedFeedback = (submittedFeedback: SubmittedFeedback) => {
    if (imageModalContext.activeEditsImageReferenceId === submittedFeedback.feedbackMetadata?.referenceId) {
      imageModalContext.setActiveEditsImageFeedback(submittedFeedback.feedback);
    }
  };

  const getReframeDirectionStyleFlexAlign = (direction: string | undefined) => {
    if (!direction) return 'center';
    if (direction.endsWith('right') || direction.endsWith('down')) {
      return 'flex-start';
    }
    if (direction.endsWith('left') || direction.endsWith('up')) {
      return 'flex-end';
    }
    return 'center';
  };

  return (
    <div className={styles.container} data-testid="ImageModalImageViewer-container">
      {isIdle && (
        <div
          data-testid="ImageModalImageViewer-aspectRatioWrapper"
          className={reframeAspectRatio ? styles.reframeWrapper : undefined}
          style={
            reframeAspectRatio
              ? {
                  aspectRatio: reframeAspectRatio,
                  justifyContent: getReframeDirectionStyleFlexAlign(reframeDirection),
                  alignItems: getReframeDirectionStyleFlexAlign(reframeDirection),
                }
              : undefined
          }
        >
          <img className={styles.originalImage} src={sourceImageUrl} alt="Image modal main image" />
        </div>
      )}
      {isGeneratingResults && <CardLoader style={{ alignItems: 'flex-start' }} />}
      {isJobFailed && <ImageModalErrorCard message={job?.message || 'Something went wrong'} />}
      {!!activeEditErrorMessage && <ImageModalErrorCard message={activeEditErrorMessage || 'Something went wrong'} />}
      {isJobCompleted && !jobHasResults && (
        <ImageModalErrorCard message={`We're sorry, your images were filtered out due to policy. Please reformulate your inputs and try again.`} />
      )}
      {isJobCompleted && jobHasResults && (
        <div className={styles.resultsContainer}>
          <div className={styles.header}>
            <Text className={styles.headerText} type="h3">
              Image results
            </Text>
            <div className={styles.resultButtons}>
              <Button
                className={styles.resetButton}
                disabled={isPublishingAsset}
                onClick={() => {
                  imageModalContext.clearActiveEdit();
                }}
              >
                Reset
              </Button>
              <Button
                className={styles.acceptButton}
                disabled={!canAccept || imageWithThumbnailsIsLoading || isPublishingAsset}
                onClick={() => {
                  handleAcceptEdits?.();
                }}
              >
                {isPublishingAsset ? (
                  <>Saving</>
                ) : (
                  <>
                    <Icon type={check} /> Select image
                  </>
                )}
              </Button>
            </div>
          </div>
          <ImageWithThumbnails
            onChangeIsLoading={onChangeImageWithThumbnailsIsLoading}
            onChangeSelectedImage={onChangeSelectedImage}
            onSubmittedFeedback={onSubmittedFeedback}
            ref={imageWithThumbnailsRef}
          />
        </div>
      )}
    </div>
  );
};

export const ImageModalImageViewer = memo(DefaultImageModalImageViewer);
