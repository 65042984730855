import { Stack } from '@mui/material';
import UIGenerator from './UIGenerator';

const LeftPanel = (props: any) => {
  const { componentList } = props;

  return (
    componentList &&
    componentList.length > 0 && (
      <>
        <Stack minWidth={'300px'} padding={1} direction={'column'} spacing={2}>
          <UIGenerator config={componentList} />
        </Stack>
      </>
    )
  );
};

export default LeftPanel;
