import { BatchResult, PublishAssetInput, WorkflowId } from '@amzn/genaihub-typescript-client';
import { Modal, TabbedNavigationItem } from '@amzn/storm-ui';
import _cloneDeep from 'lodash/cloneDeep';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/../AppContext';
import { TabbedNavigation } from 'src/components/common/storm/TabbedNavigation';
import { ImageModalCloseConfirmPopup } from 'src/components/imageModal/ImageModalCloseConfirmPopup';
import { SaveImageToAccountButton } from 'src/components/imageModal/SaveImageToAccountButton';
import { ContentItem, StudioContext } from 'src/components/pages/studio/StudioContext';
import { PublishAssetFailureNotification, PublishAssetProcessingNotification } from 'src/components/snackbar/notifications/PublishAssetNotifications';
import { SnackbarContext } from 'src/components/snackbar/SnackbarContext';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import Arrow from 'src/images/icons/arrow.svg';
import { DownloadImageButton } from './DownloadImageButton';
import { ImageModalContext } from './ImageModalContext';
import { EditModes, ImageModalEditTab } from './ImageModalEditTab';
import { ImageModalPreviewTab } from './ImageModalPreviewTab';
import styles from './styles.module.scss';

export const TEST_ID_IMAGE_MODAL_PREVIOUS_ARROW = 'image-modal-previous-arrow';
export const TEST_ID_IMAGE_MODAL_NEXT_ARROW = 'image-modal-next-arrow';

export const useImageModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return {
    isOpen,
    closeModal,
    openModal,
  };
};

const PREVIEW_TAB_INDEX = 0;

const findUrl = (urls: string[] | undefined, searchUrl: string) => {
  if (!urls || !urls.length || !searchUrl) return;
  const searchUrlObj = new URL(searchUrl);
  const baseSearchUrl = searchUrlObj.origin + searchUrlObj.pathname;
  return urls.find((url) => url?.startsWith(baseSearchUrl));
};

export const ImageModal: FC<{
  currentItem?: ContentItem;
  isOpen: boolean;
  currentTab?: number;
  closeModal: () => void;
  onCloseModal?: () => void;
}> = ({ currentItem, isOpen, currentTab, closeModal, onCloseModal }) => {
  const toggleRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [imageModalCloseConfirmPopupCallback, setImageModalCloseConfirmPopupCallback] = useState<{ callback: () => void }>();
  const appContext = useContext(AppContext);
  const imageModalContext = useContext(ImageModalContext);
  const studioContext = useContext(StudioContext);
  const snackbarContext = useContext(SnackbarContext);
  const genAIBackendClient = useAIBackendHubClient();
  const downloadOrSaveImageUrl = imageModalContext.savedEditsImageUrl || imageModalContext.imageUrl;
  const canSaveAsset = !!(appContext.accountType === 'external' && appContext.selectedAdvertisingAccount);

  const localOnCloseModal = () => {
    setActiveTab(PREVIEW_TAB_INDEX);
    imageModalContext.setPendingGeneration(false);
    closeModal();
    onCloseModal?.();
  };

  useEffect(() => {
    setActiveTab(currentTab ?? 0);
  }, [currentTab]);

  const handleDiscardEdits = () => {
    imageModalContext.clearActiveEdit();
    imageModalCloseConfirmPopupCallback?.callback?.();
  };

  const handleAcceptEdits = async () => {
    const activeEditResults = imageModalContext.activeEditResults;
    const editMode = imageModalContext.editMode;
    if (currentItem && imageModalContext.activeEditsImageUrl) {
      imageModalContext.setIsPublishingAsset(true);

      const newImageUrl = imageModalContext.activeEditsImageUrl;
      const newImageOriginalUrl = imageModalContext.activeEditsOriginalImageUrl;
      const newImageReferenceId = imageModalContext.activeEditsImageReferenceId;
      const newImageAspectRatio = imageModalContext.activeEditsAspectRatio;
      const savedImageAspectRatio = imageModalContext.savedEditsImageAspectRatio;
      const newImageFeedback = _cloneDeep(imageModalContext.activeEditsImageFeedback);

      const newResult = {
        ..._cloneDeep(currentItem),
        content: newImageUrl,
        originalUri: newImageUrl,
        referenceId: newImageReferenceId,
        feedback: newImageFeedback,
        ...(editMode === EditModes.REFRAME
          ? { aspectRatio: newImageAspectRatio }
          : savedImageAspectRatio
            ? { aspectRatio: savedImageAspectRatio }
            : {}),
        studioInputSettings: {
          ..._cloneDeep(currentItem.studioInputSettings),
          ...(editMode === EditModes.REFRAME ? { format: newImageAspectRatio } : savedImageAspectRatio ? { format: savedImageAspectRatio } : {}),
        },
      };

      // Publish Asset
      let workflowId: WorkflowId = 'IMAGE_EDITOR';
      if (editMode && editMode === 'Restyle') workflowId = 'IMAGE_THEMING';
      const batchId = activeEditResults?.batchId;
      const jobId = activeEditResults!.jobs?.[0].jobId;

      const cleanupProcessingNotification = snackbarContext.addProcessingNotification({
        SnackbarContent: PublishAssetProcessingNotification,
      });
      let isError = false;

      if (activeEditResults && newImageReferenceId && newImageOriginalUrl && batchId && jobId) {
        try {
          // Obtain a fresh signed URL from Catwalk
          const response: { body: BatchResult } = await genAIBackendClient.retrieveResultByWorkflowIdAndBatchId({
            workflowId,
            batchId,
            studioRequest: true,
          });
          const job = response.body?.jobs?.find((job) => job?.jobId === jobId);
          const freshImageOriginalUrl = findUrl(job?.urls, newImageOriginalUrl);
          // Store asset in Feed
          const payload: PublishAssetInput = {
            workflowId,
            entityId: appContext?.selectedAdvertisingAccount?.alternateIds?.[0],
            body: {
              feedOptions: {
                jobId,
                batchId,
                assetUrl: freshImageOriginalUrl ?? newImageOriginalUrl,
                aspectRatio: newResult.aspectRatio,
              },
            },
          };
          const publishRespose = await genAIBackendClient.publishAsset(payload);
          if (publishRespose.body?.assetId) {
            newResult.referenceId = publishRespose.body.assetId;
            // @ts-ignore
          } else if (publishRespose.body?.body?.assetId) {
            // @ts-ignore
            newResult.referenceId = publishRespose.body.body!.assetId;
          } else {
            throw new Error("Publish asset response missing 'assetId'");
          }
        } catch (error) {
          isError = true;
          console.error('Failed to publish asset', error);
        }
      } else {
        isError = true;
        const missingData = [];
        if (!activeEditResults) missingData.push('Edit Results');
        if (!batchId) missingData.push('Batch ID');
        if (!jobId) missingData.push('Job ID');
        if (!newImageReferenceId) missingData.push('Image ID');
        if (!newImageOriginalUrl) missingData.push('Image URL');
        console.error('Failed to publish asset. Missing the following data:', missingData);
      }

      imageModalContext.setIsPublishingAsset(false);
      imageModalContext.setImageReferenceId(newResult.referenceId || imageModalContext.imageReferenceId);
      cleanupProcessingNotification();
      if (isError) {
        snackbarContext.addFailureNotification({ SnackbarContent: PublishAssetFailureNotification });
      } else {
        studioContext.prependResults([newResult]);
        imageModalContext.setSavedEditsImageUrl(newImageUrl);
        imageModalContext.setSavedEditsImageReferenceId(newImageReferenceId);
        // Only update the saved aspect ratio with a new value if edit mode was Reframe.
        // This ensures Reframe uses the correct aspect ratio on successive edits.
        imageModalContext.setSavedEditsImageAspectRatio(editMode === EditModes.REFRAME ? newImageAspectRatio : savedImageAspectRatio);
        imageModalContext.clearActiveEdit();
        imageModalCloseConfirmPopupCallback?.callback?.();
      }
    } else {
      console.error('Failed to accept edit.');
      snackbarContext.addFailureNotification({ SnackbarContent: PublishAssetFailureNotification });
      imageModalContext.clearActiveEdit();
      imageModalCloseConfirmPopupCallback?.callback?.();
    }
  };

  const tabs = [
    {
      label: 'Preview',
      child: <ImageModalPreviewTab />,
    },
    {
      label: 'Edit',
      child: (
        <ImageModalEditTab
          handleAcceptEdits={handleAcceptEdits}
          openConfirmModal={(callback) => setImageModalCloseConfirmPopupCallback({ callback })}
        />
      ),
    },
  ];

  const handleChangeActiveTab = (index: number) => {
    if (imageModalContext.unsavedWork) {
      setImageModalCloseConfirmPopupCallback({
        callback: () => {
          imageModalContext.clearActiveEdit();
          setActiveTab(index);
        },
      });
    } else {
      imageModalContext.clearActiveEdit();
      setActiveTab(index);
    }
  };

  return (
    <>
      <div id="studio-preview-modal" className={styles.imageModal}>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            if (imageModalContext.unsavedWork || imageModalContext.pendingGeneration) {
              setImageModalCloseConfirmPopupCallback({ callback: localOnCloseModal });
            } else {
              localOnCloseModal();
            }
          }}
          header={
            <div className={styles.navigationHeader}>
              <TabbedNavigation>
                {tabs.map(({ label }, index) => (
                  <TabbedNavigationItem
                    className={styles.navItem}
                    role={'tab'}
                    name={label}
                    key={index}
                    label={label}
                    active={activeTab === index}
                    onClick={() => handleChangeActiveTab(index)}
                  />
                ))}
              </TabbedNavigation>
              <div className={styles.buttons}>
                {!!downloadOrSaveImageUrl && (
                  <>
                    <DownloadImageButton url={downloadOrSaveImageUrl} disabled={imageModalContext.isPublishingAsset} />
                    <SaveImageToAccountButton assetUrl={downloadOrSaveImageUrl} disabled={!canSaveAsset || imageModalContext.isPublishingAsset} />
                  </>
                )}
              </div>
            </div>
          }
          modalElementId="studio-preview-modal"
        >
          {tabs[activeTab].child}
        </Modal>
        {isOpen && activeTab === PREVIEW_TAB_INDEX && (
          <>
            <div
              data-testid={TEST_ID_IMAGE_MODAL_PREVIOUS_ARROW}
              aria-label="Show previous result"
              className={`${styles.arrowNavigation} ${styles.left}`}
              onClick={() => imageModalContext.handleSwitchToPrevContentItem()}
              role="button"
            >
              <Arrow />
            </div>
            <div
              data-testid={TEST_ID_IMAGE_MODAL_NEXT_ARROW}
              aria-label="Show next result"
              className={`${styles.arrowNavigation} ${styles.right}`}
              onClick={() => imageModalContext.handleSwitchToNextContentItem()}
              role="button"
            >
              <Arrow />
            </div>
          </>
        )}
      </div>
      <div id={'image-modal-close-confirm'} ref={toggleRef} />
      <ImageModalCloseConfirmPopup
        isOpen={!!imageModalCloseConfirmPopupCallback}
        onSave={() => {
          handleAcceptEdits();
        }}
        onDiscard={() => {
          handleDiscardEdits();
        }}
        onClose={() => {
          setImageModalCloseConfirmPopupCallback(undefined);
        }}
      />
    </>
  );
};
