import { Box, Divider, Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { useRef, useContext, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Metrics } from 'src/constants';
import styles from './Footer.module.scss';
import { AppContext } from '../../../../AppContext';
import { footer } from '../../../config';
import navItems from '../../../config/navigation.json';
import { useContainerDimensions } from '../../utils/ContainerDimensions';

import { getPageName } from '../../utils/getPageName';

interface FooterProps {
  title: string;
  description: string;
}

export default function Footer() {
  const componentRef = useRef(null);
  const context = useContext(AppContext);
  const { metrics } = context;
  const { width, height } = useContainerDimensions(componentRef);
  const footerProps: FooterProps = footer;
  const activeNav = { fontWeight: '700', color: '#FFF' };

  const linkTo = useNavigate();

  const handleNavButtonClick = (path: string) => {
    metrics.trackMetrics(
      Metrics.Methods.FooterClick,
      { [Metrics.Names.ButtonId]: getPageName(path) || Metrics.Values.Home },
      { [Metrics.Counters.Clicks]: 1 },
    );
    linkTo(path);
  };

  useEffect(() => {
    context.setFooterDimensions({ width, height });
    console.log('footer dimension', width, height);
  }, [height]);

  return (
    <div ref={componentRef} data-testid="footer-container">
      <Box
        className="page-padding"
        sx={{
          position: 'relative',
          paddingTop: '40px',
          paddingBottom: '40px',
          background: 'linear-gradient(90deg, rgba(73,95,255,1) 0%, rgba(80,163,255,1) 52%, rgba(127,204,255,1) 100%)',
        }}
      >
        <Stack direction={'column'} gap={2}>
          <Typography variant="h1" component="h1" sx={{ color: 'white' }}>
            {footerProps.title}
          </Typography>

          <Typography variant="h3" component="div" sx={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: footerProps.description }}></Typography>

          <Stack
            className={styles.flexColumn}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'row'}
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Stack
              className={styles.footerLinks}
              gap={1}
              alignItems={'center'}
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              maxWidth={'100%'}
              style={{ marginTop: '20px' }}
              data-testid="footer-nav-container"
            >
              {navItems.map((item, index) => (
                <Fragment>
                  <Link
                    key={index}
                    underline="none"
                    style={{ cursor: 'pointer', justifyContent: 'left', padding: 0 }}
                    onClick={() => {
                      handleNavButtonClick(item.path);
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      style={item.label.toLocaleLowerCase() === context.activePage ? activeNav : { color: '#FFF', fontWeight: '400' }}
                    >
                      {item.label}
                    </Typography>
                  </Link>
                  {index != navItems.length - 1 && (
                    <div
                      className={styles.defaultFooterDivider}
                      style={{ width: '5px', height: '5px', marginLeft: '10px', marginRight: '10px', backgroundColor: 'white' }}
                    ></div>
                  )}
                  <Divider
                    orientation="horizontal"
                    flexItem
                    sx={{ display: { xs: 'inline', md: 'none', color: 'white', borderColor: 'white' } }}
                  ></Divider>
                </Fragment>
              ))}
            </Stack>
            <Typography
              className={styles.copyrightText}
              marginLeft={{ sm: 0 }}
              variant="body1"
              component="div"
              sx={{ color: '#FFF', fontSize: '16px', marginTop: '20px' }}
            >
              © Copyright 2015-2024, Amazon{' '}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
}
