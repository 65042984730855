import { ASPECT_RATIO } from 'src/components/imageModal/components/utils/types';

export const getSizeWithAspectRatio: (
  option:
    | { width: number; aspectRatio: ASPECT_RATIO }
    | {
        height: number;
        aspectRatio: ASPECT_RATIO;
      },
) => { width: number; height: number } = ({ aspectRatio: aspectsRatio, ...option }) => {
  const [w, h] = aspectsRatio.split(':').map((str: string) => Number.parseFloat(str));
  if (!w || !h) {
    throw new Error('Invalid aspects ratio');
  }
  if ('width' in option) {
    const { width } = option;
    return {
      width,
      height: width * (h / w),
    };
  }

  if ('height' in option) {
    const { height } = option;
    return {
      height,
      width: (height * w) / h,
    };
  }

  throw new Error('Size must have width or height.');
};
