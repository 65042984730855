import Genaihubbackend, { RetrieveResultByWorkflowIdAndBatchIdOutput, SubmitWorkflowByIdInput, WorkflowId } from '@amzn/genaihub-typescript-client';
import { ASINItem } from 'src/components/pages/studio/StudioContext';
import { sanitiseText } from 'src/helpers';

export interface WorkflowInvocationJob {
  input: SubmitWorkflowByIdInput;
  prompt?: string;
  output: RetrieveResultByWorkflowIdAndBatchIdOutput;
}

interface TextToImageWorkflowProps {
  asinItem: ASINItem;
  client: Genaihubbackend;
  productImage: string;
  aspectRatio?: string;
  effects?: string;
  outputCount?: number;
  prompt?: string;
  theme?: string;
  adsEntityId?: string;
}

interface LifestyleImageryWorkflowProps {
  client: Genaihubbackend;
  asinItem?: ASINItem;
  aspectRatio?: string;
  effects?: string;
  outputCount?: number;
  prompt?: string;
  theme?: string;
  rewriteUserCustomPrompt?: boolean;
  adsEntityId?: string;
}

export interface TextGenerationWorkflowProps {
  client: Genaihubbackend;
  asinItem?: ASINItem;
  outputCount?: number;
  isProductLessLifestyle?: boolean;
  prompt?: string;
  theme?: string;
}

export class WorkflowService {
  invokeTextToImageWorkflow(props: TextToImageWorkflowProps) {
    const { asinItem, client, productImage, aspectRatio, effects, outputCount = 1, prompt, theme, adsEntityId } = props;

    let textPrompt = prompt;

    const workflowId: WorkflowId = 'TEXT_TO_IMAGE';
    return new Promise<WorkflowInvocationJob>(async (resolve, reject) => {
      try {
        if (!textPrompt) {
          const jobs = (
            await this.invokeTextGenerationWorkflow({
              asinItem,
              client,
            })
          ).output.body.jobs;
          if (jobs && jobs.length > 0 && jobs[0].urls && jobs[0].urls.length > 0) {
            textPrompt = jobs[0].urls[0];
          }
        }
        const sanitisedPrompt = sanitiseText(textPrompt || '');
        const payload: SubmitWorkflowByIdInput = {
          studioRequest: true,
          workflowId: workflowId,
          body: {
            workflowOptions: {
              text_prompt: this.constructInputPrompt(sanitisedPrompt, effects),
              product_image: productImage,
              reference_image: '',
              themes: theme || 'no_theme',
              aspect_ratio: aspectRatio || '1:1',
              scaling: '',
              rotate: '',
              num_of_images: outputCount,
              asin: asinItem?.asin || '',
            },
          },
        };
        const result = await client.submitWorkflowById(payload);
        if (!result?.body?.batchId) {
          reject('job submission failed');
          return;
        }

        const generationResult = await this.pollServerForResults(client, workflowId, result.body.batchId, adsEntityId);
        resolve({ input: payload, prompt: textPrompt, output: generationResult });
      } catch (error) {
        reject(error);
      }
    });
  }

  invokeLifestyleImageryWorkflow(props: LifestyleImageryWorkflowProps) {
    const { asinItem, client, aspectRatio, effects, outputCount = 1, prompt, theme, rewriteUserCustomPrompt = true, adsEntityId } = props;

    const workflowId: WorkflowId = 'LIFESTYLE_IMAGERY';
    return new Promise<WorkflowInvocationJob>(async (resolve, reject) => {
      const sanitisedPrompt = prompt && sanitiseText(prompt);
      const payload: SubmitWorkflowByIdInput = {
        workflowId: workflowId,
        studioRequest: true,
        body: {
          workflowOptions: {
            asin: asinItem?.asin || ' ',
            asin_ref: asinItem?.asin || ' ',
            feature_bullets: (asinItem?.metadata?.featureBullets || [' ']).map((item) => sanitiseText(item)),
            image_count: outputCount,
            prompt: this.constructInputPrompt(sanitisedPrompt, effects),
            rewriteUserCustomPrompt: rewriteUserCustomPrompt.toString(),
            theme: theme || 'no_theme',
            aspect_ratio: aspectRatio || '1:1',
            title: sanitiseText(asinItem?.metadata?.title || ' '),
          },
        },
      };

      try {
        const result = await client.submitWorkflowById(payload);
        if (!result?.body?.batchId) {
          reject('job submission failed');
          return;
        }

        const generationResult = await this.pollServerForResults(client, workflowId, result.body.batchId, adsEntityId);
        resolve({ input: payload, prompt: sanitisedPrompt, output: generationResult });
      } catch (error) {
        reject(error);
      }
    });
  }

  invokeTextGenerationWorkflow(props: TextGenerationWorkflowProps) {
    const { asinItem, client, outputCount = 1, prompt = '', theme, isProductLessLifestyle = false } = props;

    const workflowId = 'GUIDED_TEXT_GENERATION';
    return new Promise<WorkflowInvocationJob>(async (resolve, reject) => {
      const payload: SubmitWorkflowByIdInput = {
        workflowId: workflowId as any,
        body: {
          workflowOptions: {
            asin: asinItem?.asin,
            asin_ref: asinItem?.asin,
            feature_bullets: asinItem?.metadata?.featureBullets?.map((item) => sanitiseText(item)),
            image_count: outputCount,
            title: sanitiseText(asinItem?.metadata?.title || ''),
            ...(isProductLessLifestyle ? { prompt_type: { value: 'PRODUCT_LESS_LIFESTYLE' } } : {}),
            prompt: prompt,
            theme: theme || 'no_theme',
          },
        },
      };

      try {
        const result = await client.submitWorkflowById(payload);
        if (!result?.body?.batchId) {
          reject('job submission failed');
          return;
        }
        const generationResult = await this.pollServerForResults(client, workflowId as any, result.body.batchId);
        resolve({ input: payload, output: generationResult });
      } catch (error) {
        reject(error);
      }
    });
  }

  private constructInputPrompt(textPrompt?: string, effects?: string): string {
    if (textPrompt && effects) {
      return [effects, textPrompt].join(', ');
    } else if (textPrompt) {
      return textPrompt;
    } else if (effects) {
      return effects;
    } else return '';
  }

  private pollServerForResults(
    client: Genaihubbackend,
    workflowId: WorkflowId,
    batchId: string,
    entityId?: string,
  ): Promise<RetrieveResultByWorkflowIdAndBatchIdOutput> {
    const timeout = 60000;
    return new Promise<RetrieveResultByWorkflowIdAndBatchIdOutput>(async (resolve, reject) => {
      const startTime = Date.now();
      const interval = setInterval(async () => {
        try {
          const response = await client.retrieveResultByWorkflowIdAndBatchId({
            batchId: batchId,
            workflowId: workflowId,
            entityId: entityId,
            studioRequest: true,
          });

          if (!response.body?.jobs?.length) {
            clearInterval(interval);
            reject();
            return;
          }

          switch (response.body.jobs[0].status) {
            case 'RUNNING':
            case 'PENDING':
              if (Date.now() - startTime > timeout) {
                reject('timeout!');
              }
              break;
            case 'HALTED':
              clearInterval(interval);
              reject('job halted!');
              break;
            case 'COMPLETED':
              clearInterval(interval);
              resolve(response);
              break;
            case 'FAILED':
              clearInterval(interval);
              reject('job failed!');
              break;
          }
        } catch (error) {
          reject(error);
        }
      }, 5000);
    });
  }
}
