import { Inspirations, InspirationalContent } from '@amzn/genaihub-typescript-client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inspirations } from 'src/config';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import styled from 'styled-components';
import ScrollableAssetGallery from '../common/assetGallery/ScrollableAssetGallery';
import { getWorkflowNameById } from '../utils/getWorkflowNameById';

export const Container = styled.div`
  padding: 0 var(--div-spacing-default);
`;

export const Header = styled.div`
  width: 100%;
  min-height: 59px;
  margin-top: 20px;
  margin-bottom: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: 'Ember Modern Display Standard';
  font-size: 29px;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: left;

  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-family: Amazon Ember Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const InspirationsGallery = () => {
  const genAIBackendClient = useAIBackendHubClient();
  const [inspirationsList, setInspirationsList] = useState<Inspirations>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string>();
  const [totalItemCount, setTotalCount] = useState<number>();
  const linkTo = useNavigate();

  const assetClickHandler = (asset: InspirationalContent) => {
    linkTo('/studio' + (asset.textPrompt ? '?prompt=' + asset.textPrompt + '&generate=true' : ''));
  };

  const getInspirations = async () => {
    if (!totalItemCount || totalItemCount > inspirationsList.length) {
      setLoading(true);
      try {
        const startKey = lastEvaluatedKey;
        console.log('getInspirations', startKey);
        const {
          body: { count, inspirations, lastEvaluatedKey: newKey },
        } = await genAIBackendClient.retrieveInspirationsList({
          startKey,
        });

        setLoading(false);
        setInspirationsList([...inspirationsList, ...(inspirations ?? [])]);
        setLastEvaluatedKey(newKey);

        if (!startKey) {
          setTotalCount(count ?? 0);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      console.log('no more');
    }
  };

  const filterInspirations = (searchValue: string): Inspirations => {
    const value = searchValue.toLowerCase();
    return inspirationsList.filter((item: InspirationalContent) => {
      return (
        item.textPrompt?.toLowerCase().includes(value) ||
        item.type?.toLowerCase().includes(value) ||
        getWorkflowNameById(item.workflowId)?.toLowerCase().includes(value)
      );
    });
  };

  return (
    <Container>
      <Header>
        <Title>{inspirations.title}</Title>
        <SubTitle>{inspirations.subTitle}</SubTitle>
      </Header>
      <ScrollableAssetGallery<InspirationalContent>
        loading={loading}
        content={inspirationsList}
        fetchContent={getInspirations}
        filterContent={filterInspirations}
        clickCallback={assetClickHandler}
      />
    </Container>
  );
};

export default InspirationsGallery;
