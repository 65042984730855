import { Icon, IconProps, TextButton } from '@amzn/storm-ui';
import { solidThumbsDown, solidThumbsUp, thumbsDown, thumbsUp } from '@amzn/storm-ui-icons';
import { useContext, useRef } from 'react';
import { FeedbackAlert } from './FeedbackAlert';
import { FEEDBACK_CONTROL_CLASSNAME } from './FeedbackConstants';
import { FeedbackContext, FeedbackSentiment } from './FeedbackContext';
import styles from './FeedbackThumbs.module.scss';

const FeedbackThumb = (props: {
  associatedFeedbackSentiment: FeedbackSentiment;
  activeIconType: IconProps['type'];
  inactiveIconType: IconProps['type'];
  onClick: (feedbackSentiment: FeedbackSentiment) => void;
}) => {
  const feedbackContext = useContext(FeedbackContext);
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <FeedbackAlert
      associatedFeedbackSentiment={props.associatedFeedbackSentiment}
      triggerRef={triggerRef}
      trigger={
        <TextButton
          ref={triggerRef}
          className={`${styles.thumb} ${FEEDBACK_CONTROL_CLASSNAME}`}
          onClick={() => props.onClick(props.associatedFeedbackSentiment)}
          disabled={feedbackContext.feedbackSentiment === props.associatedFeedbackSentiment}
        >
          <Icon type={feedbackContext.feedbackSentiment === props.associatedFeedbackSentiment ? props.activeIconType : props.inactiveIconType} />
        </TextButton>
      }
    />
  );
};

export const FeedbackThumbsControl = () => {
  const feedbackContext = useContext(FeedbackContext);

  const onClickThumb = (feedbackSentiment: FeedbackSentiment) => {
    if (feedbackContext.feedbackSentiment !== feedbackSentiment) {
      feedbackContext.setFeedbackSentiment(feedbackSentiment);
      feedbackContext.setIsFeedbackPopoverOpen(true);
    }
  };

  return (
    <div className={styles.feedbackThumbsControl}>
      <FeedbackThumb
        associatedFeedbackSentiment={FeedbackSentiment.LIKE}
        activeIconType={solidThumbsUp}
        inactiveIconType={thumbsUp}
        onClick={onClickThumb}
      />
      <FeedbackThumb
        associatedFeedbackSentiment={FeedbackSentiment.DISLIKE}
        activeIconType={solidThumbsDown}
        inactiveIconType={thumbsDown}
        onClick={onClickThumb}
      />
    </div>
  );
};
