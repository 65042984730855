import theme58 from '../../images/themes/abstract.png';
import theme37 from '../../images/themes/autumn-kitchen.jpg';
import theme15 from '../../images/themes/autumn.jpeg';
import theme23 from '../../images/themes/backyard.jpg';
import theme24 from '../../images/themes/beach.png';
import theme45 from '../../images/themes/boho.jpg';
import theme53 from '../../images/themes/botanical-arrangement.jpg';
import theme1 from '../../images/themes/botanical-dark.jpg';
import theme2 from '../../images/themes/botanical-light.jpg';
import theme19 from '../../images/themes/christmas-illustration.jpg';
import theme33 from '../../images/themes/city.png';
import theme9 from '../../images/themes/confetti.png';
import theme25 from '../../images/themes/cottage.jpg';
import theme34 from '../../images/themes/countryside.jpeg';
import theme55 from '../../images/themes/deep-forest.jpg';
import theme51 from '../../images/themes/easter-collage.jpeg';
import theme50 from '../../images/themes/easter-nature.jpeg';
import theme57 from '../../images/themes/elegant-floral-drapery.jpg';
import theme46 from '../../images/themes/elegant.jpg';
import theme10 from '../../images/themes/floral-cool.jpg';
import theme11 from '../../images/themes/floral-warm.jpg';
import theme12 from '../../images/themes/floral-wildflowers.jpg';
import theme35 from '../../images/themes/forest.png';
import theme36 from '../../images/themes/garden.jpg';
import theme13 from '../../images/themes/gifting.png';
import theme56 from '../../images/themes/gifts-party-decor.jpg';
import theme16 from '../../images/themes/golden-christmas.png';
import theme42 from '../../images/themes/industrail.jpg';
import theme26 from '../../images/themes/kitchen-contemporary.png';
import theme28 from '../../images/themes/kitchen-mid-century-modern.jpg';
import theme29 from '../../images/themes/kitchen-modern.jpeg';
import theme27 from '../../images/themes/kitchen.jpg';
import theme30 from '../../images/themes/living-room-boho.jpg';
import theme31 from '../../images/themes/living-room-modern.jpg';
import theme3 from '../../images/themes/metallic.jpg';
import theme43 from '../../images/themes/minimalist.jpg';
import theme38 from '../../images/themes/mountain.jpg';
import theme4 from '../../images/themes/nature.jpg';
import theme5 from '../../images/themes/neon.jpg';
import theme32 from '../../images/themes/office-home.jpg';
import theme6 from '../../images/themes/pumpkin-spice.png';
import theme39 from '../../images/themes/rainforest-rock.jpg';
import theme17 from '../../images/themes/red-christmas.png';
import theme54 from '../../images/themes/rocky-landscape.jpg';
import theme7 from '../../images/themes/rocky.jpg';
import theme44 from '../../images/themes/romantic.jpg';
import theme47 from '../../images/themes/rustic.jpeg';
import theme48 from '../../images/themes/sci-fi.jpg';
import theme40 from '../../images/themes/skate-park.png';
import theme20 from '../../images/themes/spring.jpg';
import theme52 from '../../images/themes/summer.jpg';
import theme14 from '../../images/themes/tropical.jpg';
import theme21 from '../../images/themes/valentines-day.jpg';
import theme8 from '../../images/themes/water-splash.jpeg';
import theme18 from '../../images/themes/white-christmas.png';
import theme41 from '../../images/themes/winter-holiday-outdoor.jpg';
import theme22 from '../../images/themes/winter.jpg';
import theme49 from '../../images/themes/zen.jpg';

export default {
  theme1,
  theme2,
  theme3,
  theme4,
  theme5,
  theme6,
  theme7,
  theme8,
  theme9,
  theme10,
  theme11,
  theme12,
  theme13,
  theme14,
  theme15,
  theme16,
  theme17,
  theme18,
  theme19,
  theme20,
  theme21,
  theme22,
  theme23,
  theme24,
  theme25,
  theme26,
  theme27,
  theme28,
  theme29,
  theme30,
  theme31,
  theme32,
  theme33,
  theme34,
  theme35,
  theme36,
  theme37,
  theme38,
  theme39,
  theme40,
  theme41,
  theme42,
  theme43,
  theme44,
  theme45,
  theme46,
  theme47,
  theme48,
  theme49,
  theme50,
  theme51,
  theme52,
  theme53,
  theme54,
  theme55,
  theme56,
  theme57,
  theme58,
};
