import { Icon } from '@amzn/storm-ui';
import { check, copy } from '@amzn/storm-ui-icons';
import React, { useState } from 'react';
import styles from './CopyToClipboard.module.scss';

type Props = {
  textCopy: string | undefined;
  children: JSX.Element | JSX.Element[];
  invisibleMode?: boolean;
};
let clickTimeout: NodeJS.Timeout;

/*
This component copies the text passed into it (textCopy)
to the clipboard when anywhere within its children boundary thats clicked on.

Note: This component should be used around the nearest child possible and
children elements should not set hard widths in CSS as it may interfere with the overlay.
Set any required hard widths styling on other wrapper elements outside CopyToClipboard if needed.
*/

export const CopyToClipboard = ({ textCopy, children, invisibleMode }: Props): JSX.Element => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const handleClickConfirmation = () => {
    if (!clicked) {
      setClicked(true);
      clickTimeout = setTimeout(() => setClicked(false), 1500);
    }
  };

  const handleShowOverlay = () => {
    setShowOverlay(true);
  };

  const handleHideOverlay = () => {
    setShowOverlay(false);
    setClicked(false);
    clearTimeout(clickTimeout);
  };

  const handleCopyToClipboardClick = () => {
    if (textCopy && textCopy.length > 0) {
      navigator.clipboard.writeText(textCopy);
      handleClickConfirmation();
    }
  };

  // handles empty string as well
  if (!textCopy) {
    return <>{children}</>;
  }

  // allows copying to clipboard without visual overlay
  if (invisibleMode) {
    return (
      <div data-testid="CopyToClipboard-wrapper-inv" className={styles.copyToClipboardInvisible} onClick={handleCopyToClipboardClick}>
        {children}
      </div>
    );
  }

  return (
    <div
      data-testid="CopyToClipboard-wrapper"
      className={styles.copyToClipboardWrapper}
      onMouseEnter={handleShowOverlay}
      onMouseLeave={handleHideOverlay}
      onClick={handleCopyToClipboardClick}
    >
      {showOverlay && (
        <React.Fragment>
          <div className={styles.copyToClipboardOverlay}>
            <Icon size={'lg'} type={clicked ? check : copy} />
            <span className={styles.copyToClipboardOverlayText}>{clicked ? 'Copied!' : 'Copy to clipboard'}</span>
          </div>
        </React.Fragment>
      )}
      <div className={styles.copyToClipboardChildren}>{children}</div>
    </div>
  );
};
