import Genaihubbackend, { BatchResultJobsItemStatus, RetrieveResultByWorkflowIdAndBatchIdOutput, WorkflowId } from '@amzn/genaihub-typescript-client';
import { Publisher } from '@amzn/katal-metrics';
import { FAILURE_MESSAGE } from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import delay from 'src/components/utils/delay';
import { getErrorMessage } from 'src/components/utils/getErrorMessage';
import { Metrics } from 'src/constants';
import { CounterMetrics, MetricsProvider, StringMetrics } from 'src/metrics';

const RETRY_MAX = 2;

export type WorkflowOption = Record<string, { value: string }>;

export type WorkflowSummery = {
  status: BatchResultJobsItemStatus | 'IDLE';
  message?: string;
  type?: string;
  urls?: string[];
  rawJobResponse?: RetrieveResultByWorkflowIdAndBatchIdOutput;
};

export class WorkflowSubmission {
  static readonly RUNNING = 'RUNNING';
  static readonly FAILED = 'FAILED';
  static readonly COMPLETED = 'COMPLETED';
  static readonly IDLE = 'IDLE';

  private workflowId: string;
  private workflowOptions?: WorkflowOption;
  private batchId?: string;
  private status: BatchResultJobsItemStatus | 'IDLE';
  private message?: string;
  private type?: string;
  private urls?: string[];
  private rawJobResponse?: RetrieveResultByWorkflowIdAndBatchIdOutput;

  private metrics: MetricsProvider | undefined;
  private publisher: Publisher | undefined;
  private interval?: ReturnType<typeof setInterval>;
  private genAIBackendClient: Genaihubbackend;
  private submissionTime: number;
  private timeout: number;
  private computedWorkflowTime: number;
  private inProgress: boolean = false;
  private isVerbose: boolean = false;
  private retryCount: number = 0;

  constructor(workflowId: string, client: Genaihubbackend) {
    this.workflowId = workflowId;
    this.status = WorkflowSubmission.IDLE;
    this.timeout = 120000;
    this.submissionTime = 0;
    this.computedWorkflowTime = 0;
    this.genAIBackendClient = client;
  }

  withClient(client: Genaihubbackend) {
    this.genAIBackendClient = client;
    return this;
  }

  withMetrics(metrics: MetricsProvider) {
    this.metrics = metrics;
    return this;
  }

  verbose() {
    this.isVerbose = true;
    return this;
  }

  private trackMetrics(strings?: StringMetrics, counters?: CounterMetrics) {
    if (this.metrics) {
      if (!this.publisher) {
        this.publisher = this.metrics.trackMetrics(Metrics.Methods.WorkflowMetrics, strings, counters);
      } else {
        this.metrics.trackMetricsWithPublisher(this.publisher, strings, counters);
      }
    }
    return this;
  }

  async submitJob(workflowOptions: WorkflowOption, timeout?: number): Promise<WorkflowSubmission> {
    this.submissionTime = Date.now();
    this.workflowOptions = workflowOptions;
    this.inProgress = true;
    this.retryCount = 0;
    if (timeout) {
      this.timeout = timeout;
    }

    const workflowSubmition: { [key: string]: string } = {};
    for (const key in workflowOptions) {
      workflowSubmition[key] = workflowOptions[key].value ?? '';
    }
    console.log(workflowSubmition);

    this.trackMetrics(
      {
        ...(this.isVerbose ? workflowSubmition : {}),
        [Metrics.Names.WorkflowId]: this.workflowId ?? Metrics.Values.Unknown,
      },
      { [Metrics.Counters.Count]: 1 },
    );

    try {
      const response = await this.genAIBackendClient.submitWorkflowById({
        workflowId: this.workflowId as WorkflowId,
        body: {
          workflowOptions: workflowSubmition,
        },
      });
      const workflowResult = await response.body;
      console.log(workflowResult);

      if (workflowResult.batchId) {
        this.batchId = workflowResult.batchId;
        if (this.isVerbose) {
          this.trackMetrics({ [Metrics.Names.BatchId]: workflowResult.batchId });
        }
      } else {
        throw new Error('No batchId found');
      }
    } catch (error) {
      console.log(error);
      this.stopJob();
      this.status = WorkflowSubmission.FAILED;
      this.message = await getErrorMessage(error);
      this.trackMetrics({ [Metrics.Names.Error]: this.message }, { [Metrics.Counters.Failure]: 1 });
      throw error;
    }
    return this;
  }

  getJobStatus(pollingInterval: number, callback: (summery: WorkflowSummery) => void) {
    // If there is no batchid and not in progress there's no job
    if (this.batchId && this.inProgress) {
      this.interval = setInterval(async () => {
        try {
          // If the batchId was messed with then throw an error
          if (!this.batchId) {
            throw new Error('No batchId found');
          }

          const response = await this.genAIBackendClient.retrieveResultByWorkflowIdAndBatchId({
            batchId: this.batchId,
            workflowId: this.workflowId as WorkflowId,
          });

          // Check for jobs
          if (response?.body?.jobs?.length && response.body.jobs[0].status) {
            console.log('polling response', response);
            const workflowTime = this.computeWorkflowTime();
            this.status = response.body.jobs[0].status;
            this.type = response.body.type;
            this.urls = response.body.jobs[0].urls;
            this.retryCount = 0; // If no errors then reset retry count
            this.message = response.body.jobs[0].message;
            console.log('workflowTime', workflowTime);

            // Check to see if the job has timed out first
            if (workflowTime > this.timeout && this.status !== WorkflowSubmission.COMPLETED) {
              console.log('job submition timeout reached');
              this.stopJob();
              this.status = WorkflowSubmission.FAILED;
              this.message = FAILURE_MESSAGE.TIME_OUT;
              this.trackMetrics({}, { [Metrics.Names.Time]: workflowTime, [Metrics.Counters.Timeout]: 1 });
            } else if (this.status === WorkflowSubmission.FAILED) {
              console.log('job submition failed');
              throw new Error(this.message);
            } else if (this.status === WorkflowSubmission.COMPLETED) {
              console.log('job submition completed');
              this.stopJob();
              this.message = 'Job submition complete';
              this.rawJobResponse = response;
              this.trackMetrics(
                {},
                {
                  ...(this.isVerbose ? { GeneratedImageCount: this.urls?.length } : {}),
                  [Metrics.Names.Time]: workflowTime,
                  [Metrics.Counters.Success]: 1,
                },
              );
            }
          }
        } catch (error) {
          console.log(error);

          // Check to see if the error was caused by a failure from a job
          if (this.status !== WorkflowSubmission.FAILED && this.retryCount < RETRY_MAX) {
            // If it wasn't a job failure then retry
            console.log('Workflow polling retry');
            this.status = WorkflowSubmission.RUNNING;
            this.message = 'Retrying';

            await delay(3000);

            this.retryCount++;
          } else {
            // Otherwise the workflow has failed so stop it
            this.stopJob();
            this.status = WorkflowSubmission.FAILED;
            this.message = await getErrorMessage(error);
            this.trackMetrics(
              { [Metrics.Names.Error]: this.message },
              { [Metrics.Names.Time]: this.computeWorkflowTime(), [Metrics.Counters.Failure]: 1 },
            );
          }
        }

        callback?.({
          status: this.status,
          message: this.message,
          type: this.type,
          urls: this.urls,
          rawJobResponse: this.rawJobResponse,
        });
      }, pollingInterval);
    }
    return this;
  }

  get jobInProgress() {
    return this.inProgress;
  }

  private computeWorkflowTime() {
    this.computedWorkflowTime = window.Date.now() - this.submissionTime;
    return this.computedWorkflowTime;
  }

  get workflowTime() {
    return this.computedWorkflowTime;
  }

  abandon() {
    if (this.inProgress) {
      console.log('Workflow abandonded', this.inProgress);
      this.stopJob();
      this.status = WorkflowSubmission.FAILED;
      this.message = 'Workflow abandoned';
      this.trackMetrics({ [Metrics.Names.Error]: Metrics.Values.Abandoned }, { [Metrics.Values.Abandoned]: 1 });
      return true;
    }
    return false;
  }

  private stopJob() {
    clearInterval(this.interval);
    this.inProgress = false;
  }
}
