import { Card, Text } from '@amzn/storm-ui';
import React, { useRef } from 'react';
import componentStyles from './PlaygroundCard.module.scss';
import * as styles from './playgroundStyles';

const PlaygroundCard = (props: any) => {
  const { title, thumbnail, description, callback, cardStyle, videoPreview, ...rest } = props;
  const disabled = description.toLowerCase().indexOf('coming soon') !== -1;
  const videoRef = useRef<HTMLVideoElement>(null);
  const cardDescription = description.split('|')[0];

  const handleMouseEnter = (event: React.MouseEvent) => {
    const el = event.currentTarget as HTMLButtonElement;
    console.log('handleMouseEnter', el, disabled);

    // if (el && disabled) el.style.opacity = '0.2';
  };
  const handleMouseLeave = (event: React.MouseEvent) => {
    const el = event.currentTarget as HTMLButtonElement;
    // if (el && disabled) el.style.opacity = '1';
  };

  const handleVideoMouseEnter = () => {
    console.log('video entered');
    videoRef.current?.play();
  };

  const handleVideoMouseLeave = () => {
    videoRef.current?.pause();
    videoRef.current!.currentTime = 0;
  };

  return (
    <Card
      {...rest}
      onClick={!disabled ? callback : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      padding={'none'}
      className={componentStyles.card}
      style={{ ...styles.card, ...cardStyle, position: 'relative' }}
    >
      {videoPreview !== '' ? (
        <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '20px', overflow: 'hidden' }}>
          <video
            poster={thumbnail}
            onPause={(event) => event.currentTarget.load()}
            ref={videoRef}
            muted
            loop
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              height: '100%',
              width: '100%',
              transform: 'translate(-50%, -50%)',
              objectFit: 'cover',
            }}
            onMouseEnter={handleVideoMouseEnter}
            onMouseLeave={handleVideoMouseLeave}
          >
            <source src={videoPreview} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          <img src={thumbnail} style={{ height: '100%', width: '100%', display: 'block', borderRadius: '12px', objectFit: 'cover' }} />
        </div>
      )}

      <div className={componentStyles.cardTextBlockWrapper}>
        <div style={styles.cardTitleContainer}>
          <Text style={styles.cardTitle}>{title}</Text>
        </div>
        <div style={styles.cardDescription}>{cardDescription}</div>
      </div>
    </Card>
  );
};

export default PlaygroundCard;
