import './spinnerIcon.css';

export default function ({ style = {}, strokeWidth = 25 }: { style?: any; strokeWidth?: number }) {
  return (
    <div style={style}>
      <svg id="erVhFUTiGnu1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <g transform="matrix(1.8 0 0 1.8 250 250)" opacity="0.15">
          <path
            d="M-6.68,-86.582c1.979-6.017,10.055-6.418,12.847-1.203c0,0,.513,1.203.513,1.203s3.132,9.523,3.132,9.523c10.047,30.549,33.452,54.773,63.462,65.922c0,0,3.785,1.325,3.785,1.325s9.524,3.132,9.524,3.132c6.016,1.979,6.416,10.055,1.203,12.847c0,0-1.203.513-1.203.513s-9.524,3.132-9.524,3.132C46.51,19.859,22.285,43.263,11.137,73.273c0,0-1.325,3.786-1.325,3.786s-3.132,9.523-3.132,9.523C4.701,92.599,-3.375,93,-6.167,87.785c0,0-.513-1.203-.513-1.203s-3.132-9.523-3.132-9.523c-10.047-30.549-33.451-54.774-63.461-65.922c0,0-3.786-1.325-3.786-1.325s-9.523-3.132-9.523-3.132c-6.017-1.979-6.418-10.055-1.203-12.847c0,0,1.203-.513,1.203-.513s9.523-3.132,9.523-3.132c30.549-10.047,54.774-33.451,65.922-63.462c0,0,1.325-3.785,1.325-3.785s3.132-9.523,3.132-9.523Z"
            paintOrder="stroke fill markers"
            fill="none"
            fillRule="evenodd"
            stroke="#354152"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </g>
        <g transform="matrix(1.8 0 0 1.8 250 250)" paintOrder="stroke fill markers">
          <path
            id="erVhFUTiGnu5"
            d="M-6.68,-86.582c1.979-6.017,10.055-6.418,12.847-1.203c0,0,.513,1.203.513,1.203s3.132,9.523,3.132,9.523c10.047,30.549,33.452,54.773,63.462,65.922c0,0,3.785,1.325,3.785,1.325s9.524,3.132,9.524,3.132c6.016,1.979,6.416,10.055,1.203,12.847c0,0-1.203.513-1.203.513s-9.524,3.132-9.524,3.132C46.51,19.859,22.285,43.263,11.137,73.273c0,0-1.325,3.786-1.325,3.786s-3.132,9.523-3.132,9.523C4.701,92.599,-3.375,93,-6.167,87.785c0,0-.513-1.203-.513-1.203s-3.132-9.523-3.132-9.523c-10.047-30.549-33.451-54.774-63.461-65.922c0,0-3.786-1.325-3.786-1.325s-9.523-3.132-9.523-3.132c-6.017-1.979-6.418-10.055-1.203-12.847c0,0,1.203-.513,1.203-.513s9.523-3.132,9.523-3.132c30.549-10.047,54.774-33.451,65.922-63.462c0,0,1.325-3.785,1.325-3.785s3.132-9.523,3.132-9.523Z"
            paintOrder="stroke fill markers"
            fill="none"
            fillRule="evenodd"
            stroke="rgb(130, 144, 164)"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
            strokeDashoffset="-15.223632"
            strokeDasharray="0,0.09,0.12,545.28"
          />
        </g>
      </svg>
    </div>
  );
}
