import { AdvertisingAccountMetadata, QueryAdvertisingAccountsResponse } from '@amzn/genaihub-typescript-client';
import { Text, TextButton, Icon, Modal } from '@amzn/storm-ui';
import { checkCircle } from '@amzn/storm-ui-icons';
import React, { useEffect, useState, useContext } from 'react';
import Spinner from 'src/components/common/storm/Spinner';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import style from './AccountList.module.scss';
import { AppContext } from '../../../../AppContext';

const AccountList = () => {
  const context = useContext(AppContext);
  const genAIBackendClient = useAIBackendHubClient();

  const [advertisingAccounts, setAdvertisingAccounts] = useState<QueryAdvertisingAccountsResponse>();
  const [waitingForNetworkResponse, setWaitingForNetworkResponse] = useState<boolean>(false);
  const [textMessage, setTextMessage] = useState<{ show: boolean; message: string }>();
  const [isManagerAccount, setIsManagerAccount] = useState<boolean>(false);

  useEffect(() => {
    if (context.authenticated) {
      getAdvertisingAccounts();
    }
  }, [context.authenticated]);

  const getAdvertisingAccounts = async (nextToken?: string) => {
    setWaitingForNetworkResponse(true);
    try {
      const results = await genAIBackendClient.queryAdvertisingAccounts({
        body: {
          nextToken: nextToken,
          maxResults: 20,
        },
      });

      if (!results.body?.advertisingAccounts || (results.body?.advertisingAccounts?.length === 0 && context.accountType === 'external')) {
        setIsManagerAccount(true);
        setTextMessage({
          show: true,
          message:
            "There aren't any advertiser accounts associated with this login. You can still use AI creative studio, but you won't be able to save anything to your creative assets.",
        });
      } else {
        setAdvertisingAccounts({
          ...results.body,
          advertisingAccounts: [...(advertisingAccounts?.advertisingAccounts || []), ...(results.body?.advertisingAccounts || [])],
          nextToken: results.body?.nextToken,
        });
      }
    } catch (error) {
      setTextMessage({ show: true, message: 'Something went wrong while getting the accounts' });
    }

    setWaitingForNetworkResponse(false);
  };

  const closeMenu = () => {
    context.setShowSwitchAccountModal(false);
  };

  const chooseAccountClickHandler = (selectedAdvertisingAccount?: AdvertisingAccountMetadata) => {
    if (!selectedAdvertisingAccount) {
      return;
    }

    context.setSelectedAdvertisingAccount(selectedAdvertisingAccount);
    context.setShowSwitchAccountModal(false);
  };

  const onScrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollableElement = event.target as HTMLDivElement;
    if (scrollableElement.scrollHeight - (scrollableElement.scrollTop + scrollableElement.offsetHeight) < 100 && advertisingAccounts?.nextToken) {
      getAdvertisingAccounts(advertisingAccounts?.nextToken);
    }
  };

  return (
    <>
      <div className={style.accountList}>
        <Modal
          isOpen={context.showSwitchAccountModal}
          header="Choose an account"
          onClose={closeMenu}
          modalElementId="choose-account-modal"
          hasCloseButton={!!context.selectedAdvertisingAccount || isManagerAccount}
          onScrollCapture={onScrollHandler}
        >
          <div id="aaaa" className={style.modalContent}>
            <Stack style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '12px' }}>
              <Text type={'h3'}>All accounts</Text>
              {advertisingAccounts?.advertisingAccounts &&
                advertisingAccounts.advertisingAccounts.map((advertisingAccount, index: number) => (
                  <div className={style.listItem} key={index}>
                    <TextButton onClick={() => chooseAccountClickHandler(advertisingAccount)} className={style.text}>
                      {advertisingAccount.accountName}
                    </TextButton>
                    {advertisingAccount.adsAccountId == context.selectedAdvertisingAccount?.adsAccountId && (
                      <Icon className={style.listItemIcon} type={checkCircle} />
                    )}
                  </div>
                ))}
            </Stack>
            {waitingForNetworkResponse && (
              <div className={style.progressIndicator}>
                <Spinner />
              </div>
            )}
            {textMessage?.show && (
              <div className={style.textMessage}>
                <Text>{textMessage.message}</Text>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AccountList;
