import { SubmitWorkflowByIdOutput, Type, WorkflowId } from '@amzn/genaihub-typescript-client';
import { useContext, useEffect, useState, useRef } from 'react';
import StudioControlPanel from 'src/components/editor/studio/StudioControlPanel';
import ControlPanel from 'src/components/editor/UiContols/ControlPanel';
import { SnackbarContextProvider } from 'src/components/snackbar/SnackbarContext';
import { SnackbarManager } from 'src/components/snackbar/SnackbarManager';
import { STUDIO } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import styled from 'styled-components';
import style from './editorp1.module.scss';
import WorkflowResults from './WorkflowResults';
import { AppContext, EditorContextP1 as EditorContext, EditorContextStateP1 } from '../../../AppContext';
import UsePrompt from './UiContols/uiGeneratorControls/storm/UsePrompt';

const LeftPanel = styled.div.attrs({ className: style.leftPanel })``;

const RightPanel = styled.div.attrs({ className: style.rightPanel })``;

const EditorP1 = (props: { module: any; type: string }) => {
  const module = props.module || '';
  const type = props.type || '';

  const genAIBackendClient = useAIBackendHubClient();
  const appContext = useContext(AppContext);
  const editorContext = useContext(EditorContext);

  const { metrics, selectedTool } = appContext;

  const [controls, setControls] = useState<any>([]);
  const [workflows, setWorkflows] = useState<any>([]);
  const [guide, setGuide] = useState<any>([]);
  const [activateGuide, setActivateGuide] = useState<boolean>(false);

  const [workflowOptions, setWorkFlowOptions] = useState<any>({});
  const [asinMetaData, setAsinMetaData] = useState<any>({});
  const [generatedResults, setGeneratedResults] = useState<any>({});
  const [generatedTextResults, setGeneratedTextResults] = useState<any>({});
  const [fileUploadEvent, setFileUploadEvent] = useState<FileUploadEvent>();
  const [disableControl, setDisableControl] = useState<any>(null);
  const [promptUpdateEvent, setPromptUpdateEvent] = useState<any>();
  const [rawJobResponse, setRawJobResponse] = useState<SubmitWorkflowByIdOutput>();
  const rightPanelRef = useRef<HTMLDivElement>(null);

  const handleResize = (event: any) => {
    const totalHeight = getPageHeight();
    const container = document.getElementById('wrapper');
    const verticalScroll = window.scrollY || document.documentElement.scrollTop;
    if (container) container.style.height = `${totalHeight}px`;

    if (rightPanelRef.current) {
      if (verticalScroll >= 85) {
        let pageWidth = window.innerWidth >= 2008 ? '2008px' : '100%';
        rightPanelRef.current.style.position = 'fixed';
        rightPanelRef.current.style.top = '85px';
        rightPanelRef.current.style.width = `calc(${pageWidth} - 474px)`;
        rightPanelRef.current.style.height = window.innerHeight - 105 + 'px';
      } else {
        rightPanelRef.current.style.position = 'relative';
        rightPanelRef.current.style.top = '0';
        rightPanelRef.current.style.width = 'auto';
        rightPanelRef.current.style.height = window.innerHeight - 190 + verticalScroll + 'px';
      }
    }
  };

  const getPageHeight = () => {
    const pageHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );

    return pageHeight;
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
      const container = document.getElementById('wrapper');
      if (container) container.style.height = 'auto';
    };
  }, []);

  const studioResults = {
    status: 'COMPLETED',
    urls: [
      'https://d14v4zfmgqdkz1.cloudfront.net/welcome_images/welcome_img31.jpg',
      'https://d14v4zfmgqdkz1.cloudfront.net/welcome_images/welcome_img20.jpg',
      'https://d14v4zfmgqdkz1.cloudfront.net/welcome_images/welcome_img07.jpg',
    ],
    type: 'IMAGE' as Type,
    message: 'DUMMY DATA',
  };

  const editorContextState: EditorContextStateP1 = {
    asinMetaData,
    setAsinMetaData,
    workflowOptions,
    setWorkFlowOptions,
    fileUploadEvent,
    setFileUploadEvent,
    disableControl,
    setDisableControl,
    promptUpdateEvent,
    setPromptUpdateEvent,
    activateGuide,
    setActivateGuide,
    rawJobResponse,
    setRawJobResponse,
  };

  const bulkUpdateCallback = (newState: any) => {
    setWorkFlowOptions({ ...workflowOptions, ...newState });
  };

  const appendGeneratedResults = (results: any) => {
    setGeneratedResults(results); //setGeneratedResults({ ...generatedResults, ...results });
  };

  const appendGeneratedTextResults = (results: any) => {
    setGeneratedTextResults({ ...generatedTextResults, ...results });
  };

  const getWorkflows = async (selectedTool: string) => {
    try {
      const response = await genAIBackendClient.retrieveWorkflowsList({
        body: { workflowType: (type === STUDIO ? STUDIO : selectedTool) as WorkflowId },
      });
      const result: any = response.body;

      if (type === STUDIO) {
        if (result.data) setWorkflows(result.data);
      } else {
        if (result.data[0]?.controls) {
          const defaults: any = {};
          const controls = result.data[0]?.controls;
          controls
            .filter((control: any) => control.defaultValue !== undefined)
            .map((control: any) => {
              defaults[control.controlName] = { value: control.defaultValue };
            });
          setControls(controls);
        }

        if (result.data[0]?.guide.length) {
          setGuide(result.data[0]?.guide);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (!selectedTool) {
      const storedTool = localStorage.getItem('selectedTool');
      if (storedTool) {
        appContext.setSelectedTool(storedTool); // make sure we remember what it is
      }
    } else {
      getWorkflows(selectedTool);
    }
  }, [selectedTool]);

  const workFlowResultsMetaData: any = {
    LIFESTYLE_IMAGERY: { children: [UsePrompt] },
  };

  return (
    <EditorContext.Provider value={editorContextState}>
      <SnackbarContextProvider>
        {(controls.length > 0 || workflows.length > 0) && (
          <Stack
            className={style.flexContainer}
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              flexWrap: 'nowrap',
              alignItems: 'flex-start',
              position: 'relative',
            }}
          >
            <LeftPanel>
              {type === STUDIO ? (
                <StudioControlPanel workflows={workflows} type={type} />
              ) : (
                <ControlPanel
                  controls={controls}
                  editorContext={EditorContext}
                  setGeneratedResults={setGeneratedResults}
                  appendGeneratedResults={appendGeneratedResults}
                  appendGeneratedTextResults={appendGeneratedTextResults}
                  bulkUpdateCallback={bulkUpdateCallback}
                  type={type}
                />
              )}
            </LeftPanel>

            <div style={{ width: '100%' }}>
              <RightPanel ref={rightPanelRef}>
                <WorkflowResults result={generatedResults} guide={guide} textResults={generatedTextResults}>
                  {selectedTool &&
                    workFlowResultsMetaData[selectedTool] &&
                    workFlowResultsMetaData[selectedTool].children.map((Child: any, index: number) => (
                      <Child key={`editor${index}`} result={generatedResults} />
                    ))}
                </WorkflowResults>
              </RightPanel>
            </div>
          </Stack>
        )}
        <SnackbarManager></SnackbarManager>
      </SnackbarContextProvider>
    </EditorContext.Provider>
  );
};

export default EditorP1;
