import { Card, Section, Text } from '@amzn/storm-ui';
import { useContext, useState } from 'react';
import ButtonWithBorder from 'src/components/common/storm/ButtonWithBorder';
import Stack from 'src/customUIComponents/Stack';
import spacer from 'src/images/1x1.gif';
import { EditorContextP1 as EditorContext } from '../../../AppContext';

const WorkflowGuide = (props: any) => {
  const { guide } = props;
  const context = useContext(EditorContext);
  const title = 'How to use this module';
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleClickEvent = (event: any) => {
    context.setActivateGuide(true);
  };

  return (
    <>
      {guide?.length ? (
        <Stack style={{ flexDirection: 'column', justifyContent: 'space-evenly', height: '100%', flexWrap: 'nowrap' }}>
          <Text style={{ fontSize: '32px', fontWeight: '800', lineHeight: '34px', margin: '18px' }}>{title}</Text>
          <Section style={{ background: 'transparent', padding: '0px' }}>
            <Stack
              className="workflowGuideContainer"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '18px',
                marginLeft: '50px',
                marginRight: '50px',
                width: 'auto',
                alignSelf: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              {guide.map((item: any, index: number) => {
                return (
                  <Card
                    key={'guide' + index}
                    padding={'none'}
                    style={{
                      overflow: 'hidden',
                      minWidth: '260px',
                      maxWidth: '260px',
                      width: '260px',
                      height: '290px',
                      padding: '10px',
                      borderRadius: '20px',
                      flex: '1 0 30%',
                    }}
                  >
                    <div style={{ marginBottom: '15px' }}>
                      <img
                        src={item?.guideImage}
                        style={{ height: '171px', width: '240px', display: 'block', borderRadius: '20px', objectFit: 'cover' }}
                      />
                    </div>
                    <Text style={{ margin: '10px', textAlign: 'left' }}>{item.guideDescription}</Text>
                  </Card>
                );
              })}
            </Stack>
          </Section>

          <ButtonWithBorder
            data-testid="playground-see-all"
            disabled={disabled}
            style={{ marginTop: '18px', marginRight: '12px', minWidth: '92px' }}
            text="Try this example"
            clickHandler={handleClickEvent}
          />
          <div>
            <img src={spacer} style={{ height: '20px' }} />
          </div>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

export default WorkflowGuide;
