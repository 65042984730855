import { useContext, useEffect } from 'react';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import { EditorContextStateP1 } from '../../../../../../AppContext';

const RangeSlider = (props: any) => {
  const { control, bulkUpdateCallback } = props;
  const editorContext: EditorContextStateP1 = useContext(props.editorContext);
  const controlValues = editorContext.workflowOptions;
  const controlName = control.controlName;

  useEffect(() => {
    guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
  }, [editorContext.activateGuide]);

  useEffect(() => {
    // set default
    const controlName = control.controlName;
    if (!controlValues[controlName]) {
      const controlData: any = {};
      controlData[controlName] = { value: control.defaultValue };
      editorContext.setWorkFlowOptions((latestValues: any) => ({ ...latestValues, ...controlData }));
    }
  }, [control]);

  const guideCallback = (config: any) => {
    if (config) {
      console.log(controlValues, { [control.controlName]: { value: config.value } });
      batchStateUpdate({ [control.controlName]: { value: config.value } }, bulkUpdateCallback);
    }
  };

  const handleSliderValue = (event: any) => {
    console.log(controlName, event.target.value);
    const controlData: any = {};
    controlData[controlName] = { value: event.target.value };
    editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
  };

  return (
    <>
      <ControlLabel title={control.controlLabel} />
      <div>
        <input
          data-testid="rangeslider"
          type="range"
          min={Number(control.controlValues[0].value)}
          max={Number(control.controlValues[1].value)}
          //defaultValue={Number(control.defaultValue)}
          value={controlValues[controlName]?.value || Number(control.defaultValue)}
          step={Number(control.controlValues[2].value)}
          onChange={handleSliderValue}
          disabled={editorContext.disableControl}
          style={{
            accentColor: '#4305F4',
            width: '100%',
          }}
        ></input>
        <div style={{ width: '100%' }}>
          <span
            style={{
              fontFamily: 'Amazon Ember',
              fontSize: '13px',
              fontWeight: '500',
              color: '#5C687C',
            }}
          >
            {control.controlValues[0].label}
          </span>
          <span
            style={{
              float: 'right',
              fontFamily: 'Amazon Ember',
              fontSize: '13px',
              fontWeight: '500',
              color: '#5C687C',
            }}
          >
            {control.controlValues[1].label}
          </span>
        </div>
      </div>
    </>
  );
};

export default RangeSlider;
