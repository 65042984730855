import { Card, FileUploadDropZone, InlineMessage, Icon, Text } from '@amzn/storm-ui';
import { times } from '@amzn/storm-ui-icons';

import { useEffect, useContext, useState, useRef } from 'react';
import Spinner from 'src/components/common/storm/Spinner';
import { urlToFile } from 'src/components/utils/base64Encode';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import { uploadImage } from 'src/components/utils/uploadImage';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import ControlLabel from './UI/ControlLabel';
import { EditorContextStateP1 } from '../../../../../../AppContext';

const DropZone = (props: any) => {
  const editorContext: EditorContextStateP1 = useContext(props.editorContext);
  const { control, bulkUpdateCallback } = props;
  const [uploaded, setUploaded] = useState<string>('');
  const [alert, setAlert] = useState<any>([]);
  const genAIBackendClient = useAIBackendHubClient();
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (uploaded !== 'uploaded') {
      editorContext.setDisableControl(true);
    } else {
      editorContext.setDisableControl(false);
    }
  }, [uploaded]);

  useEffect(() => {
    guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
  }, [editorContext.activateGuide]);

  const guideCallback = async (guide: any) => {
    if (guide.guideCustomValue) {
      const fileUrl = new URL(guide.guideCustomValue);
      // add query param to avoid getting cached response
      fileUrl.searchParams.append('v', crypto.randomUUID());
      const file: any = await urlToFile(fileUrl.href);
      console.log('dropzone file upload called', file);
      prepareUpload(file);
    }
  };

  const handleOnChange = (e: any, files: any) => {
    const dropped = handleDroppedFiles(files);
    setUploaded('');
    if (dropped) {
      console.log('dropped');
      prepareUpload(files[0]);
    }
  };

  const handleDroppedFiles = (files: any) => {
    let alertsArray = [];

    if (files.length > 1) {
      alertsArray.push({ type: 'error', message: 'Only 1 file can be uploaded at a time' });
    } else {
      if (files[0].type.indexOf('image') === -1) alertsArray.push({ type: 'warning', message: 'Only image files can be uploaded' });
      // add more error conditions here if need be, and push them into the alerts array
    }
    setAlert(alertsArray);

    return !alertsArray.length;
  };

  const prepareUpload = async (file: File) => {
    try {
      if (file !== null) {
        setUploaded('uploading');
        const referenceId = await uploadImage(file, genAIBackendClient, 'PRODUCT_IMAGE');
        const controlName = control.controlName;
        const controlData = {
          [controlName]: { value: referenceId, file },
        };
        editorContext.activateGuide
          ? batchStateUpdate(controlData, bulkUpdateCallback)
          : editorContext.setWorkFlowOptions({ ...editorContext.workflowOptions, ...controlData });
        setUploaded('uploaded');

        editorContext.setFileUploadEvent({ payload: file, controlName });
        //editorContext.setFileUploadEvent({ payload: file });
      }
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const ShowPreview = () => {
    const file = editorContext.workflowOptions[control.controlName]?.file;

    if (file && file.name) {
      const name = file.name;
      const imageUrl = URL.createObjectURL(file);
      const size = Math.round((file.size / 1000000) * 10) / 10;

      return (
        <Card padding={10} style={{ background: '#f1f3f5', boxShadow: 'none', width: 'auto' }}>
          <Stack style={{ flexDirection: 'row', flexWrap: 'nowrap', gap: '10px' }}>
            <div style={{ flex: 1 }}>
              <Card
                padding={0}
                style={{ overflow: 'hidden', maxWidth: '150px', background: 'none', boxShadow: 'none', height: 'auto', width: 'auto' }}
              >
                <img src={imageUrl} alt={'IMAGE PREVIEW'} style={{ height: '100px', overflow: 'hidden', display: 'table-column' }} />
              </Card>
            </div>
            <div style={{ fontSize: 'small', textAlign: 'left', textOverflow: 'ellipsis', maxWidth: '160px', overflow: 'hidden', flex: 4 }}>
              {name}
              <br />
              {size} MB
            </div>
            <Icon
              type={times}
              style={{ textAlign: 'right', cursor: 'pointer', flex: 2 }}
              onClick={() => {
                const controlData = { ...editorContext.workflowOptions };
                delete controlData[control.controlName];
                editorContext.setWorkFlowOptions({ ...controlData });
                editorContext.setFileUploadEvent(undefined);
                setUploaded('');
                editorContext.setActivateGuide(false);
              }}
            />
          </Stack>
        </Card>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    const el: any = document.getElementById('drop-zone');
    el && el.setAttribute('style', 'width:100%');
  });
  const ShowLoading = () => (
    <>
      <div role="alert" aria-live="assertive" style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <Spinner size="lg" />
      </div>
    </>
  );
  const filename = editorContext.workflowOptions[control.controlName]?.file.name;
  console.log('upload', { uploaded }, filename);
  return (
    <>
      <ControlLabel title={control.controlLabel} defaultValue={control.defaultValue} />

      <Card padding={0} style={{ background: 'transparent', boxShadow: 'none', minHeight: '80px', maxWidth: '100%' }}>
        {uploaded === 'uploading' ? (
          <ShowLoading />
        ) : uploaded === 'uploaded' ? (
          ShowPreview()
        ) : (
          <>
            <FileUploadDropZone
              accept={['image/png', 'image/jpeg']}
              style={{ width: '100%' }}
              dropzoneId="drop-zone"
              inputId="file-uploader"
              heading={
                <Text type="h5" textColor="base">
                  Choose file or drag and drop
                </Text>
              }
              onChange={handleOnChange}
            />

            {alert.length > 0 &&
              alert.map((item: any, index: number) => <InlineMessage key={`alert${index}`} messageType={item.type} message={item.message} />)}
          </>
        )}
      </Card>
    </>
  );
};

export default DropZone;
