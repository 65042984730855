import { Button, Icon } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faCircleDot, faDownLong, faLeftLong, faLeftRight, faRightLong, faUpDown, faUpLong } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { Image, Layer, Rect, Stage } from 'react-konva';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import Stack from 'src/customUIComponents/Stack';
import useImage from 'use-image';
import { EditorContextP1 as EditorContext } from '../../../../../../AppContext';

const ImageExpansionCanvas = (props: { appendControlValues: Function; control: any }) => {
  const editorContext = useContext(EditorContext);
  const control = props.control;
  const [img, setImg] = useState<any>();
  const [image] = useImage(img);
  const [disabled, setDisabled] = useState<boolean>(true);
  const divRef = useRef<any>(null);
  const imgRef = useRef<any>(null);
  const [buttonCategory, setButtonCategory] = useState<string>('');
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 300, height: 200 });
  const [actualDimensions, setActualDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [blockDimensions, setBlockDimensions] = useState<{ width: number; height: number }>({ width: 300, height: 200 });
  const [imageCoordinates, setImageCoordinates] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [aspectRatio, setAspectRatio] = useState<number>(1);

  const leftUp = 'bottom/right';
  const rightDown = 'top/left';
  const bothSide = 'middle';
  const all = 'all';

  const [tool, setTool] = useState<string>(control.defaultValue);

  const clearCanvas = () => {
    console.log('Clearing Canvas');
  };

  useEffect(() => {
    console.log('file upload event', editorContext.fileUploadEvent);
    if (!editorContext.fileUploadEvent) {
      setImg(undefined);
      clearCanvas();
      return;
    }

    const img = document.createElement('img');
    const imageObjUrl = URL.createObjectURL(editorContext.fileUploadEvent.payload);
    img.onload = (event: any) => {
      setActualDimensions({ width: event.target.width, height: event.target.height });
      setImg(imageObjUrl);
      // setImageCoordinates({ x: 0, y: 0 });
    };

    img.src = imageObjUrl;
  }, [editorContext.fileUploadEvent]);

  useEffect(() => {
    updateImageDimensions();
  }, [img, aspectRatio, tool]);

  useEffect(() => {
    if (img === undefined) setDisabled(true);
    else setDisabled(false);
    //setTool(rightDown);
  }, [img]);

  useEffect(() => {
    updateExpansionSettings(tool);
  }, [buttonCategory, imageDimensions, blockDimensions]);

  // useEffect(() => {
  //   if (aspectRatio < actualDimensions.width / actualDimensions.height) {
  //     setImageCoordinates({ x: 0, y: 0 });
  //   } else {
  //     setImageCoordinates({ x: blockDimensions.width - imageDimensions.width, y: imageCoordinates.y });
  //   }
  // }, [aspectRatio]);

  useEffect(() => {
    if (editorContext.workflowOptions['aspect_ratio']) {
      const arrAspectRatio = editorContext.workflowOptions['aspect_ratio'].value.split(':');
      const ratio = arrAspectRatio[0] / arrAspectRatio[1];

      if (aspectRatio !== ratio) {
        setAspectRatio(ratio);
        //updateExpansionSettings(tool);
        //setImageCoordinates({ x: 0, y: 0 });
        //setTool(rightDown);
      }
    }
  }, [editorContext.workflowOptions]);

  const updateImageDimensions = () => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      if (aspectRatio > actualDimensions.width / actualDimensions.height) {
        // increase width
        const blockWidth = divRef.current?.offsetWidth;
        const blockHeight = blockWidth / aspectRatio; // this equals to imageHeight as well
        const imgWidth = (actualDimensions.width * blockHeight) / actualDimensions.height;
        setImageDimensions({ width: imgWidth, height: blockHeight });
        setBlockDimensions({ width: blockWidth, height: blockHeight });
        setButtonCategory('width');
        // setImageCoordinates({ x: blockDimensions.width - imageDimensions.width, y: imageCoordinates.y });
      } else if (aspectRatio < actualDimensions.width / actualDimensions.height) {
        // increase height
        const blockWidth = divRef.current?.offsetWidth; // this will be same for imageWidth
        const blockHeight = blockWidth / aspectRatio;
        const imgHeight = (blockWidth * actualDimensions.height) / actualDimensions.width;
        setImageDimensions({ width: blockWidth, height: imgHeight });
        setBlockDimensions({ width: blockWidth, height: blockHeight });
        setButtonCategory('height');
        // setImageCoordinates({ x: 0, y: 0 });
      } else {
        const blockWidth = divRef.current?.offsetWidth;
        const blockHeight = blockWidth / aspectRatio; // this will equal to image dimensions as well
        setBlockDimensions({ width: blockWidth, height: blockHeight });
        setImageDimensions({ width: blockWidth, height: blockHeight });
      }
    }
  };

  const updateExpansionSettings = (layout: any) => {
    setTool(layout);
    if (layout === leftUp) {
      if (buttonCategory === 'width') {
        setImageCoordinates({ x: blockDimensions.width - imageDimensions.width, y: 0 });
      } else {
        setImageCoordinates({ x: 0, y: blockDimensions.height - imageDimensions.height });
      }
    } else if (layout === bothSide) {
      if (buttonCategory === 'width') {
        setImageCoordinates({ x: (blockDimensions.width - imageDimensions.width) / 2, y: 0 });
      } else {
        setImageCoordinates({ x: 0, y: (blockDimensions.height - imageDimensions.height) / 2 });
      }
    } else {
      setImageCoordinates({ x: 0, y: 0 });
    }
    updateWorkflow(layout);
  };

  const getSelectedButton = (button: any) => {
    const style: any = {
      borderRadius: '8px',
      boxShadow: 'none',
      border: 'none',
      color: '#6F7B8F',
    };
    if ((button === tool || tool === all) && !disabled) {
      style.color = '#6236FF';
      style.border = '1px';
      style.borderStyle = 'solid';
      style.borderColor = '#6236FF';
    }
    return style;
  };

  const updateWorkflow = (value: string) => {
    console.log(value, control, editorContext.workflowOptions);
    if (value) {
      const controlName = control.controlName;
      const controlData: any = {};
      controlData[controlName] = { value };
      editorContext.setWorkFlowOptions({ ...editorContext.workflowOptions, ...controlData });
    }
  };

  const handleMouseEnter = (e: any) => {
    // if (e.target.type === 'button') {
    //     e.target.style.color = '#6236FF';
    // }
  };

  const handleMouseLeave = (toolName: string, e: any) => {
    // const border = e.target.style.border;
    // if (toolName === tool) {
    //     return;
    // }
    // if (e.target.type === 'button') {
    //     e.target.style.color = '#6F7B8F';
    // }
  };

  return (
    <>
      <ControlLabel title={control.controlLabel} subTitle={control.description} />

      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', margin: 'auto' }}>
        <div ref={divRef} style={{ width: '100%', height: '' }}>
          <Stage
            width={blockDimensions.width}
            height={blockDimensions.height}
            style={{ background: '#F2F4F6', marginBottom: '5px', borderRadius: '12px' }}
            // ref={}
          >
            <Layer>
              <Rect
                x={0}
                y={0}
                width={blockDimensions.width}
                height={blockDimensions.height}
                fill={img !== undefined ? '#abd2f5' : 'transparent'}
              ></Rect>
            </Layer>
            <Layer>
              <Image
                image={image}
                ref={imgRef}
                width={imageDimensions.width}
                height={imageDimensions.height}
                x={imageCoordinates.x}
                y={imageCoordinates.y}
                shadowBlur={10}
                shadowColor={'#4b8dc9'}
              />
            </Layer>
          </Stage>
        </div>
        <div
          style={{
            maxWidth: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '5px',
            marginBottom: '5px',
          }}
        >
          <Stack style={{ flexDirection: 'row', gap: '5px' }}>
            <Button
              style={getSelectedButton(rightDown)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={(event) => handleMouseLeave(rightDown, event)}
              onClick={() => {
                updateExpansionSettings(rightDown);
              }}
              disabled={disabled}
            >
              <Icon size={'lg'} type={buttonCategory === 'width' ? (faRightLong as IconDefinition) : (faDownLong as IconDefinition)} />
            </Button>
            <Button
              style={getSelectedButton(leftUp)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={(event) => handleMouseLeave(leftUp, event)}
              onClick={() => {
                updateExpansionSettings(leftUp);
              }}
              disabled={disabled}
            >
              <Icon size={'lg'} type={buttonCategory === 'width' ? (faLeftLong as IconDefinition) : (faUpLong as IconDefinition)} />
            </Button>
            <Button
              style={getSelectedButton(bothSide)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={(event) => handleMouseLeave(bothSide, event)}
              onClick={() => {
                updateExpansionSettings(bothSide);
              }}
              disabled={disabled}
            >
              <Icon size={'lg'} type={buttonCategory === 'width' ? (faLeftRight as IconDefinition) : (faUpDown as IconDefinition)} />
            </Button>
            <Button
              style={getSelectedButton(all)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={(event) => handleMouseLeave(all, event)}
              onClick={() => {
                updateExpansionSettings(all);
              }}
              disabled={disabled}
            >
              <Icon size={'lg'} type={faCircleDot as IconDefinition} />
            </Button>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default ImageExpansionCanvas;
