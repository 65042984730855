import React from 'react';
import Stack from 'src/customUIComponents/Stack';

const Content = (props: any) => {
  const { children } = props;

  const style: React.CSSProperties = {
    marginTop: '0px',
    height: 'auto',
    padding: '0px',
  };

  return (
    <Stack style={{ ...style }} className="content">
      {children}
    </Stack>
  );
};

export default Content;
