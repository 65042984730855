import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppBar, Container, Stack, Toolbar, Typography } from '@mui/material/';
import Link from '@mui/material/Link';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../../AppContext';
import Logo from '../../common/Logo';
import AppBarMenu from '../../menus/AppBarMenu';

function EditModeBar() {
  const context = useContext(AppContext);
  const [controlTitle, setControlTitle] = useState<string>();
  const { metrics } = context;
  const linkTo = useNavigate();

  useEffect(() => {
    const tools = context.tools;
    const selectedTool = context.selectedTool ? context.selectedTool : window.localStorage.getItem('selectedTool');

    setControlTitle(tools.filter((tool: any) => tool.type === selectedTool).map((item: any) => item.title));
  }, []);

  const handleButtonClick = () => {
    const route = context.activePage === 'home' ? '' : context.activePage;
    metrics.trackMetrics(
      Metrics.Methods.NavBarClick,
      { [Metrics.Names.ButtonId]: route ? route : Metrics.Values.Home },
      { [Metrics.Counters.Clicks]: 1 },
    );

    linkTo(`/${route}`);
  };

  return (
    <AppBar style={{ backgroundColor: 'white', boxShadow: 'none' }}>
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Toolbar className={'page-margin'} style={{ padding: 0, marginRight: 0 }}>
          <Typography variant="h5" component="div">
            <Logo />
          </Typography>
          <Stack direction="row" sx={{ flexGrow: 1 }} alignItems={'center'}>
            <ChevronRightIcon sx={{ color: '#5A6472', marginLeft: '-7px' }} />
            <Link underline={'none'} px={2} onClick={handleButtonClick} style={{ cursor: 'pointer', paddingLeft: '5px', paddingRight: '5px' }}>
              <Typography variant="h6" component="div" style={{}}>
                {`${context.activePage}`}
              </Typography>
            </Link>
            <ChevronRightIcon sx={{ color: '#5A6472' }} />
            <Link underline={'none'} px={2} style={{ cursor: 'pointer', paddingLeft: '5px', paddingRight: '5px' }}>
              <Typography variant="h6" component="div">
                {controlTitle}
              </Typography>
            </Link>
          </Stack>
          <AppBarMenu showFeedbackButton={false} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default EditModeBar;
