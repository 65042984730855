import Genaihubbackend, { ASINMetadata, Asset, WorkflowId } from '@amzn/genaihub-typescript-client';
import themesConfig from 'src/components/editor/UiContols/uiGeneratorControls/themes.json';
import { EffectOption, EffectLookupByValue } from 'src/components/pages/studio/effectSettings/effectSettings';
import { ASINItem, ContentItem } from 'src/components/pages/studio/StudioContext';
import { urlToFile } from 'src/components/utils/base64Encode';
import { getContentTypeByWorkflowId } from 'src/components/utils/getWorkflowNameById';
import themeIcons from 'src/config/themes/themesDropdownList';
import { EVENT_LOCAL_STORAGE, LOCAL_STORAGE_KEY_PRODUCTS, US_MARKETPLACE_ID } from 'src/constants';
import { sanitiseText } from 'src/helpers';

const storeAsinsInLocalStorage = (asinMap: { [key: string]: ASINMetadata }) => {
  // refresh local storage
  try {
    const cachedAsins = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PRODUCTS) || '[]') as ASINItem[];
    const cachedAsinsLookup: { [key: string]: ASINItem } = cachedAsins.reduce(
      (obj, cacheItem) => ({
        ...obj,
        [cacheItem.asin]: cacheItem,
      }),
      {},
    );

    for (let asin in asinMap) {
      const asinMetadata = asinMap[asin];
      // Skip asins without metadata
      if (!asinMetadata || Object.keys(asinMetadata).length === 0) continue;
      // Add asin or update existing asin with empty metadata
      if (!cachedAsinsLookup[asin] || !cachedAsinsLookup[asin].metadata || Object.keys(cachedAsinsLookup[asin].metadata).length === 0) {
        cachedAsinsLookup[asin] = {
          asin,
          metadata: asinMetadata,
          selectedImageIndex: 0,
        };
      }
    }

    const newCachedAsins = Object.values(cachedAsinsLookup);
    localStorage.setItem(LOCAL_STORAGE_KEY_PRODUCTS, JSON.stringify(newCachedAsins));
    window.dispatchEvent(new StorageEvent(EVENT_LOCAL_STORAGE, { key: LOCAL_STORAGE_KEY_PRODUCTS }));
  } catch (err) {
    console.error('Error while refreshing local storage', err);
  }
};

const fetchAsins = async (genAIBackendClient: Genaihubbackend, assets: Asset[]): Promise<Record<string, ASINMetadata>> => {
  const uniqueAsins = [...new Set(assets.map((item) => item.asin).filter((asin) => asin && asin !== ' '))] as string[];

  const asinMetadata = await Promise.all(
    uniqueAsins.map(async (asin) => {
      try {
        const fetchedAsin = (
          await genAIBackendClient.retrieveASINMetadataByASINId({
            asinId: asin,
            marketplaceId: US_MARKETPLACE_ID,
          })
        ).body;

        return {
          key: asin,
          value: fetchedAsin,
        };
      } catch (e: any) {
        console.error(`Failed to fetch asin ${asin} with error: ${e}`);
      }

      return {
        key: asin,
        value: {},
      };
    }),
  );

  const asinMap: { [key: string]: ASINMetadata } = asinMetadata.reduce(
    (obj, metadata) => ({
      ...obj,
      [metadata.key]: metadata.value,
    }),
    {},
  );

  return asinMap;
};

export const convertFeed = async (genAIBackendClient: Genaihubbackend, assets: Asset[]): Promise<ContentItem[]> => {
  const asinMap = await fetchAsins(genAIBackendClient, assets);
  storeAsinsInLocalStorage(asinMap);

  return await Promise.all(
    assets.map(async (item) => {
      let style = undefined;
      if (item.theme) {
        const matchingTheme = themesConfig.find((theme) => theme.controlValue === item.theme || theme.customPrompt === item.theme);
        if (matchingTheme) {
          style = {
            icon: (themeIcons as any)[matchingTheme.src],
            value: matchingTheme.controlValue,
            label: matchingTheme.controlLabel,
            prompt: matchingTheme.customPrompt,
          };
        }
      }

      const effects: Map<string, EffectOption> = new Map();
      let newPrompt = item.inputPrompt || '';
      if (newPrompt) {
        for (const [key, value] of Object.entries(EffectLookupByValue)) {
          const sanitisedKey = sanitiseText(key);
          if (newPrompt.includes(sanitisedKey)) {
            // Legacy - effects were added after the prompt
            newPrompt = newPrompt.replace(`, ${sanitisedKey}`, '');
            // Current - effects are added before the prompt
            newPrompt = newPrompt.replace(`${sanitisedKey}, `, '');
            effects.set(value.effectName, value.effectItem);
          }
        }
        newPrompt = newPrompt.trim();
      }

      let asinItem: ASINItem = {} as ASINItem;
      const hasAsin = item.asin && item.asin !== ' ';
      if (hasAsin) {
        asinItem = {
          ...asinItem,
          asin: item.asin!,
          metadata: asinMap[item.asin!],
        };
      }

      if (item.inputImageUri) {
        try {
          const file: any = await urlToFile(item.inputImageUri);
          const blobUrl = URL.createObjectURL(file);

          if (hasAsin) {
            asinItem = {
              ...asinItem,
              customProductImage: blobUrl,
              selectedImageIndex: -1,
            };
          } else {
            asinItem = {
              customImage: {
                url: blobUrl,
                name: file.name,
                size: file.size,
              },
            } as ASINItem;
          }
        } catch (e: any) {
          console.error(`Failed to fetch image uri ${item.inputImageUri} with error: ${e}`);
        }
      }

      // const contentFile = await urlToFile(item.uri, 'image/png');
      return {
        originalUri: item.uri,
        // Defered loading of the image to ContentTile
        // content: URL.createObjectURL(contentFile),
        referenceId: item.id,
        loading: false,
        contentType: getContentTypeByWorkflowId(item.workflowId),
        contentHeadline: newPrompt,
        aspectRatio: item.aspectRatio ? item.aspectRatio : '1.91:1',
        studioInputSettings: {
          effects,
          style,
          asin: Object.keys(asinItem).length ? asinItem : undefined,
          prompt: newPrompt,
          // need to update type of item here once 'aspectRatio' is added to Asset in the back-end
          format: item.aspectRatio ? item.aspectRatio : '1.91:1',
          advanced: undefined,
          enablePromptRewriting: false,
        },
        workflowId: item.workflowId as WorkflowId,
      };
    }),
  );
};
