import { Stack, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect } from 'react';
import { Metrics } from 'src/constants';
import { AppContext, EditorContext } from '../../../../../../AppContext';

const ToggleGroup = (props: any) => {
  const { control } = props;
  console.log('control', control);
  const { controlName, controlLabel, controlValues, defaultValue, visible, controlMetadata, parent } = control;
  const context: any = props.editorContext ? useContext(props.editorContext) : useContext(EditorContext);
  const { metrics } = useContext(AppContext);

  const selected = context.workflowOptions[controlName]?.value || defaultValue;
  console.log('selected', selected);

  const style1 = (selected: boolean): React.CSSProperties => {
    if (!selected) return {};

    return {
      borderColor: '#6236FF',
      backgroundColor: '#EFEBFF',
    };
  };

  const style2 = (selected: boolean): React.CSSProperties => {
    const style: any = {
      border: 0,
      height: '26px',
      backgroundColor: '#D9DEE4',
      margin: 1,
      group: { borderRadius: '99px' },
    };
    if (selected) {
      style.color = 'white';
      style.backgroundColor = '#5C687C';
    }

    return style;
  };

  const getStyle = controlMetadata?.style === 'style2' ? style2 : style1;

  const onChangeHandler = (event: React.MouseEvent<HTMLElement>, item: string) => {
    if (metrics) {
      metrics.trackMetrics(
        Metrics.Methods.ToggleGroup,
        {
          [Metrics.Names.Selected]: item,
          ...(selected && selected !== '' && { [Metrics.Names.Unselected]: selected }),
        },
        { [Metrics.Counters.Count]: 1 },
      );
    }

    //setSelected(item);
    const obj: Model2 = {};
    obj[controlName] = { value: item, type: 'text' };
    context.setWorkFlowOptions({ ...context.workflowOptions, ...obj });
  };

  useEffect(() => {
    console.log(context.workflowOptions);
    console.log(context.workflowConfig);
  }, [context.workflowOptions]);

  const Ungrouped = () => {
    let fullWidth: string;
    if (controlMetadata?.hasOwnProperty('fullWidth')) {
      fullWidth = controlMetadata.fullWidth ? '1' : '0';
    } else {
      fullWidth = '1';
    }
    return (
      <>
        <Stack direction={'row'} justifyContent={'center'} gap={0.5} padding={'16px'} margin={0}>
          {controlValues &&
            controlValues.map((item: any, index: number) => {
              return (
                <>
                  <ToggleButton
                    key={'ungrouped' + index}
                    value={item.value}
                    selected={selected === item.value}
                    onChange={onChangeHandler}
                    sx={{ borderRadius: '8px', flexGrow: fullWidth }}
                    style={getStyle(selected === item.value)}
                    data-testid="toggleButton"
                  >
                    {item.label}
                  </ToggleButton>
                </>
              );
            })}
        </Stack>
      </>
    );
  };
  const Grouped = () => {
    return (
      <>
        <Box pb={2} width={'260px'} textAlign={'center'}>
          <ToggleButtonGroup fullWidth={controlMetadata?.fullWidth} sx={{ borderRadius: controlMetadata?.style === 'style2' ? '99px' : '16px' }}>
            {controlValues &&
              controlValues.map((item: any, index: number) => {
                return (
                  <ToggleButton
                    key={'grouped' + index}
                    value={item.value}
                    selected={selected === item.value}
                    onChange={onChangeHandler}
                    sx={{ borderRadius: '8px', flexGrow: '1' }}
                    style={getStyle(selected === item.value)}
                    data-testid="toggleButton"
                  >
                    {item.label}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
        </Box>
      </>
    );
  };
  return (
    <>
      {visible && (
        <>
          <Typography fontSize={15} textAlign={'left'} fontWeight={400} pl={'16px'} pt={'16px'}>
            {!parent && controlLabel}
          </Typography>
          {controlMetadata?.variant === 'grouped' ? <Grouped /> : <Ungrouped />}
        </>
      )}
    </>
  );
};
export default ToggleGroup;
