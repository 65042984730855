import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Info from '../../../../../icons/Info';

const style = {
  background: ' linear-gradient(to right, #f32170, #ff6b08,#cf23cf, #eedd44)',
  webkitTextFillColor: 'transparent',
  webkitBackgroundClip: 'text',
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 14,
    maxWidth: '460px',
  },
}));

const _Tooltip = ({ title }: { title: any }) => (
  <LightTooltip title={title} arrow placement="top">
    <IconButton
      size="small"
      sx={{
        m: 0.2,
        backgroundColor: 'none',
        opacity: '0.8',
        color: 'rgba(255, 255, 255, 1)',
        '&.MuiIconButton-root:hover': {
          bgcolor: 'none',
        },
      }}
      aria-label={`Close`}
    >
      <Info />
    </IconButton>
  </LightTooltip>
);

export default _Tooltip;
