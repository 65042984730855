import { Inspirations as InspirationsType, InspirationalContent } from '@amzn/genaihub-typescript-client';
import { Text } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AssetGallery from 'src/components/common/assetGallery/AssetGallery';
import ButtonWithBorder from 'src/components/common/storm/ButtonWithBorder';
import Title from 'src/components/common/storm/Title';
import getBreakpoints from 'src/components/utils/getBreakpoints';
import { Metrics } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import styled from 'styled-components';
import componentStyles from './Inspirations.module.scss';
import { AppContext } from '../../../../AppContext';
import { getPageName } from '../../utils/getPageName';

export interface InspirationsProps {
  showButton?: boolean;
  width?: string;
  maxItems?: number;
  masonry?: boolean;
}

const defaultWidth = '100%';

const Container = styled(Stack)`
  flex-direction: column;
`;

const Header = styled(Stack)<{ width?: string }>`
  flex-direction: row;
  width: ${({ width }) => width || defaultWidth};
  align-self: flex-start;
  max-width: 2000px;
  margin-bottom: 20px;
  padding-right: var(--div-spacing-default);
  padding-left: var(--div-spacing-default);
  margin-bottom: var(--tools-description-container-margin-bottom);
`;

const GalleryContainer = styled.div<{ width?: string }>`
  flex-direction: row;
  width: ${({ width }) => width || defaultWidth};
  align-self: flex-start;
  max-width: 2000px;
  margin-bottom: 20px;
  padding-right: var(--div-spacing-default);
  padding-left: var(--div-spacing-default);
`;

const Inspirations = (props: InspirationsProps) => {
  const { showButton, width, maxItems } = props;
  const { authenticated, metrics } = useContext(AppContext);
  const [assets, setAssets] = useState<InspirationsType>([]);
  const genAIBackendClient = useAIBackendHubClient();
  const [xl, lg, md, sm] = getBreakpoints('xl', 'lg', 'md', 'sm');
  const columns = xl ? 3 : lg ? 3 : md ? 3 : sm ? 2 : 1;
  const linkTo = useNavigate();
  const title = 'Get inspired',
    defaultWidth = '100%';

  const handleClickEvent = (page: string) => {
    metrics.trackMetrics(
      Metrics.Methods.HomePage,
      { [Metrics.Names.ButtonId]: Metrics.Buttons.ViewAll, [Metrics.Names.Destination]: getPageName(page) },
      { [Metrics.Counters.Clicks]: 1 },
    );
    linkTo(page);
  };

  const assetClickHandler = (asset: InspirationalContent) => {
    linkTo('/studio' + (asset.textPrompt ? '?prompt=' + asset.textPrompt + '&generate=true' : ''));
  };

  useEffect(() => {
    authenticated ? getInspirations() : getCreattiveContent();
  }, []);

  const getInspirations = async (startKey?: string) => {
    try {
      const response = await genAIBackendClient.retrieveInspirationsList({
        startKey,
      });

      setAssets([...assets, ...(response.body.inspirations ?? [])].splice(0, maxItems));
    } catch (error) {
      console.log(error);
    }
  };

  const getCreattiveContent = async () => {
    const response = await import('src/config/unauthenticated/gallery-content.json');
    setAssets(response.default.slice(0, 6));
  };

  return (
    <Container>
      <Header styles={{ width: width || defaultWidth }}>
        <Text
          style={{
            width: '100%',
            paddingBottom: 'var(--tools-title-padding-bottom)',
            fontFamily: 'AmazonEmberMono-Bold',
            letterSpacing: '3px',
          }}
          data-testid="gallery-section-header-title"
        >
          GALLERY
        </Text>
        {showButton && (
          <Title className={componentStyles.description} title={title} style={{ flex: 1 }} data-testid="gallery-section-header-description" />
        )}
        {showButton && (
          <ButtonWithBorder
            text="Explore the gallery"
            clickHandler={() => handleClickEvent(authenticated ? '/browse' : '/gallery')}
            data-testid="gallery-section-header-page-link"
          />
        )}
      </Header>
      <GalleryContainer width={width || defaultWidth}>
        <AssetGallery assets={assets} columns={columns} spacing={1} detailsClickHandler={assetClickHandler} />
      </GalleryContainer>
    </Container>
  );
};

export default Inspirations;
