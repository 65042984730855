export default function ({ color = 'url(#exclamationTriangle_linear_rollover)' }: { color?: string }) {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="exclamation-triangle" clipPath="url(#clip0_1250_303)">
        <path
          id="Vector"
          d="M18 11.0001C18 11.5479 17.719 12.0582 17.2524 12.3746L17.107 12.4635L2.70696 20.3694C2.15028 20.675 1.46232 20.6772 0.90348 20.3751C0.395444 20.1004 0.0644313 19.6124 0.00844687 19.0702L-7.65207e-07 18.906L-7.40522e-08 3.09426C-4.75367e-08 2.48766 0.344641 1.92735 0.903481 1.62522C1.40644 1.35331 2.01398 1.32788 2.53628 1.54833L2.70696 1.63092L17.107 9.5368C17.66 9.84041 18 10.3976 18 11.0001ZM9.6 11.0001C9.6 10.7001 9.3 10.4001 9 10.4001L-3.67176e-07 9.80015C-3.97265e-07 10.4885 -3.92364e-07 10.3764 -4.19629e-07 11.0001C-4.46895e-07 11.6239 -4.48942e-07 11.6707 -4.72083e-07 12.2001L9 11.6001C9.3 11.6001 9.6 11.3001 9.6 11.0001Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1250_303">
          <rect width="20.4" height="18" fill="white" transform="translate(18 0) rotate(90)" />
        </clipPath>
      </defs>
      <linearGradient id="exclamationTriangle_linear_rollover" x1="0" y1="0" x2="18" y2="0" width="18" height="22" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6236FF" />
        <stop offset="0.55" stopColor="#E123C2" />
        <stop offset="1" stopColor="#feb424" />
      </linearGradient>
    </svg>
  );
}
