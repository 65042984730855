import { Layer, Line } from 'react-konva';

const BrushSelection = (props: { brush: any; lines: any; opacity?: number; stroke?: string; strokeWidth?: number }) => {
  const brush = props.brush || {};
  const lines = props.lines || [];

  return (
    <Layer>
      {lines.map((line: any, i: any) => (
        <Line
          key={i}
          points={line.points}
          stroke={props.stroke || 'black'}
          strokeWidth={props.strokeWidth || 30}
          tension={0}
          lineCap="round"
          lineJoin="round"
          opacity={line.tool === 'eraser' ? 1 : props.opacity ? props.opacity : 0.4}
          globalCompositeOperation={line.tool === 'eraser' ? 'destination-out' : 'source-over'}
          strokeScaleEnabled={false}
        />
      ))}
    </Layer>
  );
};

export default BrushSelection;
