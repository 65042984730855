import { Text } from '@amzn/storm-ui';
import React from 'react';
import styles from './sideBarItem.module.scss';

export const SideBarItem = (props: { onClick: () => void; text: string; icon: React.ReactElement; active: boolean }) => {
  const className = !props.active ? styles.container : `${styles.container} ${styles.active}`;
  return (
    <div className={className} onClick={props.onClick} role="button">
      <div className={styles.item}> {props.icon} </div>
      <Text className={styles.text}> {props.text} </Text>
    </div>
  );
};
