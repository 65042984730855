import { useEffect, useState } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { urlToFile } from 'src/components/utils/base64Encode';

let assetImageCache: Record<string, string> | null = {};

export class AsseImageCache {
  setAssetImage = async (asset?: ContentItem) => {
    if (!asset) return;
    const { originalUri, referenceId } = asset;
    if (originalUri) {
      const file = await urlToFile(originalUri, 'image/png');
      return this.setImage(referenceId, URL.createObjectURL(file));
    }
    return originalUri;
  };

  setImage = (identifier?: string, url?: string) => {
    if (!identifier || !url) {
      return;
    }
    if (!assetImageCache) {
      assetImageCache = {};
    }
    return (assetImageCache[identifier] = url);
  };

  getImageForAsset = async (asset?: ContentItem) => {
    if (!asset) return;
    const { referenceId } = asset;
    let url = this.getImage(referenceId);
    if (!url) {
      return await this.setAssetImage(asset);
    } else {
      return url;
    }
  };

  getImage = (identifier?: string) => {
    if (!assetImageCache || !identifier) {
      return;
    }
    return assetImageCache[identifier];
  };
}

const useAssetImageCache = (contentItem?: ContentItem) => {
  const [content, setContent] = useState<string | undefined>(contentItem?.content);
  const cache = new AsseImageCache();

  useEffect(() => {
    let unMounted = false;
    cache.getImageForAsset(contentItem).then((url) => {
      if (!unMounted && url) {
        setContent(url);
      }
    });
    return () => {
      unMounted = true;
    };
  }, [contentItem?.originalUri]);

  return content;
};

export default useAssetImageCache;
