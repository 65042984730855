import type Konva from 'konva';
import { FC, useContext, useEffect, useRef } from 'react';
import { Image as KonvaImage, Layer, Stage, Transformer } from 'react-konva';
import { ASPECT_RATIO } from 'src/components/imageModal/components/utils/types';
import useImage from 'use-image';
import { ImageModalContext } from '../../ImageModalContext';
import { getSizeWithAspectRatio } from '../utils';
export const ProductPositionCanvas: FC<{
  backgroundImageURL: string;
  productImageURL: string;
  boundingBox: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  canvasWidth: number;
  onReposition: (newPosition: { top: number; left: number; width: number; height: number }) => void;
}> = ({ onReposition, canvasWidth, backgroundImageURL, boundingBox, productImageURL }) => {
  const [backgroundImage] = useImage(backgroundImageURL);
  const [productImage] = useImage(productImageURL);
  const imageRef = useRef<Konva.Image>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const { savedEditsImageAspectRatio, studioInputSettings } = useContext(ImageModalContext);
  const aspectRatio = (savedEditsImageAspectRatio || studioInputSettings?.format || '1:1') as ASPECT_RATIO;
  const canvasHeight = getSizeWithAspectRatio({
    width: canvasWidth,
    aspectRatio,
  }).height;

  useEffect(() => {
    if (imageRef.current && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
    }
  }, [imageRef, transformerRef]);
  const getAttributesFromEvent = (currentTarget: Konva.Node) => {
    const {
      attrs: { x, y, width, height, scaleX, scaleY },
    } = currentTarget;
    return onReposition({
      width: width * scaleX,
      height: height * scaleY,
      left: x,
      top: y,
    });
  };

  const stageRef = useRef<Konva.Stage>(null);

  return (
    <Stage ref={stageRef} width={canvasWidth} height={canvasHeight} x={0} y={0}>
      <Layer>
        {backgroundImage && <KonvaImage width={canvasWidth} height={canvasHeight} image={backgroundImage} />}
        <>
          <KonvaImage
            draggable
            x={boundingBox.left}
            y={boundingBox.top}
            width={boundingBox.width}
            height={boundingBox.height}
            image={productImage}
            ref={imageRef}
            onDragEnd={(e) => {
              getAttributesFromEvent(e.currentTarget);
            }}
            dragBoundFunc={(vec: Konva.Vector2d) => {
              if (!imageRef.current || !stageRef.current) {
                return vec;
              }
              const newVec = { ...vec };
              if (vec.x < 0) {
                newVec.x = 0;
              }
              if (vec.y < 0) {
                newVec.y = 0;
              }
              const {
                attrs: { width, height, scaleX, scaleY },
              } = imageRef.current;
              const actualWidth = width * scaleX;
              const actualHeight = height * scaleY;
              if (vec.x + actualWidth > stageRef.current.width()) {
                newVec.x = stageRef.current.width() - actualWidth;
              }
              if (vec.y + actualHeight > stageRef.current.height()) {
                newVec.y = stageRef.current.height() - actualHeight;
              }

              return newVec;
            }}
            onTransformEnd={(e) => {
              getAttributesFromEvent(e.currentTarget);
            }}
          />
          <Transformer
            keepRatio={false}
            ref={transformerRef}
            rotateEnabled={false}
            anchorStyleFunc={(anchor) => {
              anchor.cornerRadius(1);
            }}
            anchorStroke="#30C1FF"
            borderStroke="#30C1FF"
            boundBoxFunc={(oldBox, newBox) => {
              if (!stageRef.current) return oldBox;
              const { x, y, width, height } = newBox;
              const isOut = x < 0 || y < 0 || x + width > stageRef.current.width() || y + height > stageRef.current.height();
              if (isOut) {
                return oldBox;
              }
              return newBox;
            }}
            onTransformEnd={(e) => {
              getAttributesFromEvent(e.currentTarget);
            }}
          />
        </>
      </Layer>
    </Stage>
  );
};
