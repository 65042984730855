import type Genaihubbackend from '@amzn/genaihub-typescript-client';
import { Sha256 } from '@aws-crypto/sha256-js';
import { getImageFromUrl, ImageType } from 'src/components/utils/base64Encode';

const toHexString = (byteArray: Uint8Array) => {
  return Array.from(byteArray, function (byte) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  }).join('');
};
export const uploadImage = async (file: File, genAIBackendClient: Genaihubbackend, contentCategory: any) => {
  console.log('START');

  console.log(file);

  const base64 = (
    await getImageFromUrl({
      url: URL.createObjectURL(file),
      type: file.type as ImageType,
    })
  ).base64Encode();
  const hash = new Sha256();
  hash.update(base64);
  const uint8Array = await hash.digest();
  const imageHash = toHexString(uint8Array);
  console.log(imageHash);

  const uploadResponse = await genAIBackendClient.fileUploadGetPresign({
    body: {
      contentSize: file?.size,
      contentType: file?.type,
      contentCategory: contentCategory,
      contentHash: imageHash,
    },
  });

  if (uploadResponse.body.url) {
    const res = await fetch(uploadResponse.body.url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        'Content-Length': file.size.toString(),
      },
      body: file,
    });
    console.log('Image Uploaded successfully');
  }

  return uploadResponse.body.referenceId;
};
