import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { uploadImage } from 'src/components/utils/uploadImage';

export const runBeforeJobSubmission = async (workflowId: string, workflowOptions: Record<string, any>, genAIBackendClient: Genaihubbackend) => {
  if (!workflowId) {
    throw new Error('Workflow ID is required');
  }

  if (workflowId === 'IMAGE_EDITOR' || workflowId === 'PARALLAX_MOTION') {
    if (workflowOptions['mask_ref']) {
      workflowOptions['mask_ref'].value = await uploadImage(workflowOptions['mask_ref'].file as File, genAIBackendClient, 'MASK_IMAGE');
    }
    workflowOptions['seed'] = {
      value: 47,
    };
    workflowOptions['image_count'] = {
      value: 4,
    };
  }
};
