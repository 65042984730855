import { useState, useRef } from 'react';
import ImageViewerOverlay from 'src/components/editor/ImageViewerOverlay';
//import VideoFrameExtractor from '../utils/videoFrameExtractor';

const VideoViewer = ({ urls }: any) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  /*
  useEffect(() => {
    const extractFrame = async () => {
      if (videoRef.current && canvasRef.current) {
        const videoFrameExtractor = new VideoFrameExtractor(videoRef.current, canvasRef.current);

        try {
          const frame = await videoFrameExtractor.extractFrameAtSecond(1);

          setImageSrc(frame);
        } catch (error) {
          console.error('Error extracting frame:', error);
        }
      }
    };

    extractFrame();
  }, []);
*/
  return (
    <>
      {urls?.length === 1 && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 'auto', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '720px', marginTop: '20px' }}>
              <video data-testid="sandbox-generated-video" ref={videoRef} width="720" height="408" controls>
                <source src={urls[0]} type="video/mp4" />
              </video>
              <canvas ref={canvasRef} width="720" height="408" style={{ display: 'none' }}></canvas>

              <ImageViewerOverlay url={urls[0]} type="video" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VideoViewer;
