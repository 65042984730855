import { UserDetails } from '@amzn/genaihub-typescript-client';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { getSelectedAdvertisingAccountFromLocalStorage } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { getWebLabAlias, isClosedBetaWeblabEnabled } from 'src/util/weblab/weblab';
import { AppContext } from '../../AppContext';

export interface AssetGalleryProps {
  children: ReactNode;
  unAuthenticated?: boolean;
  unAuthenticatedPages?: string[];
  unAuthenticatedFallback?: ReactNode;
  unAuthenticatedCallback?: (page?: string) => void;
}

export default function WithAuthentication({
  children,
  unAuthenticated,
  unAuthenticatedPages,
  unAuthenticatedFallback,
  unAuthenticatedCallback,
}: AssetGalleryProps) {
  const { activePage, authenticated, userDetails, setAuthenticated, setUserDetails, setAccountType, setSelectedAdvertisingAccount } =
    useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const genAIBackendClient = useAIBackendHubClient();

  const getUserDetails = async () => {
    try {
      const result = await genAIBackendClient.getUserDetails({});
      console.log('userData----');
      console.log(result);
      console.log('alias', result.body?.alias);

      if (!result.body) {
        throw new Error('Response not found');
      }

      const { alias, isExternal, isStudioEnabled } = result.body;
      const userDetails: UserDetails = { alias, isExternal };
      const isStudioEnabledForUser = isClosedBetaWeblabEnabled() || isStudioEnabled;

      if (!userDetails.alias && isStudioEnabledForUser) {
        // valid closed beta user but no username
        userDetails.alias = getWebLabAlias();
        userDetails.isExternal = true;
      }

      if (userDetails.isExternal && !isStudioEnabledForUser) {
        // is external but invalid closed beta user
        userDetails.alias = undefined;
        userDetails.isExternal = true;
      }

      console.log('alias', userDetails.alias, 'isExternal', userDetails.isExternal);

      if (!userDetails.alias) {
        // not part of the weblab nor midway
        setAuthenticated(false);
      } else {
        setAuthenticated(true);
        setUserDetails(userDetails);
        setAccountType(userDetails.isExternal ? 'external' : 'internal');
        // If external load the last set account before children render
        if (userDetails.isExternal) {
          setSelectedAdvertisingAccount(getSelectedAdvertisingAccountFromLocalStorage());
        }
        localStorage.setItem('userDetails', alias || 'undefined');
      }
    } catch (err) {
      console.log(err);
      console.log('Not logged in.');
    }
    setLoading(false);
  };

  useEffect(() => {
    setAuthenticated(false);
    if (!authenticated || !userDetails) {
      console.log('isAuthenticated check');
      getUserDetails();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading && !authenticated && !unAuthenticated && !unAuthenticatedPages?.includes(activePage)) {
      unAuthenticatedCallback?.(activePage);
    }
  }, [loading, activePage, authenticated, unAuthenticated]);

  return <>{loading ? '' : authenticated || unAuthenticated || unAuthenticatedPages?.includes(activePage) ? children : unAuthenticatedFallback}</>;
}
