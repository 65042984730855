import { Inspirations, InspirationalContent, Asset } from '@amzn/genaihub-typescript-client';
import { useState } from 'react';
import { Container, Header, Title, SubTitle } from 'src/components/pages/InspirationsGallery';
import { inspirations } from 'src/config';
import galleryContent from 'src/config/unauthenticated/gallery-content.json';
import ScrollableAssetGallery from '../common/assetGallery/ScrollableAssetGallery';
import { getWorkflowNameById } from '../utils/getWorkflowNameById';

const CreativeGallery = () => {
  const [loading] = useState<boolean>(false);
  const pageLength = 12;
  const [content, setContent] = useState<Asset[]>([]);

  function fetchContent() {
    if (content.length < galleryContent.length) {
      setContent([...content, ...getContentPage(content.length)]);
    }
  }

  function getContentPage(index: number = 0, length: number = pageLength) {
    return galleryContent.slice(index, index + length);
  }

  const assetClickHandler = (asset: InspirationalContent) => {
    console.log('assetClickHandler', asset);
  };

  const filterContent = (searchValue: string): Inspirations => {
    const value = searchValue.toLowerCase();
    return content.filter((item: InspirationalContent) => {
      return (
        item.textPrompt?.toLowerCase().includes(value) ||
        item.type?.toLowerCase().includes(value) ||
        getWorkflowNameById(item.workflowId)?.toLowerCase().includes(value)
      );
    });
  };

  return (
    <Container>
      <Header>
        <Title>{inspirations.title}</Title>
        <SubTitle>{inspirations.subTitle}</SubTitle>
      </Header>
      <ScrollableAssetGallery<InspirationalContent>
        loading={loading}
        content={content}
        fetchContent={fetchContent}
        filterContent={filterContent}
        clickCallback={assetClickHandler}
      />
    </Container>
  );
};

export default CreativeGallery;
