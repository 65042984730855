import { Dropdown, DropdownItem } from '@amzn/storm-ui';
import { useContext } from 'react';
import { EffectOption, EffectsEnum, EffectSettings } from 'src/components/pages/studio/effectSettings/effectSettings';
import { StudioContext } from 'src/components/pages/studio/StudioContext';
import style from './EffectsSetting.module.scss';

interface EffectItem {
  label: string;
  options: string[];
}

const OverrideLabel = (dropdownItem: any, value: any, effectId: string) => {
  if (value) {
    return dropdownItem;
  }
  return EffectSettings[effectId]?.label;
};
const EffectsSetting = () => {
  const studioContext = useContext(StudioContext);

  const handleDropdownChange = (effectId: string, value: EffectOption) => {
    const newEffects = new Map(studioContext.effects);
    newEffects?.set(effectId, value);
    studioContext.setEffects(newEffects);
  };

  return (
    <div className={style.container}>
      <div className={style.header}>Effects</div>
      <div className={style.dropdownList}>
        {[EffectsEnum.Lighting, EffectsEnum.Camera, EffectsEnum.Color].map((effect) => (
          <Dropdown
            style={{ backgroundColor: 'var(--secondary-button-color-variant-2)', boxShadow: 'none' }}
            onChange={(value) => handleDropdownChange(effect, value)}
            fullWidth={true}
            selectedValue={studioContext.effects?.get(effect)}
            onOverrideLabel={(dropdownItem, value) => OverrideLabel(dropdownItem, value, effect)}
            mobileFullScreen={true}
            secondaryViewCloseButtonLabel="Done"
          >
            {EffectSettings[effect].options.map((option) => (
              <DropdownItem key={option.label} value={option}>
                {option.label}
              </DropdownItem>
            ))}
          </Dropdown>
        ))}
      </div>
    </div>
  );
};

export default EffectsSetting;
