import { ImageList, Skeleton } from '@mui/material/';
import ImageListItem from '@mui/material/ImageListItem';
import React, { useContext, useState } from 'react';
import ImageViewer from './ImageViewer';
import './ImageList.scss';
import { useLocation } from 'react-router-dom';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../../AppContext';

export default function MasonryImageList(props: any) {
  const { metrics } = useContext(AppContext);
  const { imageGallery, cols, ...passthrough } = props;
  const { pathname } = useLocation();

  const clickHandler = (src: any, index: number) => {
    metrics.trackMetrics(
      Metrics.Methods.InperationPreview,
      { [Metrics.Names.Action]: Metrics.Actions.Open, index: index },
      { [Metrics.Counters.Clicks]: 1 },
    );
    setImageSource(src);
    setOpenViewer(true);
  };

  const [imageSource, setImageSource] = useState('');
  const [openViewer, setOpenViewer] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState(props.showPageView || pathname.startsWith('/browse'));
  const isInspirationPage = props.showPageView || pathname.startsWith('/browse');
  const chunkArrayInGroups = (arr: any[], count: number) => {
    const groups = new Array(count).fill(0).map((arr) => new Array(0));

    for (let i = 0; i < arr.length; i = i + count) {
      let y = i;
      groups.forEach((group) => {
        arr[y] && group.push(arr[y]);
        y++;
      });
    }

    return groups;
  };

  const groups = chunkArrayInGroups(imageGallery, 3);

  return (
    <div {...passthrough}>
      <ImageViewer src={imageSource} openViewer={openViewer} setOpenViewer={setOpenViewer}></ImageViewer>
      <ImageList
        className={!isInspirationPage ? 'image-list-wrapper' : ''}
        variant="masonry"
        sx={{
          columnCount: {
            xs: '1 !important',
            sm: '2 !important',
            md: `${cols - 1} !important`,
            lg: `${cols} !important`,
            xl: `${cols} !important`,
          },
        }}
        style={
          !isInspirationPage
            ? {
                width: '100vw',
                minHeight: imageGallery.length === 0 ? '560px' : 'auto',
                position: 'relative',
                left: 'calc(-50vw + 50%)',
                marginTop: 0,
              }
            : {}
        }
        gap={15}
      >
        {imageGallery.length == 0 ? (
          new Array(11).fill(0).map((item: any, index) => (
            <ImageListItem key={index} className={'image-list-item'} style={{ gridArea: `item${index + 1}` }}>
              <Skeleton variant="rounded" height={isInspirationPage ? 500 : '100%'} />
            </ImageListItem>
          ))
        ) : isInspirationPage ? (
          <React.Fragment>
            <ImageList variant="masonry" cols={1} gap={15}>
              <ImageListColumn
                imageGallery={groups[0]}
                showOverlay={showOverlay}
                isInspirationPage={true}
                clickHandler={(src, index) => clickHandler(src, index + 0)}
              ></ImageListColumn>
            </ImageList>
            <ImageList variant="masonry" cols={1} gap={15}>
              <ImageListColumn
                imageGallery={groups[1]}
                showOverlay={showOverlay}
                isInspirationPage={true}
                clickHandler={(src, index) => clickHandler(src, index + groups[0].length)}
              ></ImageListColumn>
            </ImageList>
            <ImageList variant="masonry" cols={1} gap={15}>
              <ImageListColumn
                imageGallery={groups[2]}
                showOverlay={showOverlay}
                isInspirationPage={true}
                clickHandler={(src, index) => clickHandler(src, index + groups[0].length + groups[1].length)}
              ></ImageListColumn>
            </ImageList>
          </React.Fragment>
        ) : (
          <ImageListColumn
            imageGallery={imageGallery}
            showOverlay={showOverlay}
            isInspirationPage={false}
            clickHandler={(src, index) => clickHandler(src, index)}
          ></ImageListColumn>
        )}
      </ImageList>
    </div>
  );
}

const ImageListColumn = (props: {
  imageGallery: any;
  showOverlay: boolean;
  isInspirationPage: boolean;
  clickHandler: (src: string, index: number) => void;
}) => {
  const remixHandler = (event: any) => {
    event.stopPropagation();
  };

  return props.imageGallery.map((item: any, index: number) =>
    item.src ? (
      <ImageListItem key={item.src} className={'image-list-item'} style={{ gridArea: `item${index + 1}` }}>
        <div
          style={{
            height: '100%',
          }}
          className={props.showOverlay ? 'container' : ''}
          onClick={() => {
            props.clickHandler(item.src, index);
          }}
        >
          <img
            className="image"
            srcSet={item.src}
            src={item.src}
            alt={item.src}
            loading="lazy"
            style={{ cursor: 'pointer', width: '100%', borderRadius: '8px', height: '100%', objectFit: 'cover' }}
          />
          {props.showOverlay ? (
            <div className="middle">
              <div className="text">{item.textPrompt}</div>
              {/*
               <div className="buttonsWrapper" style={{ paddingTop: '5px' }}>
                <Stack direction={'row'} gap={1}>
                  <Button variant={'contained'} color={'primary'} onClick={remixHandler}>
                    Remix with AI Gen
                  </Button>
                  <Button variant={'contained'} color={'secondary'} onClick={remixHandler}>
                    Edit Image
                  </Button>
                </Stack>
              </div>
              */}
            </div>
          ) : (
            <></>
          )}
        </div>
      </ImageListItem>
    ) : (
      <ImageListItem key={index}>
        <Skeleton variant="rounded" height={props.isInspirationPage ? 500 : 250} />
      </ImageListItem>
    ),
  );
};
