// import { AppContext } from 'AppContext';
import { FC, useContext } from 'react';
import AnimatedButton from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
// import { ProductEditAction, useWorkflow, WorkflowId } from '../utils';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import { HEADING_TEXT_SIZE } from 'src/constants';
import styles from './PositionControls.modules.scss';
import { ProductPositionCanvas } from './ProductPositionCanvas';
import { ImageModalContext } from '../../ImageModalContext';
export const PositionControls: FC = () => {
  // const { activePage, userDetails } = useContext(AppContext);
  // TODO: integration with backend;
  const { imageUrl } = useContext(ImageModalContext);
  // const {isPending} = useWorkflow<WorkflowId.PRODUCT_EDIT>({
  //   workFlowId: WorkflowId.PRODUCT_EDIT,
  //   userAlias: userDetails?.alias,
  //   pageName: activePage,
  // });
  return (
    <div className={styles.container}>
      <ControlLabel title={'Scale and rotate'} titleSize={HEADING_TEXT_SIZE.MEDIUM} />
      <ProductPositionCanvas
        onReposition={console.log}
        boundingBox={{
          width: 50,
          height: 30,
          top: 10,
          left: 10,
        }}
        canvasWidth={400}
        backgroundImageURL={imageUrl}
        productImageURL={'https://placehold.co/50x30'}
      />
      <div className={styles.controlSection}>
        <AnimatedButton
          data-testid="studio-edit-reframe-generate-button"
          // animate={isPending}
          style={{ paddingLeft: '20px', paddingRight: '0', minWidth: '150px' }}
          disabled={true}
          text={'Generate'}
          fullscreen={false}
        ></AnimatedButton>
      </div>
    </div>
  );
};
