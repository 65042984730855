import { Card, FileUploadButton, Icon } from '@amzn/storm-ui';
import { times } from '@amzn/storm-ui-icons';

import { useContext, useState } from 'react';
import Spinner from 'src/components/common/storm/Spinner';
import { uploadImage } from 'src/components/utils/uploadImage';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import ControlLabel from './UI/ControlLabel';
import { EditorContextP1 as EditorContext } from '../../../../../../AppContext';

const ImageUpload = ({ control }: any) => {
  const editorContext = useContext(EditorContext);

  const [uploaded, setUploaded] = useState<string>('');
  const genAIBackendClient = useAIBackendHubClient();

  const handleOnChange = async (e: any) => {
    setUploaded('');
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      prepareUpload(e.target.files[0]);
      // setFile(e.target.files[0]);
    }
  };

  const prepareUpload = async (file: File) => {
    if (file !== null) {
      setUploaded('uploading');
      const referenceId = await uploadImage(file, genAIBackendClient, 'PRODUCT_IMAGE');
      const controlName = control.controlName;
      const controlData = {
        [controlName]: { value: referenceId, file },
      };
      console.log('CONTROL DATA:::: ', controlData);
      editorContext.setWorkFlowOptions({ ...editorContext.workflowOptions, ...controlData });
      setUploaded('uploaded');

      editorContext.setFileUploadEvent({ payload: file, controlName });
      //editorContext.setFileUploadEvent({ payload: file });
    }
  };

  const ShowPreview = () => {
    const file = editorContext.workflowOptions[control.controlName]?.file;
    console.log(file);
    const name = file.name;
    const imageUrl = URL.createObjectURL(file);
    const size = Math.round((file.size / 1000000) * 10) / 10;
    return (
      <Card padding={0} style={{ background: '#f1f3f5', boxShadow: 'none', width: 'auto' }}>
        <Stack direction="row" wrap="nowrap">
          <div style={{ flex: 1 }}>
            <Card padding={0} style={{ overflow: 'hidden', maxWidth: '150px', background: 'none', boxShadow: 'none', height: 'auto', width: 'auto' }}>
              <img src={imageUrl} alt={'IMAGE PREVIEW'} style={{ height: '100px', overflow: 'hidden', display: 'table-column' }} />
            </Card>
          </div>

          <div style={{ fontSize: 'small', textAlign: 'left', textOverflow: 'ellipsis', maxWidth: '100px', overflow: 'hidden', flex: 4 }}>
            {name}
            <br />
            {size} MB
          </div>
          <Icon
            type={times}
            style={{ textAlign: 'right', cursor: 'pointer', flex: 2 }}
            onClick={() => {
              const controlData = { ...editorContext.workflowOptions };
              delete controlData[control.controlName];
              editorContext.setWorkFlowOptions({ ...controlData });
              editorContext.setFileUploadEvent(undefined);
              setUploaded('');
            }}
          />
        </Stack>
      </Card>
    );
  };

  const ShowLoading = () => (
    <>
      <div role="alert" aria-live="assertive" style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '25px', paddingLeft: '60px' }}>
        <Spinner size="lg" />
      </div>
    </>
  );
  return (
    <>
      <ControlLabel title={control.controlLabel} />

      <Card padding={10} style={{ background: '#f1f3f5', boxShadow: 'none', minHeight: '100px' }}>
        {uploaded === 'uploading' ? (
          <ShowLoading />
        ) : uploaded === 'uploaded' ? (
          ShowPreview()
        ) : (
          <Card style={{ background: 'white', boxShadow: 'none', height: '100px', width: '170px', paddingTop: '8px' }}>
            <FileUploadButton inputId="file-uploader" label="Upload file" onChange={handleOnChange} />
          </Card>
        )}
      </Card>
    </>
  );
};

export default ImageUpload;
