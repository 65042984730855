import { Button, Text } from '@amzn/storm-ui';
import aiLabTheme from 'src/config/themes/aiLabThemeV2';

export default function (props: any) {
  const { disabled, clickHandler, text, width, fullscreen, style, ...rest } = props;
  console.log('buttonDisabled', disabled);
  return (
    <Button
      {...rest}
      disabled={disabled}
      primary={true}
      style={{
        color: aiLabTheme.buttonText.secondaryV2,
        backgroundColor: 'transparent',
        borderWidth: '2px',
        borderColor: aiLabTheme.buttonBorder.secondaryV2,
        width: fullscreen ? '100%' : width,
        height: '42px',
        minHeight: '42px',
        ...style,
      }}
      onClick={clickHandler}
    >
      <Text style={{ fontSize: '15px', fontWeight: '600' }}>{text}</Text>
    </Button>
  );
}
