import { useState, useEffect, useRef } from 'react';
import './css/Carousel.css';
import ArrowNav from 'src/components/common/storm/ArrowNav';
import Circle from 'src/icons/circle';
import Stack from './Stack';

const Carousel = (props: any) => {
  const { items, ItemHeight, itemWidth, width, height, navigate = undefined, ...rest } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [showLeftNav, setShowLeftNav] = useState(false);
  const [showRightNav, setShowRightNav] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollLeft = itemWidth ? itemWidth.match(/\d+/g) : [1300];
  const scrollStep = Number(scrollLeft[0]) + 20;

  const useDots = navigate === 'dots' || navigate === 'all' ? true : false;
  const useArrows = navigate === 'arrows' || navigate === 'all' ? true : false;
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleNavClick = (index: any) => {
    console.log(index);
    setCurrentIndex(index);
  };

  const handleResize = (event: any) => {
    checkScrollPosition(scrollRef.current?.scrollLeft);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, left: scrollPosition, behavior: 'smooth' });
      checkScrollPosition(scrollPosition);
    }
  }, [scrollPosition]);

  const handleScroll = () => {
    checkScrollPosition(scrollRef.current?.scrollLeft);
  };
  const checkScrollPosition = (position: any) => {
    if (scrollRef.current) {
      const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

      if (scrollRef.current) {
        if (position >= maxScrollLeft) {
          setShowRightNav(false);
        } else {
          setShowRightNav(true);
        }

        if (position <= 0) {
          setShowLeftNav(false);
        } else {
          setShowLeftNav(true);
        }
      }
    }
  };

  const handleArrowClick = (dir: string) => {
    if (scrollRef.current) {
      let nextStep;
      switch (dir) {
        case 'left':
          nextStep = scrollRef.current.scrollLeft - scrollStep;
          break;
        case 'right':
          nextStep = scrollRef.current.scrollLeft + scrollStep;
          break;
        default:
          nextStep = 0;
      }

      setScrollPosition(nextStep);
    }
  };

  return (
    <div className="carousel-container">
      <div
        className="carouselScroll"
        ref={scrollRef}
        style={{ paddingLeft: 'var(--div-spacing-default)', overflowX: 'scroll' }}
        onScroll={handleScroll}
      >
        <Stack style={{ gap: '20px' }}>
          <div className="carousel" style={{ width, height, alignSelf: 'flex-start' }}>
            <div className="carousel-inner" data-testid="carousel-items">
              {items.map((item: any, index: number) => (
                <div
                  className="carousel-item"
                  key={index}
                  style={{
                    width: itemWidth,
                    height: ItemHeight,
                    marginRight: index === items.length - 1 ? 'var(--div-spacing-default)' : '10px',
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </Stack>
      </div>
      {useArrows && items.length > 1 && (
        <>
          <Stack style={{ flexDirection: 'row', width: 'auto', position: 'absolute', top: '200px' }}>
            <ArrowNav visible={showLeftNav} dir={'left'} clickHandler={handleArrowClick} />
          </Stack>
          <Stack style={{ flexDirection: 'row', width: 'auto', position: 'absolute', right: '0', top: '200px' }}>
            <ArrowNav visible={showRightNav} dir={'right'} clickHandler={handleArrowClick} />
          </Stack>
        </>
      )}
      {useDots && items.length > 1 && (
        <div className="carousel-nav">
          {items.map((_: any, index: any) => (
            <div key={index} className={`carousel-nav-button ${index === currentIndex ? 'active' : ''}`} onClick={() => handleNavClick(index)}>
              <Circle />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
