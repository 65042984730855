import { Button, Text } from '@amzn/storm-ui';
import React from 'react';
import './AnimatedButton.css';
import Lottie from 'react-lottie';
import Stack from 'src/customUIComponents/Stack';
import { StarsGenerating } from '../../../../../../animations/animations';

export default function (props: any) {
  const { disabled, clickHandler, text, width, fullscreen, style, animate } = props;

  const animationStyle: React.CSSProperties = {
    background:
      'linear-gradient(45deg, rgba(205,9,255,1) 0%, rgba(95,47,250,0.8939950980392157) 19%, rgba(92,175,255,0.8099614845938375) 38%, rgba(90,169,255,0.8519782913165266) 62%, rgba(48,13,242,1) 100%)',
    backgroundSize: '200% 200%',
    color: 'white',
  };

  const staticStyle: React.CSSProperties = {
    backgroundColor: disabled ? '' : '#4305F4',
  };

  const appliedStyle = animate ? animationStyle : staticStyle;

  const defaultOptions = {
    loop: true,
    autoplay: animate || false,
    animationData: StarsGenerating,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Button
      {...props}
      className="animated-button"
      primary={true}
      style={{ width: fullscreen ? '100%' : width, height: '42px', ...appliedStyle, ...style }}
      onClick={clickHandler}
    >
      <Stack style={{ flexDirection: 'row', justifyContent: 'center', position: 'relative', top: '-10px', left: '-20px' }}>
        <Lottie isStopped={!animate} style={{ margin: '0px' }} options={defaultOptions} height={50} width={50} />
        <Text style={{ fontSize: '15px', fontWeight: '500' }}>{text}</Text>
      </Stack>
    </Button>
  );
}
