export const FAILURE_TEXT = 'Sorry, something went wrong. Please try again later.';
export const PROCESSING_TEXT = 'This is taking longer than expected. To save your image, wait a little longer or try again later.';

export const PublishAssetProcessingNotification = () => {
  return <>{PROCESSING_TEXT}</>;
};

export const PublishAssetFailureNotification = () => {
  return <>{FAILURE_TEXT}</>;
};
