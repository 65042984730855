import Close2Icon from 'src/icons/close2.svg';

export const CloseButton = (props: { onClick: (event: React.MouseEvent) => void; visibility?: boolean; className?: string }) => {
  return (
    <div
      className={`imageListItemCloseButton ${props.className}`}
      style={{
        position: 'absolute',
        visibility: props.visibility ? 'visible' : 'hidden',
        width: '25px',
        height: '25px',
        right: '5px',
        top: '5px',
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        zIndex: 3,
      }}
      onClick={props.onClick}
    >
      <Close2Icon />
    </div>
  );
};
