import { Button, Card, Divider, Icon, Section, Text } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faIcons, faPenToSquare, faSliders, faVideo } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import * as ControlComponents from 'src/components/editor/UiContols/uiGeneratorControls';
import StdButton from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/StdButton';
import Stack from 'src/customUIComponents/Stack';

const StudioControlPanel = ({ workflows, bulkUpdateCallback }: any) => {
  const controlComponents: any = ControlComponents;
  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    console.log('OPENING STUDIO CONTROL :: ', workflows);
  }, []);

  const selectControlComponent = (controlType: string) => {
    return controlType in controlComponents ? controlComponents[controlType] : () => {};
  };

  const createControlCard = (control: any) => {
    if (!control) return <>/</>;
    const ControlComponent = selectControlComponent(control.controlType);

    return (
      <>
        {control.visible && (
          <>
            <Card
              id={control.name}
              padding={'none'}
              style={{ backgroundColor: 'transparent', boxShadow: 'none', textAlign: 'left', marginBottom: '30px' }}
            >
              <ControlComponent control={control} bulkUpdateCallback={bulkUpdateCallback} />
            </Card>
          </>
        )}
      </>
    );
  };

  const styleSelected = (index: number) => {
    const style: any = {
      borderRadius: '8px',
      boxShadow: 'none',
      border: 'none',
      color: '#6F7B8F',
      height: '60px',
    };
    if (index === active) {
      style.color = '#6236FF';
      style.border = '1px';
      style.borderStyle = 'solid';
      style.borderColor = '#6236FF';
      style.height = '60px';
    }
    return style;
  };

  const titleStyleSelected = (index: number) => {
    const style: any = {
      color: '#6F7B8F',
    };
    if (index === active) {
      style.color = '#6236FF';
    }
    return style;
  };

  useEffect(() => {
    const element = document.getElementById(active.toString());
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });

    // document.body.style.overflow = 'hidden';
  }, []);

  const createControlBookmark = (workflow: any, index: any) => {
    const icons = [faSliders, faPenToSquare, faIcons, faVideo];
    return (
      <Button
        style={styleSelected(index)}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={(event) => handleMouseLeave(bothSide, event)}
        onClick={() => {
          console.log('CLICK ::: ', workflow, index);
          setActive(index);
          const element = document.getElementById(index.toString());
          element?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }}
      >
        <Icon size={'lg'} type={icons[index] as IconDefinition} />
      </Button>
    );
  };

  const StudioControlPanelWorkflows = (workflow: any, index: number) => {
    console.log(workflow);
    return (
      <>
        <div id={index.toString()} style={{ width: '100%' }}>
          <h3 style={titleStyleSelected(index)}>{workflow.workflowName}</h3>
          {workflow.controls.map((control: any, index: any) => {
            return createControlCard(control);
          })}
          <Divider />
        </div>
      </>
    );
  };

  const clickHandler = () => {
    console.log('Start generating');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '470px', marginLeft: '-60px' }}>
      <div style={{ textAlign: 'center', borderBottom: '5px solid black', borderTop: '1px solid black' }}>
        <Text
          style={{
            fontSize: '30px',
            lineHeight: '64px',
            fontWeight: '800',
            alignSelf: 'start',
            backgroundColor: '#b1bccc',
            color: '#091a36',
          }}
        >
          {'STUDIO'}
        </Text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '44px', width: '500px', alignItems: 'top' }}>
        <div style={{ width: '70px', backgroundColor: '#b1bccc', alignItems: 'start', paddingTop: '50px' }}>
          <div
            style={{
              flexDirection: 'column',
              marginTop: '0',
              gap: '44px',
              marginBottom: '44px',
              display: 'flex',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
          >
            {workflows.map((workflow: any, index: any) => {
              return createControlBookmark(workflow, index);
            })}
          </div>
        </div>
        <Section
          style={{
            color: '#232F3F',
            justifyContent: 'flex-start',
            width: '400px',
            minHeight: '90vh',
            backgroundColor: '#FAFAFA',
            padding: '0px',
            position: 'relative',
            maxHeight: '100vh',
            overflowY: 'scroll',
            paddingRight: '20px',
            paddingLeft: '20px',
          }}
        >
          <Stack style={{ flexDirection: 'column', marginTop: '6px', gap: '4px', marginBottom: '44px' }}>
            {workflows.length === 0 && <></>}
            {workflows.length > 0 &&
              workflows.map((workflow: any, index: any) => {
                return StudioControlPanelWorkflows(workflow, index);
              })}
            <StdButton style={{ marginBottom: '150px' }} disabled={false} clickHandler={clickHandler} text="Generate" fullscreen></StdButton>
          </Stack>
        </Section>
      </div>
    </div>
  );
};

export default StudioControlPanel;
