import { Button, Modal } from '@amzn/storm-ui';
import { FC, useContext } from 'react';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import styles from './ImageModalCloseConfrimPopup.module.scss';

export const TEST_ID_IMAGE_MODAL_CLOSE_CONFIRM_POPUP = 'image-modal-close-confirm-popup';

export const ImageModalCloseConfirmPopup: FC<{
  isOpen: boolean;
  onDiscard: () => void;
  onSave: () => void;
  onClose: () => void;
}> = ({ isOpen, onDiscard, onSave, onClose }) => {
  const { pendingGeneration } = useContext(ImageModalContext);

  return (
    <Modal
      modalElementId={'image-modal-close-confirm'}
      header={<div className={styles.header}>{pendingGeneration ? 'Images are generating' : 'Leave without selecting an image?'}</div>}
      footer={
        <div className={styles.footer}>
          <Button
            onClick={() => {
              onDiscard();
              onClose();
            }}
          >
            {pendingGeneration ? 'Leave' : 'Continue'}
          </Button>
          <Button
            primary
            className={styles.cta}
            onClick={() => {
              !pendingGeneration && onSave();
              onClose();
            }}
          >
            {pendingGeneration ? 'Wait for images' : 'Select image'}
          </Button>
        </div>
      }
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div data-testid={TEST_ID_IMAGE_MODAL_CLOSE_CONFIRM_POPUP} className={styles.content}>
        {pendingGeneration
          ? 'If you leave now, images will stop generating and your changes will be lost.'
          : 'If you leave without selecting an image, your image results will be deleted.'}
      </div>
    </Modal>
  );
};
