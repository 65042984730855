import { BatchResult, Feedback, WorkflowId } from '@amzn/genaihub-typescript-client';
import { Button, Icon } from '@amzn/storm-ui';
import { solidThumbsDown, solidThumbsUp, thumbsDown, thumbsUp } from '@amzn/storm-ui-icons';
import { download } from '@amzn/storm-ui-icons';
import React, { useContext, useEffect, useState } from 'react';
import FeedbackPopoverStorm from 'src/components/common/storm/FeedbackPopoverStorm';
import FeedbackAlert from 'src/components/editor/FeedbackAlert';
import styles from 'src/components/imageModal/styles.module.scss';
import { AddToAssetLibraryFailureNotification } from 'src/components/snackbar/notifications/AddToAssetLibraryNotifications';
import { DownloadContentFailureNotification } from 'src/components/snackbar/notifications/DownloadContentNotification';
import { SnackbarContext } from 'src/components/snackbar/SnackbarContext';
import { convertToBlobAndDownload } from 'src/components/utils/LinkUtils';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import useAssetLibrary from 'src/hooks/useAssetLibrary';
import stormStyles from './ImageViewerOverlay.module.scss';
import { AppContext, EditorContextP1 } from '../../../AppContext';

const ImageViewerOverlay = ({ url, type }: { url: string; type: string }) => {
  const [hoverIn, setHoverIn] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [openAlert, setOpenAlert] = useState<string>('');
  const [openFeedback, setOpenFeedback] = useState<boolean>(false);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const [saveToAssetLibraryInProgress, setSaveToAssetLibraryInProgress] = useState<boolean>(false);
  const useAssetLibraryHook = useAssetLibrary();
  const snackbarContext = useContext(SnackbarContext);
  const editorContext = useContext(EditorContextP1);
  const appContext = useContext(AppContext);
  const { selectedTool } = appContext;
  const genAIBackendClient = useAIBackendHubClient();
  const canSaveAsset = !!(appContext.accountType === 'external' && appContext.selectedAdvertisingAccount);

  useEffect(() => {
    if (url != null) {
      setSelectedIcon('');
      setOpenAlert('');
      setOpenFeedback(false);
    }
  }, [url]);

  const handleMouseEnter = () => {
    setHoverIn(true);
  };

  const handleMouseLeave = () => {
    setHoverIn(false);
  };

  const handleClick = (sentiment: string) => {
    if (selectedIcon !== sentiment) {
      setSelectedIcon(sentiment);
      setOpenAlert(sentiment);

      // Will refactor this later
      let feedback;
      if (sentiment === 'like') feedback = 'LIKE';
      else if (sentiment === 'dislike') feedback = 'DISLIKE';
      else feedback = 'NEUTRAL';

      const assetUrl = new URL(url);
      genAIBackendClient.submitFeedbackSentiment({
        body: {
          feedback: feedback as Feedback,
          workflowId: selectedTool as WorkflowId,
          assetUri: assetUrl.origin + assetUrl.pathname,
          contentCreationTime: 30.55, // TODO: get this from parent
          scope: 'ASSET',
        },
      });
    }
  };

  const closePopover = () => {
    setOpenFeedback(false);
    setHoverIn(false);
  };

  const handleAddComment = () => {
    setOpenFeedback(true);
    setOpenAlert('');
  };

  const handleDismiss = () => {
    setOpenAlert('');
  };

  const downloadAsset = async () => {
    try {
      setDownloadInProgress(true);
      await convertToBlobAndDownload(url);
    } catch (e) {
      console.error(e);
      snackbarContext.addFailureNotification({
        SnackbarContent: DownloadContentFailureNotification,
      });
    } finally {
      setDownloadInProgress(false);
    }
  };

  const LikeIcon = () => {
    return (
      <span>
        <Icon
          onClick={() => {
            handleClick('like');
          }}
          type={selectedIcon === 'like' ? solidThumbsUp : thumbsUp}
          size={'lg'}
          style={{
            cursor: 'pointer',
            color: type === 'video' ? '#8290A4' : 'white',
            float: 'left',
            marginRight: '10px',
          }}
        />
      </span>
    );
  };
  const DislikeIcon = () => {
    return (
      <span>
        <Icon
          onClick={() => {
            handleClick('dislike');
          }}
          type={selectedIcon === 'dislike' ? solidThumbsDown : thumbsDown}
          size={'lg'}
          style={{
            cursor: 'pointer',
            color: type === 'video' ? '#8290A4' : 'white',
            float: 'left',
          }}
        />
      </span>
    );
  };

  const saveToAssetLibraryButtonClickHandler = async () => {
    try {
      setSaveToAssetLibraryInProgress(true);

      const response: { body: BatchResult } = await genAIBackendClient.retrieveResultByWorkflowIdAndBatchId({
        workflowId: appContext.selectedTool as WorkflowId,
        batchId: editorContext.rawJobResponse?.body?.batchId || '',
      });
      const urls = response.body?.jobs?.reduce((acc: string[], curr) => acc.concat(curr.urls || []), [])?.map((url) => new URL(url));
      let newPresignedUrl;

      if (!urls || !url) {
        throw new Error('Url need to be a non-null value');
      }

      const urlObj = new URL(url);
      for (let tempUrl of urls) {
        if (urlObj.pathname == tempUrl.pathname) {
          newPresignedUrl = tempUrl.toString();
        }
      }

      if (!newPresignedUrl) {
        throw new Error("New presigned url can't be null");
      }

      await useAssetLibraryHook.saveAsset({
        assetUrl: newPresignedUrl,
        asin: editorContext.workflowOptions?.asin?.value,
      });
    } catch (e) {
      snackbarContext.addFailureNotification({
        SnackbarContent: AddToAssetLibraryFailureNotification,
      });
    } finally {
      setSaveToAssetLibraryInProgress(false);
    }
  };

  const FeedBackAlertComp = ({ icon, feedbackText }: { icon: () => React.JSX.Element; feedbackText: string }) => (
    <FeedbackAlert
      userFeedback={feedbackText}
      sentiment={openAlert}
      handleDismiss={handleDismiss}
      handleAddComment={handleAddComment}
      FeedbackIcon={icon}
      position={'bottom'}
    />
  );
  // console.log(type);
  return (
    <>
      {type === 'video' ? (
        <>
          <FeedbackPopoverStorm
            toggle={openFeedback}
            closePopover={closePopover}
            feedbackScope={'ASSET'}
            sentiment={selectedIcon === 'like' ? 'LIKE' : 'DISLIKE'}
            imageSrc={url}
            type={type}
          />

          <Stack style={{ flexDirection: 'row', width: '100%', marginTop: '18px' }}>
            <div>
              <FeedBackAlertComp icon={LikeIcon} feedbackText="like" />
            </div>
            <div style={{ flex: 9 }}>
              <FeedBackAlertComp icon={DislikeIcon} feedbackText="dislike" />
            </div>
            <Button onClick={downloadAsset} loading={downloadInProgress} loadingLabel="Downloading">
              <Icon type={download} /> Download
            </Button>
          </Stack>
        </>
      ) : (
        <div data-testid={'sandbox-generated-image-overlay'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FeedbackPopoverStorm
            toggle={openFeedback}
            closePopover={closePopover}
            feedbackScope={'ASSET'}
            sentiment={selectedIcon === 'like' ? 'LIKE' : 'DISLIKE'}
            imageSrc={url}
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#000000',
              opacity: hoverIn ? 0.4 : 0,
              position: 'absolute',
              borderRadius: '10px',
            }}
          ></div>
          <div
            style={{
              margin: '10px auto',
              padding: '0 20px',
              width: '100%',
              position: 'absolute',
              opacity: hoverIn ? 1 : 0,
            }}
          >
            <span className={stormStyles.FeedBackAlertComp} style={{ float: 'left' }}>
              <FeedBackAlertComp icon={LikeIcon} feedbackText="like" />
            </span>
            <span className={stormStyles.FeedBackAlertComp} style={{ float: 'left' }}>
              <FeedBackAlertComp icon={DislikeIcon} feedbackText="dislike" />
            </span>
            <div style={{ float: 'right', display: 'flex', gap: '10px' }}>
              <Button data-testid={'sandbox-module-download-button'} onClick={downloadAsset} loading={downloadInProgress} loadingLabel="Downloading">
                <Icon type={download} /> Download
              </Button>
              <Button
                data-testid={'sandbox-save-to-creative-assets-button'}
                primary={true}
                loading={saveToAssetLibraryInProgress}
                loadingLabel={'Saving'}
                disabled={!canSaveAsset}
                className={!saveToAssetLibraryInProgress && styles.saveButtons}
                onClick={saveToAssetLibraryButtonClickHandler}
              >
                Save to creative assets
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageViewerOverlay;
