import { WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';

export type WeblabResolver = (weblabName: string, weblabTreatment: WeblabTreatment) => boolean;

interface WeblabWrapperProps {
  children: any;
  weblabName: string;
  weblabTreatment: WeblabTreatment;
  weblabResolver?: WeblabResolver;
}

export function WeblabWrapper({ weblabName, weblabTreatment, children, weblabResolver = isWeblabInTreatment }: WeblabWrapperProps) {
  const weblabResult = weblabResolver(weblabName, weblabTreatment);
  return weblabResult ? children : null;
}
