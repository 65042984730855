import { Container, ImageList, ImageListItem, Popover } from '@mui/material';
import { useContext } from 'react';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../../AppContext';

interface ImaViewerProps {
  src: string;
  imageGallery?: any;
  cols?: number;
  openViewer: boolean;
  setOpenViewer: any;
  MultiViewComponent?: any;
}

const singleImage = (src: string) => (
  <ImageList gap={0} cols={1}>
    <ImageListItem rows={100}>
      <img srcSet={src} src={src} alt={src} style={{ maxHeight: '90vh' }} loading="lazy" />
    </ImageListItem>
  </ImageList>
);

const ImageViewer = (props: ImaViewerProps) => {
  const { openViewer, setOpenViewer, src, MultiViewComponent, imageGallery, cols } = props;
  const { metrics } = useContext(AppContext);

  const clickHandler = () => {
    console.log('clickHandler');
    metrics.trackMetrics(Metrics.Methods.InperationPreview, { [Metrics.Names.Action]: Metrics.Actions.Open }, { [Metrics.Counters.Clicks]: 1 });
    setOpenViewer(false);
  };

  const DisplayView = MultiViewComponent ? (
    <MultiViewComponent imageGallery={imageGallery} cols={cols} showPreview={true} previewImage={src} />
  ) : (
    singleImage(src)
  );

  return (
    <Popover
      open={openViewer}
      onClose={clickHandler}
      anchorReference={'none'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.8)',
      }}
    >
      <Container maxWidth={'xl'}>{DisplayView}</Container>
    </Popover>
  );
};
export default ImageViewer;
