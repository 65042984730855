import { ReactElement, useContext, useEffect, useState } from 'react';
import { ImageModalImageViewer } from 'src/components/imageModal/components/ImageModalImageViewer';
import { ReframeControls } from 'src/components/imageModal/components/ReframeControls/ReframeControls';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import Add from 'src/components/imageModal/ItemImages/insert.svg';
import Position from 'src/components/imageModal/ItemImages/position.svg';
import Reframe from 'src/components/imageModal/ItemImages/reframe.svg';
import Remix from 'src/components/imageModal/ItemImages/remix.svg';
import Remove from 'src/components/imageModal/ItemImages/remove.svg';
import Restyle from 'src/components/imageModal/ItemImages/restyle.svg';
import { SideBarItem } from 'src/components/imageModal/SideBarItem';
import { AICS_WEBLAB_EDIT_TAB_FAST_FOLLOW, AICS_WEBLAB_EDIT_TAB_POSITION_EDIT, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { AddRemoveControls } from './components/AddRemoveControls/AddRemoveControls';
import ImageModalStyleSetting from './components/ImageModalStyleSetting';
import { PositionControls } from './components/PositionControls/PositionControls';
import { RemixControls } from './components/RemixControls/RemixControls';
import styles from './ImageModalEditTab.module.scss';
import { WORKFLOW_TYPES } from './types';

export enum EditModes {
  RESTYLE = 'Restyle',
  POSITION = 'Position',
  REFRAME = 'Reframe',
  ADD = 'Add',
  REMOVE = 'Remove',
  REMIX = 'Remix',
}

export interface ImageModalEditTabProps {
  handleAcceptEdits: () => void;
  openConfirmModal: (callback: () => void) => void;
}

export const ImageModalEditTab = ({ handleAcceptEdits, openConfirmModal }: ImageModalEditTabProps) => {
  const [editMode, setEditMode] = useState<string>(EditModes.RESTYLE);
  const imageModalContext = useContext(ImageModalContext);
  const sourceImageUrl = imageModalContext.savedEditsImageUrl || imageModalContext.imageUrl;
  const [reframeAspectRatio, setReframeAspectRatio] = useState<string>();
  const [reframeDirection, setReframeDirection] = useState<string>();
  const enableEditTabFastFollow = isWeblabInTreatment(AICS_WEBLAB_EDIT_TAB_FAST_FOLLOW, WeblabTreatment.T1);
  const enableEditTabPosition = isWeblabInTreatment(AICS_WEBLAB_EDIT_TAB_POSITION_EDIT, WeblabTreatment.T1);
  const editModes: {
    text: string;
    icon: ReactElement;
    enabled?: boolean;
  }[] = [
    {
      text: EditModes.RESTYLE,
      icon: (
        <div data-id="restyle-icon">
          <Restyle />
        </div>
      ),
      enabled: true,
    },
    {
      text: EditModes.REMIX,
      icon: (
        <div data-id="remix-icon">
          <Remix />
        </div>
      ),
      enabled: enableEditTabFastFollow,
    },
    {
      text: EditModes.POSITION,
      icon: <Position />,
      enabled: enableEditTabPosition,
    },
    { text: EditModes.REFRAME, icon: <Reframe />, enabled: enableEditTabFastFollow },
    { text: EditModes.ADD, icon: <Add />, enabled: true },
    { text: EditModes.REMOVE, icon: <Remove />, enabled: true },
  ];

  const getSideBarItemControls = () => {
    switch (editMode) {
      case EditModes.RESTYLE:
        return <ImageModalStyleSetting />;
      // case EditModes.POSITION:
      //   return <div>Position</div>;
      case EditModes.REFRAME:
        return (
          <ReframeControls
            reframeAspectRatio={(reframeAspectRatio) => setReframeAspectRatio(reframeAspectRatio)}
            reframeDirection={(reframeDirection) => setReframeDirection(reframeDirection)}
          />
        );
      case EditModes.ADD:
        return <AddRemoveControls taskType={WORKFLOW_TYPES.INPAINTING} />;
      case EditModes.REMOVE:
        return <AddRemoveControls taskType={WORKFLOW_TYPES.REMOVAL} />;
      case EditModes.REMIX:
        return <RemixControls />;
      case EditModes.POSITION:
        return <PositionControls />;
      default:
        return <div>controls</div>;
    }
  };

  const handleChangeEditMode = (newEditMode: string | undefined) => {
    if (!newEditMode) {
      return;
    }
    if (imageModalContext.unsavedWork) {
      openConfirmModal(() => {
        imageModalContext.clearActiveEdit();
        setEditMode(newEditMode);
      });
    } else {
      imageModalContext.clearActiveEdit();
      setEditMode(newEditMode);
    }
  };

  useEffect(() => {
    imageModalContext.setEditMode(editMode);
  }, [editMode]);

  return (
    <div className={styles.imageEditTab} role={'tabpanel'} data-testid="Edit">
      <div className={styles.container}>
        {editModes
          .filter((mode) => mode.enabled)
          .map((item) => (
            <SideBarItem
              key={item.text}
              text={item.text}
              icon={item.icon}
              onClick={() => handleChangeEditMode(item.text)}
              active={editMode === item.text}
            />
          ))}
      </div>
      <div className={styles.main}>
        <div className={styles.controlsContainer}>{getSideBarItemControls()}</div>
        <div className={styles.imageContainer}>
          <ImageModalImageViewer
            sourceImageUrl={sourceImageUrl}
            handleAcceptEdits={handleAcceptEdits}
            reframeAspectRatio={reframeAspectRatio}
            reframeDirection={reframeDirection}
          />
        </div>
      </div>
    </div>
  );
};
