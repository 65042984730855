import { Button, Icon, Snackbar } from '@amzn/storm-ui';
import { times } from '@amzn/storm-ui-icons';
import { animated, useTransition, TransitionState } from '@react-spring/web';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { SnackbarContext, SnackbarNotification } from 'src/components/snackbar/SnackbarContext';
import styled from 'styled-components';
import styles from './SnackbarManager.module.scss';

const Badge = styled('div')`
  color: ${({ theme }) => theme.palette.white};
  background: ${({ theme }) => theme.palette.red[600]};
`;

export const SnackbarManagerTestIds = {
  ROOT: 'snackbar-manager-root',
  NOTIFICATION_ROOT_PREFIX: 'notification-root-',
  NOTIFICATION_SNACKBAR_PREFIX: 'notification-snackbar-',
  NOTIFICATION_CLOSE_BUTTON_PREFIX: 'notification-close-button-',
  NOTIFICATION_BADGE_PREFIX: 'notification-badge-',
};

export const generateTestIdForNotificationRoot = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_ROOT_PREFIX}${id}`;
export const generateTestIdForNotificationSnackbar = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_SNACKBAR_PREFIX}${id}`;
export const generateTestIdForNotificationCloseButton = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_CLOSE_BUTTON_PREFIX}${id}`;
export const generateTestIdForNotificationBadge = (id: string) => `${SnackbarManagerTestIds.NOTIFICATION_BADGE_PREFIX}${id}`;

export const SnackbarManager: FC = () => {
  const snackbarContext = useContext(SnackbarContext);
  const notifications = snackbarContext.notifications;

  const refMap = useMemo(() => new WeakMap(), []);
  const [items, setItems] = useState<SnackbarNotification[]>([]);

  const itemsTransition = useTransition(items, {
    from: { opacity: 0, maxHeight: 0, y: -40 },
    keys: (item) => item.id,
    enter: (item: SnackbarNotification) => async (next, cancel) => {
      await next({ opacity: 1, maxHeight: refMap.get(item).offsetHeight, y: 0 });
    },
    leave: { opacity: 0, maxHeight: 0, y: -40 },
    config: (item: SnackbarNotification, index: number, phase: TransitionState['phase']) => {
      return (key: string) => {
        return {
          duration: 500,
        };
      };
    },
  });

  useEffect(() => {
    if (notifications.length) {
      if (snackbarContext.expanded) {
        setItems([...notifications]);
      } else {
        setItems([notifications[notifications.length - 1]]);
      }
    } else {
      setItems([]);
    }
  }, [notifications, snackbarContext.expanded]);

  const notificationBadgeLabel = notifications.length > 9 ? '9+' : `${notifications.length}`;

  const onClickCloseButton = (notification: SnackbarNotification) => {
    if (!snackbarContext.expanded) {
      snackbarContext.removeAllNotifications();
    } else {
      snackbarContext.removeNotification(notification);
    }
  };

  return (
    <div data-testid={SnackbarManagerTestIds.ROOT} className={`${styles.snackbarManager} ${snackbarContext.expanded ? styles.expanded : ''}`}>
      <div className={styles.snackbarList}>
        {itemsTransition((style, item, t, index) => (
          <animated.div data-testid={generateTestIdForNotificationRoot(item.id)} className={styles.snackbarListItem} style={style}>
            <div className={styles.snackbarListItemContent} ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}>
              <Snackbar
                data-testid={generateTestIdForNotificationSnackbar(item.id)}
                id={`snackbar-manager-notification-${item.id}`}
                className={styles.snackbar}
                type={item.type}
                {...(index + 1 === items.length ? { onClick: () => snackbarContext.toggleNotifications() } : {})}
              >
                <item.SnackbarContent />
              </Snackbar>
              <div className={styles.spacer}></div>
              <Button
                data-testid={generateTestIdForNotificationCloseButton(item.id)}
                className={styles.closeButton}
                small={true}
                primary={true}
                transparent={false}
                type="button"
                onClick={() => onClickCloseButton(item)}
              >
                <Icon type={times} />
              </Button>
              {
                <Badge
                  data-testid={generateTestIdForNotificationBadge(item.id)}
                  className={`
                        ${styles.badge}
                        ${notifications.length > 1 ? styles.show : ''}
                      `}
                >
                  {notificationBadgeLabel}
                </Badge>
              }
            </div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};
