import { Children, forwardRef, isValidElement } from 'react';
import './css/FlexboxLayout.css';

const Stack = forwardRef<HTMLDivElement, any>((props: any, ref) => {
  const { className, children, ...rest } = props;
  //const Item = (item: any) => <div className="flex-item">{item}</div>;

  const RenderChild = (children: any) => {
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        return <child.type {...(child.props as object)} />;
      }
      return child;
    });
  };

  return (
    <div ref={ref} {...rest} className={className ? `${className} flex-container` : `flex-container`}>
      {RenderChild(children)}
    </div>
  );
});

export default Stack;
