import { Box, IconButton } from '@mui/material';
import { Button } from '@mui/material/';
import { useState, MouseEvent, useContext } from 'react';
import { Metrics } from 'src/constants';
import { AppContext } from '../../../AppContext';
import LightBulb from '../../icons/lightBulb.svg';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const AppBarMenu = (props: { showFeedbackButton?: boolean }) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const appContext = useContext(AppContext);
  const { metrics } = appContext;

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    metrics.trackMetrics(Metrics.Methods.UserIcon, { [Metrics.Names.Action]: Metrics.Actions.Open }, { [Metrics.Counters.Count]: 1 });
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    metrics.trackMetrics(Metrics.Methods.UserIcon, { [Metrics.Names.Action]: Metrics.Actions.Close }, { [Metrics.Counters.Count]: 1 });
    setAnchorElUser(null);
  };

  const handelUserMenuClick = (event: MouseEvent<HTMLElement>, id: string) => {
    metrics.trackMetrics(Metrics.Methods.UserMenuClick, { [Metrics.Names.ButtonId]: id }, { [Metrics.Counters.Clicks]: 1 });
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0, ml: '20px' }}>
      {props.showFeedbackButton && (
        <Button
          style={{
            backgroundColor: '#D9DEE4',
            color: 'black',
            padding: '6px 22px',
            marginRight: '25px',
            fontSize: '0.95rem',
            borderRadius: '100px',
          }}
          onClick={() => {}}
        >
          {' '}
          <LightBulb />
          &nbsp;&nbsp;Feedback
        </Button>
      )}
      {appContext.userDetails?.alias && (
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <img
            style={{
              borderRadius: '50%',
              width: '1.5em',
              height: '1.5em',
              objectFit: 'cover',
            }}
            src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${appContext.userDetails.alias}`}
          />
        </IconButton>
      )}
      {/*
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={(e) => handelUserMenuClick(e, setting)}>
            <Typography textAlign='center'>{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
      */}
    </Box>
  );
};
export default AppBarMenu;
