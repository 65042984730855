export default function (controlValues: any, activateGuide: boolean, callback: Function) {
  if (activateGuide) {
    const cv: any = controlValues.reduce((acc: any, cv: any, index: number) => {
      if (cv.guide) {
        acc = { ...cv };
      }
      return acc;
    }, {});
    if (cv?.guide) {
      callback(cv);
    } else {
      callback(undefined);
    }
  }
}
