import { ThemeProvider } from '@amzn/storm-ui';
import _cloneDeep from 'lodash/cloneDeep';
import { useRef, useState, useEffect } from 'react';
import ContentTilesContainer from 'src/components/pages/studio/contentTile/ContentTilesContainer';
import { EffectOption } from 'src/components/pages/studio/effectSettings/effectSettings';
import EffectsSetting from 'src/components/pages/studio/effectSettings/EffectsSetting';
import {
  ASINItem,
  ContentItem,
  JobStatus,
  StudioContext,
  StudioContextType,
  StudioInputSettings,
  StyleItem,
} from 'src/components/pages/studio/StudioContext';
import StyleSetting from 'src/components/pages/studio/StyleSetting';
import UserInputContainer, { DEFAULT_FORMAT, UserInputContainerRef } from 'src/components/pages/studio/UserInputContainer';
import { SnackbarContextProvider } from 'src/components/snackbar/SnackbarContext';
import { SnackbarManager } from 'src/components/snackbar/SnackbarManager';
import { getAllQueryStringParams } from 'src/components/utils/getQueryStringParams';
import { US_MARKETPLACE_ID } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import SliderIcon from 'src/icons/SliderIcon.svg';
import styles from './StudioPage.module.scss';

const StudioPage = () => {
  const [creativeTypes, setCreativeTypes] = useState<string[]>();
  const [format, setFormat] = useState<string>(DEFAULT_FORMAT.id);
  const [style, setStyle] = useState<StyleItem>();
  const [effects, setEffects] = useState<Map<string, EffectOption>>();
  const [asin, setAsin] = useState<ASINItem>();
  const [textPrompt, setTextPrompt] = useState<string>();
  const [jobStatus, setJobStatus] = useState<JobStatus>();
  const [results, setResults] = useState<ContentItem[]>([]);
  const [placeholders, setPlaceholders] = useState<ContentItem[]>();
  const [autoGenerate, setAutoGenerate] = useState<boolean>(false);
  const [negativePrompt, setNegativePrompt] = useState<string>();
  const [seed, setSeed] = useState<string>();
  const [temperature, setTemperature] = useState<number>();
  const [enablePromptRewriting, setEnablePromptRewriting] = useState(false);
  const [enableAdvancedMode, setEnableAdvancedMode] = useState(false);
  const [showPanel, toggleSidePanel] = useState<boolean>(false);
  const userInputContainerRef = useRef<UserInputContainerRef>(null);
  const genAIBackendClient = useAIBackendHubClient();

  const clearPlaceholders = () => {
    setPlaceholders([]);
  };

  const clearResults = () => {
    setResults([]);
  };

  const prependResults = (newResults: ContentItem[]) => {
    setResults((prevResults: ContentItem[]) => [...(newResults || []), ...(prevResults || [])]);
  };

  const appendResults = (newResults: ContentItem[]) => {
    setResults((prevResults: ContentItem[]) => [...(prevResults || []), ...(newResults || [])]);
  };

  const removeResult = (assetToDelete: string) => {
    const newResults = results?.filter((item) => item.referenceId !== assetToDelete);
    setResults(newResults);
  };

  const updateResult = (updatedResult: ContentItem) => {
    const newResults = results?.map((result) => (result.referenceId === updatedResult.referenceId ? updatedResult : result));
    setResults(newResults);
  };

  const handleReuseSettings = (props: { studioInputSettings: StudioInputSettings }) => {
    /* eslint-disable-next-line react/prop-types */
    const { studioInputSettings } = props;

    /* eslint-disable-next-line react/prop-types */
    setAsin(_cloneDeep(studioInputSettings.asin));
    /* eslint-disable-next-line react/prop-types */
    setEffects(_cloneDeep(studioInputSettings.effects));
    /* eslint-disable-next-line react/prop-types */
    setFormat(studioInputSettings.format || DEFAULT_FORMAT.id);
    /* eslint-disable-next-line react/prop-types */
    setStyle(_cloneDeep(studioInputSettings.style));
    /* eslint-disable-next-line react/prop-types */
    setTextPrompt(studioInputSettings.prompt);
    /* eslint-disable-next-line react/prop-types */
    userInputContainerRef.current?.setPrompt(studioInputSettings.prompt);

    /* eslint-disable-next-line react/prop-types */
    if (studioInputSettings.advanced) {
      /* eslint-disable-next-line react/prop-types */
      setNegativePrompt(studioInputSettings.advanced.negativePrompt);
      /* eslint-disable-next-line react/prop-types */
      setSeed(studioInputSettings.advanced.seed);
      /* eslint-disable-next-line react/prop-types */
      setTemperature(studioInputSettings.advanced.temperature);
      setEnableAdvancedMode(true);
    } else {
      setNegativePrompt(undefined);
      setSeed(undefined);
      setTemperature(undefined);
      setEnableAdvancedMode(false);
    }

    /* eslint-disable-next-line react/prop-types */
    if (studioInputSettings.enablePromptRewriting) {
      setEnablePromptRewriting(true);
    } else {
      setEnablePromptRewriting(false);
    }
  };

  const studioContext: StudioContextType = {
    placeholders,
    setPlaceholders,
    // Disabling this option for now since we don't support multiple generations in parallel
    // appendPlaceholders,
    clearPlaceholders,
    creativeTypes,
    setCreativeTypes,
    format,
    setFormat,
    style,
    setStyle,
    effects,
    setEffects,
    asin,
    setAsin,
    textPrompt,
    setTextPrompt,
    jobStatus,
    setJobStatus,
    results,
    prependResults,
    appendResults,
    clearResults,
    removeResult,
    updateResult,
    autoGenerate,
    setAutoGenerate,
    negativePrompt,
    setNegativePrompt,
    seed,
    setSeed,
    temperature,
    setTemperature,
    enableAdvancedMode,
    setEnableAdvancedMode,
    enablePromptRewriting,
    setEnablePromptRewriting,
    handleReuseSettings,
  };

  useEffect(() => {
    const urlProps = getAllQueryStringParams();
    if (urlProps.generate && (urlProps.prompt || urlProps.ASIN)) {
      setAutoGenerate(true);
      if (urlProps.prompt) {
        setTextPrompt(urlProps.prompt);
      } else if (urlProps.ASIN) {
        fetchASIN(urlProps.ASIN);
      }
      history.replaceState({}, document.title, window.location.origin + window.location.pathname);
    }
  }, []);

  const fetchASIN = async (asin: string) => {
    try {
      const fetchedAsin = (
        await genAIBackendClient.retrieveASINMetadataByASINId({
          asinId: asin,
          marketplaceId: US_MARKETPLACE_ID,
        })
      ).body;
      const newAsinItem: ASINItem = {
        asin: asin,
        metadata: fetchedAsin,
        selectedImageIndex: 0,
      };
      setAsin(newAsinItem);
      setAutoGenerate(false);
    } catch (err) {
      console.error(err);
      console.log('fail to load ASIN from Hero');
      setAutoGenerate(false);
    }
  };

  return (
    <StudioContext.Provider value={studioContext}>
      <SnackbarContextProvider>
        <ThemeProvider>
          <div className={styles.pageContainer} data-testid="studio-container">
            <div className={`${styles.panel} ${showPanel ? styles.show : ''}`} data-testid="studio-style-container">
              <div className={styles.panelToggle}>
                <div
                  data-testid="studio-style-container-toggle-button"
                  role="button"
                  className={styles.panelToggleButton}
                  onClick={() => toggleSidePanel((v) => !v)}
                >
                  <SliderIcon />
                </div>
              </div>
              <StyleSetting />
              <div style={{ height: '30px' }}></div>
              <EffectsSetting />
            </div>
            <div className={styles.canvas} data-testid="studio-canvas-container">
              <ContentTilesContainer />
              <div className={styles.userInputs} data-testid="studio-inputs-container">
                <UserInputContainer ref={userInputContainerRef} />
              </div>
            </div>
          </div>
          <SnackbarManager></SnackbarManager>
        </ThemeProvider>
      </SnackbarContextProvider>
    </StudioContext.Provider>
  );
};

export default StudioPage;
