import React, { createContext, useEffect, useRef, useState } from 'react';
import { v6 as uuidV6 } from 'uuid';

export enum SnackbarType {
  ERROR = 'error',
  INFO = 'info',
  LOADING = 'loading',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface SnackbarNotification {
  id: string;
  disableAutoDismissal?: boolean;
  type: SnackbarType;
  SnackbarContent: React.FC;
}

export interface SnackbarContextType {
  expanded: boolean;
  notifications: SnackbarNotification[];
  addNotification: (notification: SnackbarNotification) => void;
  addFailureNotification: (props: { SnackbarContent: SnackbarNotification['SnackbarContent'] }) => void;
  addProcessingNotification: (props: { SnackbarContent: SnackbarNotification['SnackbarContent'] }) => () => void;
  addSuccessNotification: (props: { SnackbarContent: SnackbarNotification['SnackbarContent'] }) => void;
  removeNotification: (notification: SnackbarNotification | string) => void;
  removeAllNotifications: () => void;
  toggleNotifications: () => void;
  expandNotifications: () => void;
  collapseNotifications: () => void;
}

interface Timeouts {
  [key: string]: {
    id: ReturnType<typeof setTimeout>;
    readyToClear: boolean;
  };
}

export const PROCESSING_TIMEOUT = 3000;
export const SNACKBAR_DISMISSAL_TIMEOUT = 5000;

export const SnackbarContext = createContext({} as SnackbarContextType);

export const SnackbarContextProvider = (props: { children: React.ReactNode; dismissalTimeout?: number }) => {
  const { dismissalTimeout = SNACKBAR_DISMISSAL_TIMEOUT } = props;

  const expandedRef = useRef<boolean>(false);
  const timeoutsRef = useRef<Timeouts>({});
  const [expanded, setExpanded] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<SnackbarNotification[]>([]);

  const addNotification = (notification: SnackbarNotification) => {
    if (notifications.findIndex((n) => n.id === notification.id) >= 0) {
      return console.error(`A notification already exists with id '${notification.id}'`);
    }

    setNotifications((value: SnackbarNotification[]) => {
      return value.concat(notification);
    });

    if (!notification.disableAutoDismissal) {
      timeoutsRef.current[notification.id] = {
        readyToClear: false,
        id: setTimeout(() => {
          if (expandedRef.current) {
            timeoutsRef.current[notification.id].readyToClear = true;
          } else {
            removeNotification(notification);
          }
        }, dismissalTimeout),
      };
    }
  };

  // Convenience functions for addNotification
  const addProcessingNotification = (props: { SnackbarContent: SnackbarNotification['SnackbarContent'] }) => {
    const processingNotification = {
      id: uuidV6(),
      disableAutoDismissal: true,
      type: SnackbarType.LOADING,
      ...props,
    };

    const processingTimeout = setTimeout(() => {
      snackbarContext.addNotification(processingNotification);
    }, PROCESSING_TIMEOUT);

    // returns a cleanup function
    return () => {
      clearTimeout(processingTimeout);
      removeNotification(processingNotification);
    };
  };

  const addFailureNotification = (props: { SnackbarContent: SnackbarNotification['SnackbarContent'] }) => {
    const failureNotification = {
      id: uuidV6(),
      type: SnackbarType.ERROR,
      ...props,
    };

    addNotification(failureNotification);
  };

  const addSuccessNotification = (props: { SnackbarContent: SnackbarNotification['SnackbarContent'] }) => {
    const successNotification = {
      id: uuidV6(),
      type: SnackbarType.SUCCESS,
      ...props,
    };

    addNotification(successNotification);
  };

  const removeNotification = (notification: SnackbarNotification | string) => {
    const notificationId = typeof notification === 'string' ? notification : notification.id;
    if (timeoutsRef.current[notificationId]?.id) {
      clearTimeout(timeoutsRef.current[notificationId].id);
    }
    delete timeoutsRef.current[notificationId];
    setNotifications((notifications: SnackbarNotification[]) => {
      return notifications.filter((n) => n.id !== notificationId);
    });
  };

  const removeAllNotifications = () => {
    collapseNotifications();
    setNotifications([]);
  };

  const collapseNotifications = () => {
    setExpanded(false);
  };

  const expandNotifications = () => {
    setExpanded(true);
  };

  const toggleNotifications = () => {
    setExpanded((value) => !value);
  };

  useEffect(() => {
    if (!notifications.length) {
      collapseNotifications();
    }
  }, [notifications]);

  useEffect(() => {
    expandedRef.current = expanded;

    if (!expanded) {
      for (const notificationId in timeoutsRef.current) {
        const timeout = timeoutsRef.current[notificationId];
        if (timeout.readyToClear) {
          removeNotification(notificationId);
        }
      }
    }
  }, [expanded]);

  const snackbarContext = {
    expanded,
    notifications,
    addNotification,
    addFailureNotification,
    addProcessingNotification,
    addSuccessNotification,
    removeNotification,
    removeAllNotifications,
    expandNotifications,
    collapseNotifications,
    toggleNotifications,
  };

  return <SnackbarContext.Provider value={snackbarContext}>{props.children}</SnackbarContext.Provider>;
};
