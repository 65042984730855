import { Inspirations } from '@amzn/genaihub-typescript-client';
import { Grid } from '@mui/material';
import { Button, Container } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignIn from 'src/components/signin/Signin';
import { home } from 'src/config';
import styleGuide from 'src/config/themes/styleGuide';
import { Metrics } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { AppContext } from '../../../AppContext';
import { inspirations, tools } from '../../config';
import MasonryImageList from '../common/mui/ImageList';
import AIModel from '../layout/mui/AIModel';
import AIModelSkeleton from '../layout/mui/AIModelSkeleton';
import HeadedContainer from '../layout/mui/HeadedContainer';
import MainFeature from '../layout/mui/MainFeature';
import { getPageName } from '../utils/getPageName';

const Home = () => {
  const genAIBackendClient = useAIBackendHubClient();
  const context = useContext(AppContext);
  const { metrics } = context;
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const [inspirationsList, setInpirationsList] = useState<Inspirations>();
  const linkTo = useNavigate();

  const handleClickEvent = (page: string) => {
    metrics.trackMetrics(
      Metrics.Methods.HomePage,
      { [Metrics.Names.ButtonId]: Metrics.Buttons.ViewAll, [Metrics.Names.Destination]: getPageName(page) },
      { [Metrics.Counters.Clicks]: 1 },
    );
    linkTo(page);
  };

  const isAuthenticated = () => {
    setShowSignInDialog(true);
    return false;
  };
  const modelCards = context.authenticated ? 3 : 2;
  const gridColumns = context.authenticated ? 4 : 6;
  const skeletonCards = [1, 2, 3];

  const renderCards = () => {
    let cards = [];
    if (!context.tools.length) {
      for (var i = 0; i < modelCards; i++) {
        cards.push(<AIModelSkeleton key={`skeleton${i}`} gridColumns={gridColumns} />);
      }
    } else {
      cards = context.tools
        .sort((a: any, b: any) => a.order - b.order)
        .slice(0, modelCards)
        .map((item: any, index: number) => {
          return <AIModel key={index} post={item} gridColumns={gridColumns} isAuthenticated={isAuthenticated} />;
        });
    }
    return cards;
  };

  useEffect(() => {
    getInspirations();
  }, []);

  const getInspirations = async () => {
    const response = await genAIBackendClient.retrieveInspirationsList({});
    setInpirationsList(response.body.inspirations);
  };

  return (
    <>
      <MainFeature post={home} />
      <Container maxWidth={false} id={'homepage'} className="home-content-sizing" sx={{ pt: 8 }}>
        <HeadedContainer data-testid="homepage-tools-container" config={tools}>
          <Grid data-testid="homepage-tools-grid" container spacing={4}>
            {renderCards()}
          </Grid>
        </HeadedContainer>
        <Container maxWidth={'xl'} sx={{ textAlign: 'center', mt: '3em', mb: '3em', marginBottom: '160px' }}>
          {context.authenticated && (
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              data-testid="homepage-tools-view-all"
              onClick={() => {
                handleClickEvent('./play');
              }}
              sx={{ ...styleGuide.secondaryButtonHover }}
            >
              View all
            </Button>
          )}
        </Container>
        <HeadedContainer data-testid="homepage-inspiration-container" config={inspirations}>
          {/*
					<Grid container spacing={2} gridAutoFlow={'dense'}>
					{imageGallery.slice(0, 4).map((item, index) => {
						return <Gallery key={index} image={item} />;
					})}
				</Grid>
				*/}
          <MasonryImageList
            data-testid="homepage-masonry-list"
            imageGallery={
              inspirationsList?.slice(0, 11).map((item: any) => ({
                src: item.assetUri ? item.assetUri : item.uri ? item.uri : '',
              })) ?? []
            }
            cols={6}
          />
        </HeadedContainer>
        <Container maxWidth="xl" sx={{ textAlign: 'center', mt: '3em', mb: '3em', marginTop: '15px', marginBottom: '80px' }}>
          {context.authenticated && (
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              data-testid="homepage-inspiration-view-all"
              onClick={() => {
                handleClickEvent('./browse');
              }}
              sx={{ ...styleGuide.secondaryButtonHover }}
            >
              View all images
            </Button>
          )}
        </Container>
        <SignIn open={showSignInDialog} handleClose={() => setShowSignInDialog(false)} />
      </Container>
    </>
  );
};

export default Home;
