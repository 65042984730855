import { Autocomplete, TextField } from '@mui/material';
import { useState, useContext } from 'react';
import { Metrics } from 'src/constants';
import { AppContext, EditorContext } from '../../../../../../AppContext';

const DropDown = ({ control }: any) => {
  const { controlLabel, controlName, controlValues, defaultValue } = control;
  const context = useContext(EditorContext);
  const { metrics } = useContext(AppContext);
  const [label, setLabel] = useState<string>(controlLabel);
  const themesList = controlValues.map((item: any) => item.value);
  const [selected, setSelected] = useState<string>();

  const onChangeHandler = (event: any) => {
    const value = event.target.textContent;

    metrics.trackMetrics(
      Metrics.Methods.DropDown,
      {
        [Metrics.Names.Selected]: value,
        ...(selected && selected !== '' && { [Metrics.Names.Unselected]: selected }),
      },
      { [Metrics.Counters.Count]: 1 },
    );

    event.target.textContent.length > 0 ? setLabel('') : setLabel(controlLabel);
    setSelected(event.target.textContent);

    const obj: Model2 = {};
    obj[controlName] = { value: event.target.textContent, type: 'text' };

    context.setWorkFlowOptions({ ...context.workflowOptions, ...obj });
  };

  return (
    <div style={{ padding: '16px' }}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={themesList}
        sx={{ width: 250 }}
        onChange={onChangeHandler}
        renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: false }} label={label} />}
      />
    </div>
  );
};

export default DropDown;
