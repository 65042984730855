import _cloneDeep from 'lodash/cloneDeep';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FeedbackContext, FeedbackScope, Feedback, useFeedbackContext, SubmittedFeedback } from 'src/components/feedback/FeedbackContext';
import { FeedbackThumbsControl } from 'src/components/feedback/FeedbackThumbsControl';
import { LoadedImage, getImageFromUrl } from 'src/components/utils/ImageUtils';
import styles from './ImageWithThumbnails.module.scss';
import { LoadingAnimation } from './LoadingAnimation';
import { WorkflowId } from '../imageModal/components/utils';

export enum ImageStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
}

export interface Image {
  originalUrl: string;
  referenceId: string;
  url: string;
}

export interface ImageWithThumbnailsRef {
  setImages: (images: Image[]) => void;
}

export interface ImageWithThumbnailsProps {
  onChangeIsLoading?: (isLoading: boolean) => void;
  onChangeSelectedImage?: (selectedImage: ImageState | undefined) => void;
  onSubmittedFeedback?: (submittedFeedback: SubmittedFeedback) => void;
}

export interface ImageState {
  status: ImageStatus;
  image: Image;
  feedback?: Feedback;
  loadedImage?: LoadedImage;
}

const DEFAULT_ASPECT_RATIO = 1.91;
const SPACE_ASPECT_RATIO = 100 / 70;

export const ImageWithThumbnails = forwardRef<ImageWithThumbnailsRef, ImageWithThumbnailsProps>((props, ref) => {
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const [loadingImageWidth, setLoadingImageWidth] = useState<string>('');
  const [loadingImageHeight, setLoadingImageHeight] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [imageStates, setImageStates] = useState<ImageState[]>([]);

  const feedbackContext = useFeedbackContext({
    feedbackScope: FeedbackScope.ASSET,
    onSubmittedFeedback: (submittedFeedback) => {
      // Assign submitted feedback to the image
      if (submittedFeedback) {
        setImageStates((value: ImageState[]) =>
          value.map((imageState) =>
            imageState.image.referenceId === submittedFeedback.feedbackMetadata?.referenceId
              ? {
                  ...imageState,
                  feedback: _cloneDeep(submittedFeedback.feedback),
                }
              : imageState,
          ),
        );
        props.onSubmittedFeedback?.(submittedFeedback);
      }
    },
  });

  useImperativeHandle(ref, () => ({
    setImages,
  }));

  useEffect(() => {
    props.onChangeIsLoading?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!images?.length) {
      setIsLoading(false);
      setImageStates([]);
      setSelectedImageIndex(-1);
      return;
    }

    let apply = true;
    let aspectRatioSet = false;

    const newImageStates: ImageState[] = (images || []).map((image: Image) => ({
      image: { ...image },
      status: ImageStatus.LOADING,
    }));

    const updateImageState = (updatedImageState: ImageState) => {
      if (!apply) return;
      setImageStates((value) =>
        value.map((imageState) => (imageState.image.referenceId === updatedImageState.image.referenceId ? updatedImageState : imageState)),
      );
    };

    const updateAspectRatio = (props: { width: number; height: number }) => {
      if (!apply) return;
      if (!aspectRatioSet) {
        aspectRatioSet = true;
        const newAspectRatio = props.width / props.height;
        let newLoadingImageWidth, newLoadingImageHeight;
        if (newAspectRatio < SPACE_ASPECT_RATIO) {
          newLoadingImageWidth = '100%';
          newLoadingImageHeight = `${100 * (1 / newAspectRatio)}%`;
        } else {
          newLoadingImageWidth = `${100 * newAspectRatio}%`;
          newLoadingImageHeight = '100%';
        }
        setLoadingImageWidth(newLoadingImageWidth);
        setLoadingImageHeight(newLoadingImageHeight);
        setAspectRatio(props.width / props.height);
      }
    };

    const fetchImages = async () => {
      await Promise.all(newImageStates.map((imageState) => fetchImage(imageState)));
      if (apply) {
        setIsLoading(false);
      }
    };

    const fetchImage = async (imageState: ImageState) => {
      try {
        const loadedImage = await getImageFromUrl({
          url: imageState.image.url,
          onFetchDimensions: updateAspectRatio,
        });
        updateAspectRatio({
          width: loadedImage.width,
          height: loadedImage.height,
        });
        updateImageState({
          ...imageState,
          loadedImage,
          status: ImageStatus.READY,
        });
      } catch (error) {
        console.error('Failed to load image', error);
        updateImageState({
          ...imageState,
          status: ImageStatus.ERROR,
        });
      }
    };

    setImageStates(newImageStates);
    setSelectedImageIndex(0);
    setIsLoading(true);
    fetchImages();

    // cancel application of this useEffect instance's fetchImage if useEffect cleaned up.
    return () => {
      apply = false;
    };
  }, [images]);

  useEffect(() => {
    if (feedbackContext.isFeedbackPopoverOpen) {
      feedbackContext.closeAndSubmitFeedback();
    }

    if (selectedImageIndex >= 0 && selectedImageIndex < imageStates.length) {
      const imageState = imageStates[selectedImageIndex];
      feedbackContext.setFeedbackComment(imageState.feedback?.comment);
      feedbackContext.setFeedbackSentiment(imageState.feedback?.sentiment);
      feedbackContext.setFeedbackMetadata({
        assetUrl: imageState.image.originalUrl,
        prompt: undefined,
        referenceId: imageState.image.referenceId,
        workflowId: WorkflowId.IMAGE_EDITOR,
      });
      props.onChangeSelectedImage?.(imageState);
    } else {
      feedbackContext.setFeedbackComment(undefined);
      feedbackContext.setFeedbackSentiment(undefined);
      feedbackContext.setFeedbackMetadata(undefined);
      props.onChangeSelectedImage?.(undefined);
    }
  }, [selectedImageIndex]);

  const onClickThumbnail = (index: number) => {
    if (!isLoading) {
      setSelectedImageIndex(index);
    }
  };

  return (
    <FeedbackContext.Provider value={feedbackContext}>
      <div className={`${styles.imageWithThumbnails}`}>
        <div className={`${styles.selectedImage}`}>
          <div
            className={`${styles.imgWrapper} ${!isLoading ? styles.show : ''} ${feedbackContext.isFeedbackPopoverOpen ? styles.feedbackPopoverOpen : ''}`}
          >
            {imageStates && imageStates[selectedImageIndex] && imageStates[selectedImageIndex].status === ImageStatus.READY ? (
              <img src={imageStates[selectedImageIndex].image.url} />
            ) : (
              <></>
            )}
            <div className={styles.overlay}>
              <FeedbackThumbsControl />
            </div>
          </div>
          <LoadingAnimation
            className={`${styles.loadingAnimation} ${isLoading ? styles.show : ''}`}
            primaryMessage="Loading"
            style={{
              width: loadingImageWidth || '100%',
              height: loadingImageHeight || '100%',
            }}
          />
        </div>
        <div
          className={`${styles.thumbnails}`}
          style={{
            minHeight: '60px',
            maxHeight: `clamp(60px, ${(1 / (aspectRatio || 1.91)) * 80}px, 20% - 20px)`,
          }}
        >
          {imageStates.map((imageState, index) => (
            <div
              key={imageState.image.referenceId}
              className={`${styles.thumbnail} ${selectedImageIndex === index ? styles.selected : ''}`}
              onClick={() => onClickThumbnail(index)}
            >
              <div className={`${styles.imgWrapper} ${!isLoading ? styles.show : ''}`}>
                {imageState.status === ImageStatus.READY ? <img src={imageState.image.url} /> : <></>}
              </div>
              <LoadingAnimation className={`${styles.loadingAnimation} ${isLoading ? styles.show : ''}`} />
            </div>
          ))}
        </div>
      </div>
    </FeedbackContext.Provider>
  );
});
