export const FAILURE_TEXT = 'Sorry, something went wrong. Please try again later.';
export const PROCESSING_TEXT = 'This is taking longer than expected. To delete your image, wait a little longer or try again later.';

export const DeleteAssetProcessingNotification = () => {
  return <>{PROCESSING_TEXT}</>;
};

export const DeleteAssetFailureNotification = () => {
  return <>{FAILURE_TEXT}</>;
};
