import { Card, Link, Section, Text } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import ImageViewerOverlay from 'src/components/editor/ImageViewerOverlay';
import Stack from 'src/customUIComponents/Stack';
import { EditorContextP1 } from '../../../AppContext';

const ImageViewer = (props: { ids: string[]; urls: string[]; children: any; textUrls: string[] }) => {
  const [selectedImage, setSelectedImage] = useState<string>();
  const [selectedText, setSelectedText] = useState<string>();
  const [loaded, setLoaded] = useState(false);
  const { ids, urls, children, textUrls } = props;

  const editorContext = useContext(EditorContextP1);

  useEffect(() => {
    if (urls.length > 0 && selectedImage === undefined) {
      setSelectedImage(urls[0]);
    }
  }, [urls]);

  useEffect(() => {
    if (textUrls && textUrls.length > 0) {
      setSelectedText(textUrls[0]);
    }
  }, [textUrls]);

  const handleLoad = () => {
    setLoaded(true);
  };

  const handleClickEvent = (event: any, index: number) => {
    setSelectedImage(event.target.src);
  };

  const clickHandler = () => {
    const controlValues = editorContext.workflowOptions;
    const controlData: any = {};
    controlData['prompt'] = { value: selectedText };
    controlData['rewriteUserCustomPrompt'] = { value: 'false' };
    editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
  };

  useEffect(() => {
    const hasResults = urls && urls.length > 0;
    if (!hasResults) {
      return;
    }
    setSelectedImage(urls[0]);
  }, [urls]);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100%' }}>
        <Stack style={{ flexDirection: 'column', gap: '20px', flexWrap: 'nowrap', justifyContent: 'center', marginTop: '20px' }}>
          <Section style={{ background: 'transparent', padding: '0px' }}>
            <Card padding={0} style={{ maxHeight: '60vh', width: 'auto', position: 'relative', borderRadius: '10px' }}>
              {selectedImage ? (
                <>
                  <ImageViewerOverlay url={selectedImage} type="image" />
                  <img
                    data-testid={'sandbox-image-viewer'}
                    onLoad={handleLoad}
                    src={selectedImage}
                    style={{ maxHeight: '60vh', width: '100%', display: loaded ? 'block' : 'none', borderRadius: '10px' }}
                  />
                </>
              ) : (
                <></>
              )}
            </Card>
            {selectedText ? (
              <div style={{ maxWidth: '700px', width: 'auto', marginTop: '20px' }}>
                <Text style={{ color: '#232F3F', fontWeight: '400', fontStyle: 'italic' }} fontSize={'base'}>
                  {selectedText}
                  <Link style={{ width: '130px', color: '#4305F4', textDecoration: 'none', cursor: 'pointer' }} onClick={clickHandler}>
                    {' '}
                    Edit this prompt
                  </Link>
                </Text>
              </div>
            ) : (
              <></>
            )}
          </Section>

          {urls && urls.length > 1 && (
            <Section className="results-thumbnails" style={{ background: 'transparent', padding: '0' }}>
              <Stack style={{ gap: '8px', width: 'auto', display: 'flex', flexDirection: 'row', margin: '0 auto' }}>
                {urls.map((item: any, index: number) => {
                  return (
                    <Card
                      key={'imageViewer' + index}
                      padding={0}
                      style={{
                        overflow: 'hidden',
                        width: 'auto',
                        borderRadius: '8px',
                        border: `1px solid ${item === selectedImage ? '#4305F4' : '#B8BFCB'}`,
                        boxShadow: '0px 2px 4px 0px #232F3F26',
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        data-testid={'sandbox-image-thumbnail'}
                        id={(ids && ids[index]) || 'id' + index}
                        src={item}
                        style={{ display: 'block', padding: '0', objectFit: 'cover', borderRadius: '10px', margin: '6px' }}
                        onClick={(e) => {
                          handleClickEvent(e, index);
                        }}
                        width="80"
                        height="80"
                      />
                    </Card>
                  );
                })}
              </Stack>
            </Section>
          )}
          {children}
        </Stack>
      </div>
    </>
  );
};

export default ImageViewer;
