import { UserSentiment, WorkflowId } from '@amzn/genaihub-typescript-client';
import { useMutation } from '@tanstack/react-query';
import _cloneDeep from 'lodash/cloneDeep';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  SubmitFeedbackProcessingNotification,
  SubmitFeedbackFailureNotification,
  SubmitFeedbackSuccessNotification,
} from 'src/components/snackbar/notifications/SubmitFeedbackNotifications';
import { SnackbarContext } from 'src/components/snackbar/SnackbarContext';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';

export enum FeedbackSentiment {
  DISLIKE = 'DISLIKE',
  NEUTRAL = 'NEUTRAL',
  LIKE = 'LIKE',
}

export enum FeedbackScope {
  ASSET = 'ASSET',
  MODULE = 'MODULE',
  OTHER = 'OTHER',
}

export interface AssetFeedbackMetadata {
  workflowId: WorkflowId | undefined;
  assetUrl: string | undefined;
  prompt: string | undefined;
  referenceId: string | undefined;
}

export interface ModuleFeedbackMetadata {
  workflowId: WorkflowId | undefined;
  assetUrl?: undefined;
  prompt?: undefined;
  referenceId?: undefined;
}

export interface OtherFeedbackMetadata {
  workflowId: WorkflowId | undefined;
  assetUrl?: undefined;
  prompt?: undefined;
  referenceId?: undefined;
}

// For FeedbackMetadat, properties with "undefined" types forces typescript to recognize the properties without having to write ("prop" in props) before accessing or having to cast
export type FeedbackMetadata = AssetFeedbackMetadata | ModuleFeedbackMetadata | OtherFeedbackMetadata;

export interface Feedback {
  sentiment: FeedbackSentiment;
  comment?: string;
}

export interface SubmittedFeedback {
  feedback: Feedback;
  feedbackMetadata?: FeedbackMetadata;
}

export interface UseFeedbackContextProps {
  feedbackScope: FeedbackScope;
  onSubmittedFeedback?: (submittedFeedback: SubmittedFeedback) => void;
}

export const useFeedbackContext = (props: UseFeedbackContextProps) => {
  const [feedbackMetadata, setFeedbackMetadata] = useState<FeedbackMetadata>();

  const [feedbackSentiment, setFeedbackSentiment] = useState<FeedbackSentiment | undefined>();
  const [feedbackComment, setFeedbackComment] = useState<string | undefined>();
  const [isFeedbackPopoverOpen, setIsFeedbackPopoverOpen] = useState(false);
  const [isFeedbackCommentModalOpen, setIsFeedbackCommentModalOpen] = useState(false);

  const genAIBackendClient = useAIBackendHubClient();
  const snackbarContext = useContext(SnackbarContext);

  const feedbackScope = props.feedbackScope;

  const feedbackMutation = useMutation({
    mutationFn: (userSentiment: UserSentiment) => {
      return genAIBackendClient.submitFeedbackSentiment({
        body: userSentiment,
      });
    },
  });

  const trimAssetUrl = (url: URL | undefined) => {
    if (!url) return undefined;

    if (url.protocol === 'blob:') {
      console.warn('Blob URL provided for feedback sentiment.');
      return url.pathname;
    } else {
      return url.origin + url.pathname;
    }
  };

  const submitFeedback = async (params?: { includeComment: boolean }) => {
    // Prepare a processing notification in case the submit feedback request takes longer than expected.
    const cleanupProcessingNotification = snackbarContext.addProcessingNotification({
      SnackbarContent: SubmitFeedbackProcessingNotification,
    });

    try {
      if (feedbackMutation.isPending) {
        throw new Error('Feedback submission already in progress');
      }

      if (!feedbackSentiment) {
        throw new Error('Feedback sentiment required to submit feedback');
      }

      if (!params?.includeComment) {
        setFeedbackComment('');
      }

      const sentiment = feedbackSentiment;
      const comment = params?.includeComment ? feedbackComment : undefined;
      const assetUrlObj = feedbackMetadata?.assetUrl ? new URL(feedbackMetadata.assetUrl) : undefined;
      const trimmedAssetUrl = trimAssetUrl(assetUrlObj);

      // Capture UserSentiment before closing modal/popover
      const userSentiment: UserSentiment = {
        assetUri: trimmedAssetUrl,
        // TODO: contentCreationTime is not available and needs to be added in a future CR.
        contentCreationTime: 30,
        feedback: sentiment,
        prompt: feedbackMetadata?.prompt,
        scope: feedbackScope,
        textFeedback: comment,
        workflowId: feedbackMetadata?.workflowId,
      };

      // Capture submitted feedback before closing modal/popover
      const submittedFeedback = {
        feedback: {
          sentiment,
          comment,
        },
        feedbackMetadata: _cloneDeep(feedbackMetadata),
      };

      // Close modal/popover to avoid blocking the user
      setIsFeedbackCommentModalOpen(false);
      setIsFeedbackPopoverOpen(false);

      // Handle the feedback submission to the server
      await feedbackMutation.mutateAsync(userSentiment);

      // Put post-success in another try-catch to avoid triggering failure notification tied to API call
      try {
        props.onSubmittedFeedback?.(submittedFeedback);
        snackbarContext.addSuccessNotification({
          SnackbarContent: SubmitFeedbackSuccessNotification,
        });
      } catch (err) {
        console.error(err);
      }
    } catch (err) {
      console.error('Error while submitting feedback', err);
      snackbarContext.addFailureNotification({
        SnackbarContent: SubmitFeedbackFailureNotification,
      });
      setFeedbackComment('');
      setFeedbackSentiment(undefined);
    } finally {
      // Cleanup
      cleanupProcessingNotification();
    }
  };

  const closeAndSubmitFeedback = () => {
    if (!isFeedbackPopoverOpen) return;
    setIsFeedbackPopoverOpen(false);
    setIsFeedbackCommentModalOpen(false);
    submitFeedback();
  };

  useEffect(() => {
    if (!isFeedbackPopoverOpen && isFeedbackCommentModalOpen) {
      setIsFeedbackCommentModalOpen(false);
    }
  }, [isFeedbackPopoverOpen]);

  return {
    feedbackMetadata,
    setFeedbackMetadata,
    //
    feedbackScope,
    //
    feedbackSentiment,
    setFeedbackSentiment,
    //
    feedbackComment,
    setFeedbackComment,
    //
    isFeedbackPopoverOpen,
    setIsFeedbackPopoverOpen,
    //
    isFeedbackCommentModalOpen,
    setIsFeedbackCommentModalOpen,
    //
    closeAndSubmitFeedback,
    submitFeedback,
  };
};

export type FeedbackContextType = ReturnType<typeof useFeedbackContext>;
export const FeedbackContext = createContext({} as FeedbackContextType);

export type FeedbackContextProviderProps = { children: React.ReactNode } & UseFeedbackContextProps;

export const FeedbackContextProvider = (props: FeedbackContextProviderProps) => {
  const { children, ...rest } = props;
  const feedbackContext = useFeedbackContext(rest);
  return <FeedbackContext.Provider value={feedbackContext}>{props.children}</FeedbackContext.Provider>;
};
