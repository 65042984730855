import { Asset, InspirationalContent } from '@amzn/genaihub-typescript-client';
import { CloseButton, Modal } from '@amzn/storm-ui';
import { Stack } from '@mui/material';
import { ReactNode, useCallback, useContext } from 'react';
import AssetContentCard from './AssetContentCard';
import styles from './AssetDetails.module.scss';
import { AppContext } from '../../../../AppContext';
import { getWorkflowNameById } from '../../utils/getWorkflowNameById';
import isInspirationaContent from '../../utils/isInspirationaContent';
import { SIGNIN_TEXT, signinClickHandler } from '../SignInButton';
import ButtonWithBorder from '../storm/ButtonWithBorder';

interface AssetDetailsProps {
  asset: Asset;
  showDetails?: boolean;
  buttonText?: string;
  setShowDetails: (value: boolean) => void;
  clickHandler?: (asset: Asset) => void;
}

export default function AssetDetails(props: AssetDetailsProps) {
  const { asset, buttonText, showDetails, setShowDetails, clickHandler } = props;
  const { authenticated } = useContext(AppContext);
  console.log('AssetDetail', authenticated);

  const handleRect = useCallback(
    (node: HTMLDivElement) => {
      setTimeout(() => node?.parentElement?.scrollTo(0, 0), 100);
    },
    [asset, showDetails],
  );

  const closeDetails = () => {
    console.log('closeDetails');
    setShowDetails(false);
  };

  return (
    <>
      <Modal isOpen={showDetails} onClose={closeDetails} hasCloseButton={false} padding={'small'} contentClassName={styles.modal}>
        <div ref={handleRect} className={`assets-details ${styles['assets-details']} ${styles.container} ${styles.spaceBetween}`}>
          <div className={styles.content}>
            <AssetContentCard asset={asset} controls />
          </div>
          <div className={`${styles.information} ${styles.container} ${styles.column} ${styles.spaceBetween}`}>
            <Stack spacing={0} className={styles.details}>
              {isInspirationaContent(asset) ? (
                <InspirationalContentDetails asset={asset as InspirationalContent} />
              ) : (
                <AssetFeedDetails asset={asset} />
              )}
            </Stack>
            <ButtonWithBorder
              style={{ borderColor: '#4305F4', color: '#4305F4' }}
              text={buttonText ? buttonText : authenticated ? 'Try this example' : SIGNIN_TEXT}
              clickHandler={() => {
                authenticated ? clickHandler?.(asset) : signinClickHandler();
              }}
            />
          </div>
          <div className={styles.close} onClick={closeDetails}>
            <CloseButton />
          </div>
        </div>
      </Modal>
    </>
  );
}

const InspirationalContentDetails = (props: { asset: InspirationalContent }) => {
  const { asset } = props;
  return (
    <>
      {asset.textPrompt && <Detail header="Prompt" detail={asset.textPrompt} prompt />}
      <Detail header="Creative type" detail={capitalize(asset.type)} />
      {asset.resolution && <Detail header="Creative dimensions" detail={asset.resolution} />}
      {asset.productCategory && <Detail header="Product category" detail={(asset as any).productCategory} />}
      {asset.creativeFeature && <Detail header="Creative feature" detail={(asset as any).creativeFeature} />}
      {asset.workflowId && <Detail header="Generative feature" detail={getWorkflowNameById(asset.workflowId)} />}
    </>
  );
};

const AssetFeedDetails = (props: { asset: Asset }) => {
  const { asset } = props;
  return (
    <>
      <Detail header="Creative type" detail={capitalize(asset.type)} />
      {/* TODO Add more details and options */}
    </>
  );
};

const Detail = (props: { header: string; detail?: ReactNode | string; prompt?: boolean }): JSX.Element => {
  return (
    <>
      <div className={styles.header}>{props.header}</div>
      <div className={`${styles.detail} ${props.prompt ? styles.prompt : ''}`}>{props.detail}</div>
    </>
  );
};

const capitalize = (s?: string): string => {
  return s ? s[0].toUpperCase() + s.slice(1).toLowerCase() : '';
};
